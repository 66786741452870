import styled from 'styled-components';
import { secondary, disabled, bosColor, typography, padding, margin } from '@next-components/common-styles';

const editableWidth = 36.5;

interface IEditFinanceProps {
  rowBorderColor: string;
}

export const EditFinance = styled.div<IEditFinanceProps>`
  &.edit-finance {
    width: 100%;
  }
  .error-santandar {
    text-align: center;
    color: ${bosColor.destructive};
    font-weight: bold;
  }
  .footerMenu {
    margin-top: 3rem;
    margin-bottom: 3.5rem;
    .nextButton {
      .small.bottomButton {
        .tooltip {
          max-width: max-content;
          left: 45%;
          &::after {
            right: initial;
          }
        }
      }
    }
  }
  header.info {
    margin-bottom: 3.2rem;

    p {
      width: ${editableWidth}%;
    }
  }
  .content {
    display: flex;
    gap: 4rem;
    width: 100%;

    .empty-state {
      width: ${editableWidth}%;
    }
    .editable {
      width: ${editableWidth}%;
      form {
        margin-bottom: 0;
        padding: 0 0.625rem;
        min-height: 49.7rem;
      }
      .sales-rep-comment h4 {
        line-height: 1;
        margin-left: 0.625rem;
        padding-top: 0;
      }
    }
    .calculations {
      display: grid;
      gap: 3rem;
      width: 100%;
      .header {
        column-gap: 0.625rem;
      }
      .item {
        column-gap: 0.625rem;
        border: none;
        button {
          width: 1.375rem;
          height: 1.375rem;
        }
      }
      .calculation-type.leasing {
        .item.empty {
          grid-template-columns: 1fr;
        }
      }
      .calculation-type {
        .item.empty {
          grid-template-columns: 1fr;
        }
        .header,
        .item {
          grid-template-columns: 2rem 5.5rem 5rem 5.5rem 7rem 6rem 5rem 4.5rem 1.2rem;
          .sortable {
            font-size: 0.9rem;
          }
          & > div {
            font-size: 0.9rem;
            & > span {
              font-size: 0.9rem;
            }
          }
        }
        .newServiceContractForm {
          border: 1px solid ${bosColor.neutralGrey};
          border-radius: 0 0 0.5rem 0.5rem;
        }
        &.singleRounded {
          .accordionContainer {
            border-radius: 0 0 0.5rem 0.5rem;
            & > div {
              border-radius: 0 0 0.5rem 0.5rem;

              button.btnAccordion:not(.search-button) {
                border-radius: 0 0 0.5rem 0.5rem !important;
              }
              &[open] {
                button.btnAccordion:not(.search-button) {
                  border-radius: 0 !important;
                }
              }
              .content[open] {
                border-radius: 0 0 0.5rem 0.5rem !important;
                overflow: unset;
                & > div {
                  border-radius: 0 0 0.5rem 0.5rem !important;
                }
              }
            }
          }
        }
      }
      .calculation-type.cash {
        .header,
        .item {
          .sortable {
            font-size: 0.9rem;
          }
          & > div {
            font-size: 0.9rem;
            & > span {
              font-size: 0.9rem;
            }
          }
        }
      }
      .calculation-type {
        .tooltip {
          z-index: 1;
        }
        > div {
          padding: 0;
          margin: 0;
          top: 0;
          .item {
            &.selected {
              border-color: ${(props) => props.rowBorderColor ?? secondary};
            }
          }
        }

        .disable {
          color: ${disabled};
        }

        .sign-button {
          height: 1.875rem;
          padding: 0 10px;
          width: auto;
        }

        .add-calculation {
          background-color: ${bosColor.turquoise20};
          border: 1px solid ${bosColor.neutralGrey};
          border-radius: 0 0 0.5rem 0.5rem;
          height: 3.125rem;
          width: 100%;
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border-top: 0;
          &.spinner {
            display: flex;
            place-content: center;
          }
          .iconButtonWrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            cursor: pointer;
            svg {
              padding-block: 3px;
            }
          }
          .icon {
            align-items: center;
            background-color: ${secondary};
            border-radius: 50%;
            display: inline-flex;
            height: 1.5rem;
            justify-content: center;
            margin-right: 1rem;
            width: 1.5rem;

            svg {
              height: 1.5rem;
              width: 1.5rem;
              path {
                fill: white;
              }
            }
          }

          .text {
            color: ${bosColor.black};
            font-size: 1rem;
            font-style: normal;
            font-weight: ${typography.weight.extrabold};
            line-height: 175%;

            &.disabled {
              color: ${bosColor.neutralPlus};
            }
          }
        }

        .item {
          div:last-child {
            header {
              height: 2.625rem;
              padding: 0 1.25rem;
              .tinyFont {
                font-size: 1rem;
              }
            }

            header ~ div {
              height: 3rem;
              min-width: 11.875rem;
              padding: 0 1.25rem;
            }
            .icon {
              height: 1rem;
              margin: 0 0.625rem 0 0;
              position: relative;
              width: 1rem;
            }
          }
        }
      }

      .print {
        margin: 3.2rem 0;

        .text {
          font-size: 1.125rem;
        }
      }

      &.finance-option-container {
        .nextAccordion {
          ${padding[0].a};
          .calculation-type {
            &.loan,
            &.leasing,
            &.cash {
              .serviceContractComparisionModal {
                .header,
                .item {
                  grid-template-columns: 4fr 1.5fr 1.5fr;
                }
                .custom-modal {
                  border-radius: 0.5rem;
                  width: 100%;
                  max-width: 68.75rem;
                  .close-modal-button {
                    top: 1rem;
                    right: 1.5rem;
                    svg {
                      background-color: ${bosColor.lightGrey};
                      border-radius: 65rem;
                    }
                  }
                  .inner-container {
                    ${padding[0].a};
                    .content-container {
                      header {
                        height: 4rem;
                        ${padding[16].y};
                        ${padding[32].l};
                        ${padding[24].r};
                        ${margin[0].a};
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 25.1875rem;
                        align-self: stretch;
                        border-bottom: 0.0625rem solid ${bosColor.neutralGrey};
                        h2 {
                          color: ${bosColor.black};
                          font-size: 1.25rem;
                          font-style: normal;
                          font-weight: ${typography.weight.extrabold};
                          line-height: 180%;
                        }
                      }
                    }
                  }
                  .content-container {
                    > header {
                      + div > div {
                        ${padding[24].a};
                      }
                    }
                  }
                }
              }
            }
            &.loan {
              .header,
              .item {
                grid-template-columns: 2rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5rem;
                &.empty {
                  grid-template-columns: 1fr;
                  margin: 0;
                  border: 1px solid ${bosColor.neutralGrey};
                  border-top: 0;
                  & > div {
                    font-weight: ${typography.weight.semibold};
                  }
                }
              }
            }
            &.leasing {
              .header,
              .item {
                grid-template-columns: 3rem 1fr 1fr 1fr 1fr 1fr 1fr 1.5rem;
                &.empty {
                  grid-template-columns: 1fr;
                  margin: 0;
                  border: 1px solid ${bosColor.neutralGrey};
                  border-top: 0;
                  & > div {
                    font-weight: ${typography.weight.semibold};
                  }
                }
              }
              &.alternativeLeasing {
                & > div:has(div.alternativeLeasingContent) {
                  padding: 0;
                }
                &.leasingAdded > div:has(div.alternativeLeasingContent) {
                  padding: 0;
                }
                .tableContainer {
                  overflow: unset;
                  .header,
                  .item {
                    grid-template-columns: 3rem 1fr 1fr 1fr 1fr 1fr 1.5rem;
                    &.empty {
                      grid-template-columns: 1fr;
                    }
                  }
                }
              }
            }
            &.cash {
              .header,
              .item {
                grid-template-columns: 3rem 1fr 1fr 1fr 1fr 1fr 1.5rem;
                &.empty {
                  grid-template-columns: 1fr;
                  margin: 0;
                  border: 1px solid ${bosColor.neutralGrey};
                  border-top: 0;
                  border-radius: 0 0 0.5rem 0.5rem;
                  height: 3.375rem;
                  & > div {
                    font-weight: ${typography.weight.semibold};
                  }
                }
              }
            }
            .header,
            .item {
              div {
                font-size: 1rem;
                padding: 0;
                font-weight: ${typography.weight.extrabold};
                color: ${bosColor.black};
              }
              & > div {
                font-size: 1rem;
                padding: 0;
                color: ${bosColor.black};
                & > span {
                  font-size: 1rem;
                }
              }
            }
            .header.headerRow {
              padding: 0.75rem 1rem;
              background-color: ${bosColor.neutralGrey};
              border-radius: 0.5rem 0.5rem 0 0;
              border: none;
              z-index: auto;
            }

            .tableContainer {
              .accordionContainer > div {
                button.btnAccordion:not(.search-button) {
                  padding: 0 1rem;
                  border: 1px solid ${bosColor.neutralGrey};
                  border-radius: 0;
                  background: #fcfcfc;
                  border-top: 0;
                  grid-template-columns: 1fr;
                  .labelContainer .item {
                    background: none;
                    div,
                    div > span {
                      font-weight: ${typography.weight.semibold} !important;
                    }
                  }
                  .distanceAndIconContainer {
                    position: absolute;
                    right: 1rem;
                    .accordionIcon {
                      background: none;
                      transform: initial;
                    }
                  }
                }
                &[open] button.btnAccordion:not(.search-button) {
                  background: ${bosColor.lightGrey};
                  .distanceAndIconContainer {
                    .accordionIcon {
                      transform: rotate(180deg);
                    }
                  }
                }

                .content {
                  &[open] {
                    padding: 0;
                    border-radius: 0;
                    border: none;
                    > div {
                      border-right: 1px solid ${bosColor.neutralGrey};
                      border-left: 1px solid ${bosColor.neutralGrey};
                      border-bottom: 1px solid ${bosColor.neutralGrey};

                      & > div {
                        border-radius: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
