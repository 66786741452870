import { bosColor, typography } from '@next-components/common-styles';
import styled from 'styled-components';

export const AlternativeLeasingContainer = styled.div`
  background: ${bosColor.lightGrey};
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 1rem;
  width: 100%;

  .alternativeLeasingContent {
    width: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    border-radius: 0.5rem !important;
    border: 1px solid ${bosColor.neutralGrey};
    background: ${bosColor.white};

    .leasingTitle {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: ${typography.weight.extrabold} !important;
      line-height: 175%;
    }
  }
  .customMessageBar {
    width: 100%;
    max-width: none !important;

    .iconContainer {
      margin-top: 0.25rem;
      width: 1.5rem;
      height: 1.5rem;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .dropdown-container {
    width: 100%;
    .leasingOptions {
      .react-select__menu {
        .react-select__option--is-selected {
          div:after {
            display: none;
          }
        }
      }
    }
  }
  .buttonWrapper {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    width: 100%;

    button {
      width: 100%;
      &:disabled {
        background: ${bosColor.neutralGrey};
        border-color: ${bosColor.neutralGrey};
        color: ${bosColor.neutralPlus};
        opacity: 1;
      }
      &.loading-button {
        height: 3.5rem;
      }
      &.leasingAction {
        padding: 0.75rem 1rem;
        font-weight: ${typography.weight.extrabold};
        justify-content: center;
      }
      &.selectLeasing {
      }
      &.removeLeasing {
        height: 3.375rem;
        gap: 0.5rem;

        .iconWrapper {
          .buttonIcon {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
  }
`;

export const CustomOptionContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;

  span {
    margin: 0;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-weight: ${typography.weight.semibold};
    line-height: 175%;

    &.distanceLabel {
      text-align: right;
    }
  }
`;
