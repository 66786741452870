import { cloneDeep, isNil, minBy, sortBy, uniq } from 'lodash';
import {
  IEquipmentTypes,
  IInterior,
  INextArticle,
  IVarnish,
  IVehicleConfiguration,
  MAKE,
  VehicleEquipmentCategoryEnum,
  invokeProvidedBrandSpecificCode,
} from 'next-common';
import { IModelPackages, IVehicleModel } from '../../components/sales/sales-vehicle-picker-page';
import { Events, eventEmitter } from '../events';
import { getDisableEnableSKUList, isDisabledSKU } from './selectableRules';
import {
  IPartialEnrichedEquipment,
  getColorPrice,
  getExterirTilleggFromEquipList,
  getInteriorTilleggFromEquipList,
  getInteriorsPrice,
  getListverkFromEquipList,
  getListverkPrice,
  getRimPrice,
  getTaklakFromEquipList,
  getTaklakkPrice,
  rulesArrPayloadToString,
  rulesPayloadMapper,
} from './selectables';
import { IArticlesResponse, ICurrentSelections, ISelectionData, ISelectionDataWithPayload, filterAccessoryFromArticles, getOneOffTaxForKia, prepareRulesPayloadFromSelectedData } from './conjunction';
import { ICartProps, ICartSummaryData } from '@next-components/cart';
import { VehicleConfig, calculateRetailPrice, prepareVehicleConfig } from './vehicleOrder';
import { prepareArticles } from './vat';
import { ArticleType } from 'sales-common';

export enum PreSelectedKeyEnum {
  COLOR = 'COLOR',
  INTERIOR = 'INTERIOR',
  RIM = 'RIM',
  LISTVERK = 'LISTVERK',
  TAKLAK = 'TAKLAK',
  EXT_TILLEGG = 'EXT_TILLEGG',
  INT_TILLEGG = 'INT_TILLEGG',
}

export enum TillaggSubCategoryEnum {
  Eksteriørpakke = 'Eksteriørpakke',
  Interiørpakke = 'Interiørpakke',
}

export const checkRulesAndReturnPreselectedElement = (
  preselectedElements: string[],
  onLoadDisableSkuList,
  payloadArray: string[],
  enrichedEquipment,
) => {
  let finalPreselectedElement = [];
  
  preselectedElements?.forEach(element => {
    const updatedPayload = [...payloadArray, ...finalPreselectedElement];
    const rulesPayload: string = rulesArrPayloadToString(updatedPayload);
    
    let { disableSkusMappedList, enableSkusMappedList } = updatedPayload?.length
    ? getDisableEnableSKUList(rulesPayload, enrichedEquipment)
    : { disableSkusMappedList: new Map(), enableSkusMappedList: new Map() };
   
    if (!isDisabledSKU(element, disableSkusMappedList, enableSkusMappedList, onLoadDisableSkuList)) {
      finalPreselectedElement.push(element);
    }
  })

  return finalPreselectedElement;
};

export const getPreSelectedVarnishCode = (
  varnishes: IVarnish[],
  onLoadDisableSkuList,
  payloadArray: string[],
  enrichedEquipment,
): [boolean, string] => {
  const preSelectedVarnishCode = varnishes?.filter((varnish) => varnish?.preselected)?.[0]?.varnishCode || null;
  const preselectVarnishes = preSelectedVarnishCode ? checkRulesAndReturnPreselectedElement([preSelectedVarnishCode], onLoadDisableSkuList, payloadArray, enrichedEquipment) : [];

  if(preselectVarnishes?.length) {
    return [isPreselectionValidationFailed(varnishes, preselectVarnishes), preselectVarnishes?.[0]];
  } else {
    let varnishCode = minBy(varnishes, 'additionalPriceExcludingVat')?.varnishCode || null;
    const rulesPayload: string = rulesArrPayloadToString(payloadArray);
    let { disableSkusMappedList, enableSkusMappedList } = getDisableEnableSKUList(rulesPayload, enrichedEquipment);
    if (isDisabledSKU(varnishCode, disableSkusMappedList, enableSkusMappedList, onLoadDisableSkuList)) {
      let sortedVarnishByPrice = sortBy(varnishes, ['additionalPriceExcludingVat']);
      varnishCode =
      sortedVarnishByPrice?.filter(
          (varnish) =>
            !isDisabledSKU(varnish?.varnishCode, disableSkusMappedList, enableSkusMappedList, onLoadDisableSkuList),
        )?.[0]?.varnishCode || null;
    }  
    return [isPreselectionValidationFailed(varnishes, [varnishCode]), varnishCode];
  }
};

export const getPreSelectedInteriorCode = (
  interiors: IInterior[],
  onLoadDisableSkuList,
  payloadArray: string[],
  enrichedEquipment,
): [boolean, string] => {
  const preSelectedInteriorCode = interiors?.filter((interior) => interior?.preselected)?.[0]?.interiorCode || null;
  const preselectInteriors = preSelectedInteriorCode ? checkRulesAndReturnPreselectedElement([preSelectedInteriorCode], onLoadDisableSkuList, payloadArray, enrichedEquipment) : [];

  if(preselectInteriors?.length) {
    return [isPreselectionValidationFailed(interiors, preselectInteriors), preselectInteriors?.[0]];
  } else {
    let interiorCode = minBy(interiors, 'additionalPriceExcludingVat')?.interiorCode || null;
    const rulesPayload: string = rulesArrPayloadToString(payloadArray);
    let { disableSkusMappedList, enableSkusMappedList } = getDisableEnableSKUList(rulesPayload, enrichedEquipment);
    if (isDisabledSKU(interiorCode, disableSkusMappedList, enableSkusMappedList, onLoadDisableSkuList)) {
      let sortedInteriorByPrice = sortBy(interiors, ['additionalPriceExcludingVat']);
      interiorCode =
        sortedInteriorByPrice?.filter(
          (interior) =>
            !isDisabledSKU(interior?.interiorCode, disableSkusMappedList, enableSkusMappedList, onLoadDisableSkuList),
        )?.[0]?.interiorCode || null;
    }
    return [isPreselectionValidationFailed(interiors, [interiorCode]), interiorCode];
  }
};

export const getPreSelectedRimCode = (
  rims: IEquipmentTypes[],
  onLoadDisableSkuList,
  payloadArray: string[],
  enrichedEquipment,
): [boolean, string] => {
  const preSelectedRimCode = rims?.filter((rim) => rim?.preselected)?.[0]?.sku || null;
  const preselectRims = preSelectedRimCode ? checkRulesAndReturnPreselectedElement([preSelectedRimCode], onLoadDisableSkuList, payloadArray, enrichedEquipment) : [];

  if(preselectRims?.length) {
    return [isPreselectionValidationFailed(rims, preselectRims), preselectRims?.[0]];
  } else {
    let rimCode = minBy(rims, 'priceExcludingVat')?.sku || null;
    const rulesPayload: string = rulesArrPayloadToString(payloadArray);
    let { disableSkusMappedList, enableSkusMappedList } = getDisableEnableSKUList(rulesPayload, enrichedEquipment);
    if (isDisabledSKU(rimCode, disableSkusMappedList, enableSkusMappedList, onLoadDisableSkuList)) {
      let sortedRimsByPrice = sortBy(rims, ['priceExcludingVat']);
      rimCode =
        sortedRimsByPrice?.filter(
          (rim) => !isDisabledSKU(rim?.sku, disableSkusMappedList, enableSkusMappedList, onLoadDisableSkuList),
        )?.[0]?.sku || null;
    }

    return [isPreselectionValidationFailed(rims, [rimCode]), rimCode];
  }
};

export const getPreSelectedListverkCode = (
  Listverks: IEquipmentTypes[],
  onLoadDisableSkuList,
  payloadArray: string[],
  enrichedEquipment,
): [boolean, string] => {
  const preSelectedListverkCode = Listverks?.filter((Listverk) => Listverk?.preselected)?.[0]?.sku || null;
  const preselectListverks = preSelectedListverkCode ? checkRulesAndReturnPreselectedElement([preSelectedListverkCode], onLoadDisableSkuList, payloadArray, enrichedEquipment) : [];

  if(preselectListverks?.length) {
    return [isPreselectionValidationFailed(Listverks, preselectListverks), preselectListverks?.[0]];
  } else {
    let listverkCode = minBy(Listverks, 'priceExcludingVat')?.sku || null;
    if (listverkCode) {
      const rulesPayload: string = rulesArrPayloadToString(payloadArray);
      let { disableSkusMappedList, enableSkusMappedList } = getDisableEnableSKUList(rulesPayload, enrichedEquipment);

      if (isDisabledSKU(listverkCode, disableSkusMappedList, enableSkusMappedList, onLoadDisableSkuList)) {
        let sortedListverkByPrice = sortBy(Listverks, ['priceExcludingVat']);
        listverkCode =
          sortedListverkByPrice?.filter(
            (listverk) =>
              !isDisabledSKU(listverk?.sku, disableSkusMappedList, enableSkusMappedList, onLoadDisableSkuList),
          )?.[0]?.sku || null;
      }
    }
    return [isPreselectionValidationFailed(Listverks, [listverkCode]), listverkCode];
  }
};

export const getPreSelectedTaklakCode = (
  Taklakks: IEquipmentTypes[],
  onLoadDisableSkuList,
  payloadArray: string[],
  enrichedEquipment,
): [boolean, string] => {
  const preSelectedTaklakkCode = Taklakks?.filter((Taklakk) => Taklakk?.preselected)?.[0]?.sku || null;
  const preselectTaklakks = preSelectedTaklakkCode ? checkRulesAndReturnPreselectedElement([preSelectedTaklakkCode], onLoadDisableSkuList, payloadArray, enrichedEquipment) : [];

  if(preselectTaklakks?.length) {
    return [isPreselectionValidationFailed(Taklakks, preselectTaklakks), preselectTaklakks?.[0]];
  } else {
    let taklakCode = minBy(Taklakks, 'priceExcludingVat')?.sku || null;
    const rulesPayload: string = rulesArrPayloadToString(payloadArray);
    let { disableSkusMappedList, enableSkusMappedList } = getDisableEnableSKUList(rulesPayload, enrichedEquipment);
    if (isDisabledSKU(taklakCode, disableSkusMappedList, enableSkusMappedList, onLoadDisableSkuList)) {
      let sortedTaklaksByPrice = sortBy(Taklakks, ['priceExcludingVat']);
      taklakCode =
        sortedTaklaksByPrice?.filter(
          (taklak) => !isDisabledSKU(taklak?.sku, disableSkusMappedList, enableSkusMappedList, onLoadDisableSkuList),
        )?.[0]?.sku || null;
    }
    return [isPreselectionValidationFailed(Taklakks, [taklakCode]), taklakCode];
  }
};

export const getPreSelectedTilleggCode = (
  equipements: IEquipmentTypes[],
  onLoadDisableSkuList,
  subcategory: string,
  payloadArray: string[],
  enrichedEquipment,
): [boolean, string[]] => {
  //Filter by pre-selected
  const preSelectedTilleggCodes = (equipements?.filter((equipment) => equipment?.preselected))?.map(equip => equip?.sku);
  const preselectTilleggs = preSelectedTilleggCodes?.length ? checkRulesAndReturnPreselectedElement(preSelectedTilleggCodes, onLoadDisableSkuList, payloadArray, enrichedEquipment) : [];

  if(preselectTilleggs?.length) {
    return [isPreselectionValidationFailed(equipements, preselectTilleggs), preselectTilleggs];
  }  else {
    //Filter by sub-category
    const filterTilleggsBySubCategory = equipements?.filter((tillegg) => {
      const isValidSubCategory = tillegg?.subCategory === subcategory;
      return tillegg?.subCategory && isValidSubCategory;
    });
  
    if (!filterTilleggsBySubCategory?.length) {
      console.log(`No Tillagg found for subcategory: ${subcategory}`);
    }
  
    //Filter by lowest price
    let tilleggCode = minBy(filterTilleggsBySubCategory, 'priceExcludingVat')?.sku || null;
  
    //Check if selected Tillegg code is disabled or not...
    const rulesPayload: string = rulesArrPayloadToString(payloadArray);
  
    let { disableSkusMappedList, enableSkusMappedList } = payloadArray?.length
      ? getDisableEnableSKUList(rulesPayload, enrichedEquipment)
      : { disableSkusMappedList: new Map(), enableSkusMappedList: new Map() };
    if (isDisabledSKU(tilleggCode, disableSkusMappedList, enableSkusMappedList, onLoadDisableSkuList)) {
        let listAfterRemoveCode = filterTilleggsBySubCategory?.filter((tillegg) => tillegg?.sku !== tilleggCode);
        let sortedTilleggByPrice = sortBy(listAfterRemoveCode, ['priceExcludingVat']);
        tilleggCode =
          sortedTilleggByPrice?.filter(
            (tillegg) => !isDisabledSKU(tillegg?.sku, disableSkusMappedList, enableSkusMappedList, onLoadDisableSkuList),
          )?.[0]?.sku || null;
    } 
    return [isPreselectionValidationFailed(filterTilleggsBySubCategory, [tilleggCode]), tilleggCode ? [tilleggCode] : []];
  }
};

const isPreselectionValidationFailed = (
  equipments: IVarnish[] | IInterior[] | IEquipmentTypes[],
  preSlectedSku: string[],
) => {
  if (equipments?.length && !preSlectedSku?.length) {
    return true;
  }
  return false;
};

export const preparePreSelectedSKUList = (
  data: IVehicleModel,
  onLoadDisableSkusList,
  enrichedEquipment: IPartialEnrichedEquipment[],
) => {
  const currentSalesPackage = data?.salesPackages?.[0];

  //Prepare different types of equipmeny list for selected salespackage
  const colors = currentSalesPackage?.colors || [];
  const interior = currentSalesPackage?.interiors || [];
  const rims = currentSalesPackage?.rims || [];
  const listverks = getListverkFromEquipList(currentSalesPackage?.selectableEquipment);
  const taklaks = getTaklakFromEquipList(currentSalesPackage?.selectableEquipment) || [];
  const exteriorTillegg = getExterirTilleggFromEquipList(currentSalesPackage?.selectableEquipment) || [];
  const interiorTillegg = getInteriorTilleggFromEquipList(currentSalesPackage?.selectableEquipment) || [];

  try {
    const [isExtTillggValidationFailed, extTilleggCodes] = getPreSelectedTilleggCode(
      exteriorTillegg,
      onLoadDisableSkusList,
      TillaggSubCategoryEnum.Eksteriørpakke,
      [],
      enrichedEquipment,
    );

    const [isIntTillggValidationFailed, intTillegeCodes] = getPreSelectedTilleggCode(
      interiorTillegg,
      onLoadDisableSkusList,
      TillaggSubCategoryEnum.Interiørpakke,
      extTilleggCodes?.filter((value) => !isNil(value)),
      enrichedEquipment,
    );

    const [isColorValidationFailed, colorCode] = getPreSelectedVarnishCode(
      colors,
      onLoadDisableSkusList,
      [...extTilleggCodes, ...intTillegeCodes]?.filter((value) => !isNil(value)),
      enrichedEquipment,
    );

    const [isRimValidationFailed, rimCode] = getPreSelectedRimCode(
      rims,
      onLoadDisableSkusList,
      [colorCode, ...extTilleggCodes, ...intTillegeCodes]?.filter((value) => !isNil(value)),
      enrichedEquipment,
    );

    const [isInteriorValidationFailed, interiorCode] = getPreSelectedInteriorCode(
      interior,
      onLoadDisableSkusList,
      [colorCode, ...extTilleggCodes, rimCode, ...intTillegeCodes]?.filter((value) => !isNil(value)),
      enrichedEquipment,
    );

    const [isListverkValidationFailed, listverksCode] = getPreSelectedListverkCode(
      listverks,
      onLoadDisableSkusList,
      [colorCode, ...extTilleggCodes, rimCode, interiorCode, ...intTillegeCodes]?.filter((value) => !isNil(value)),
      enrichedEquipment,
    );

    const [isTaklakValidationFailed, taklakCode] = getPreSelectedTaklakCode(
      taklaks,
      onLoadDisableSkusList,
      [colorCode, ...extTilleggCodes, rimCode, interiorCode, listverksCode, ...intTillegeCodes]?.filter(
        (value) => !isNil(value),
      ),
      enrichedEquipment,
    );
    //CHECK VALIDATION...AND PROMPT ERROR IF ANY RULES CONFLICT FOUND WITH PRESELECTION
    if (
      isColorValidationFailed ||
      isInteriorValidationFailed ||
      isRimValidationFailed ||
      isListverkValidationFailed ||
      isTaklakValidationFailed ||
      isExtTillggValidationFailed ||
      isIntTillggValidationFailed
    ) {
      let InValidEquip = [];
      if(isColorValidationFailed) InValidEquip.push("COLOR");
      if(isInteriorValidationFailed) InValidEquip.push("INTERIOR");
      if(isRimValidationFailed) InValidEquip.push("RIM");
      if(isListverkValidationFailed) InValidEquip.push("LISTVERK");
      if(isTaklakValidationFailed) InValidEquip.push("TAKLAK");
      if(isExtTillggValidationFailed) InValidEquip.push("EXT.TILLEGE");
      if(isIntTillggValidationFailed) InValidEquip.push("INT.TILLEGE");

      console.log(`Preselection Validation failed due to ${JSON.stringify(InValidEquip)} validation failed`);
      eventEmitter.dispatch(Events.PRESELECT_SKU_ERROR, true);
    }

    return PreparePreSelectSKUList(
      colorCode,
      interiorCode,
      rimCode,
      listverksCode,
      taklakCode,
      extTilleggCodes,
      intTillegeCodes,
    );
  } catch (error) {
    console.log('Preselection Error =>', error);
    return new Map();
  }
};

const PreparePreSelectSKUList = (
  colorCode: string,
  interiorCode: string,
  rimCode: string,
  listverksCode: string,
  taklakCode: string,
  extTilleggCodes: string[],
  intTillegeCodes: string[],
) => {
  const preSelectedSKUList = new Map();
  //Prepare object for each equipment (status: true: if sku is preselected, else false)

  preSelectedSKUList.set(PreSelectedKeyEnum.COLOR, {
    status: !Boolean(colorCode),
    sku: colorCode,
  });
  preSelectedSKUList.set(PreSelectedKeyEnum.INTERIOR, {
    status: !Boolean(interiorCode),
    sku: interiorCode,
  });
  preSelectedSKUList.set(PreSelectedKeyEnum.RIM, {
    status: !Boolean(rimCode),
    sku: rimCode,
  });
  preSelectedSKUList.set(PreSelectedKeyEnum.LISTVERK, {
    status: !Boolean(listverksCode),
    sku: listverksCode,
  });
  preSelectedSKUList.set(PreSelectedKeyEnum.TAKLAK, {
    status: !Boolean(taklakCode),
    sku: taklakCode,
  });
  preSelectedSKUList.set(PreSelectedKeyEnum.EXT_TILLEGG, {
    status: !Boolean(extTilleggCodes?.length),
    sku: extTilleggCodes,
  });
  preSelectedSKUList.set(PreSelectedKeyEnum.INT_TILLEGG, {
    status: !Boolean(intTillegeCodes?.length),
    sku: intTillegeCodes,
  });
  return preSelectedSKUList;
};

interface IAllEquipment {
  equipmentIds: IEquipmentTypes[];
  rimIds: IEquipmentTypes;
  colorId: string;
  interiorId: string;
  listverkData: IEquipmentTypes;
  taklakkData: IEquipmentTypes;
}

export const ReApplyDefaultColor = (
  currentVariantData: IModelPackages,
  rulesPayload: string[],
  enrichedEquipment: IPartialEnrichedEquipment[],
  onloadDisableSku,
): string => {
  if (currentVariantData?.colors?.length) {
    const [, colorCode] = getPreSelectedVarnishCode(
      currentVariantData?.colors,
      onloadDisableSku,
      rulesPayload,
      enrichedEquipment,
    );
    return colorCode;
  }
  return null;
};

export const ReApplyDefaultInterior = (
  currentVariantData: IModelPackages,
  rulesPayload: string[],
  enrichedEquipment: IPartialEnrichedEquipment[],
  onloadDisableSku,
): string => {
  if (currentVariantData?.interiors?.length) {
    const [, interiorCode] = getPreSelectedInteriorCode(
      currentVariantData?.interiors,
      onloadDisableSku,
      rulesPayload,
      enrichedEquipment,
    );
    return interiorCode;
  }
  return null;
};

export const ReApplyDefaultRim = (
  currentVariantData: IModelPackages,
  rulesPayload: string[],
  enrichedEquipment: IPartialEnrichedEquipment[],
  onloadDisableSku,
): IEquipmentTypes => {
  if (currentVariantData?.rims?.length) {
    const [, rimCode] = getPreSelectedRimCode(
      currentVariantData?.rims,
      onloadDisableSku,
      rulesPayload,
      enrichedEquipment,
    );
    if (rimCode) return currentVariantData?.rims?.find((rim) => rim?.sku === rimCode);
  }
  return null;
};

export const ReApplyDefaultTaklak = (
  currentVariantData: IModelPackages,
  rulesPayload: string[],
  enrichedEquipment: IPartialEnrichedEquipment[],
  onloadDisableSku,
): IEquipmentTypes => {
  const equipmentFilter: IEquipmentTypes[] = getTaklakFromEquipList(currentVariantData?.selectableEquipment);
  if (equipmentFilter?.length) {
    const [, taklakCode] = getPreSelectedTaklakCode(equipmentFilter, onloadDisableSku, rulesPayload, enrichedEquipment);
    if (taklakCode) {
      return equipmentFilter?.find((equip) => equip?.sku === taklakCode);
    }
  }
  return null;
};

export const ReApplyDefaultListverk = (
  currentVariantData: IModelPackages,
  rulesPayload: string[],
  enrichedEquipment: IPartialEnrichedEquipment[],
  onloadDisableSku,
): IEquipmentTypes => {
  const equipmentFilter: IEquipmentTypes[] = getListverkFromEquipList(currentVariantData?.selectableEquipment);
  if (equipmentFilter?.length) {
    const [, listverksCode] = getPreSelectedListverkCode(
      equipmentFilter,
      onloadDisableSku,
      rulesPayload,
      enrichedEquipment,
    );
    if (listverksCode) {
      return equipmentFilter?.find((equip) => equip?.sku === listverksCode);
    }
  }
  return null;
};

export const ReApplyDefaultTillagg = (
  equipmentData: IEquipmentTypes[],
  currentVariantData: IModelPackages,
  rulesPayload: string[],
  enrichedEquipment: IPartialEnrichedEquipment[],
  onloadDisableSku,
): IEquipmentTypes[] => {
  let updatedEquipmentData: IEquipmentTypes[] = cloneDeep(equipmentData);
  let exteriorTillaggSku =
    equipmentData?.find((equip) => equip?.subCategory === TillaggSubCategoryEnum?.Eksteriørpakke)?.sku || null;
  let interiorTillaggSku =
    equipmentData?.find((equip) => equip?.subCategory === TillaggSubCategoryEnum?.Interiørpakke)?.sku || null;

  if (!exteriorTillaggSku) {
    const equipmentFilter: IEquipmentTypes[] = getExterirTilleggFromEquipList(currentVariantData?.selectableEquipment);    
    if (equipmentFilter?.length) {
      const [, tilleggCodes] = getPreSelectedTilleggCode(
        equipmentFilter,
        onloadDisableSku,
        TillaggSubCategoryEnum.Eksteriørpakke,
        rulesPayload,
        enrichedEquipment,
      );
      if(tilleggCodes?.length) {
        const addedEquipment = equipmentFilter?.filter((equip) => tilleggCodes?.includes(equip?.sku));
        updatedEquipmentData?.push(...addedEquipment);
      }
    }
  }

  if (!interiorTillaggSku) {
    const newRulePayload = cloneDeep(uniq([...rulesPayload, exteriorTillaggSku]));
    const equipmentFilter: IEquipmentTypes[] = getInteriorTilleggFromEquipList(currentVariantData?.selectableEquipment);
    if (equipmentFilter?.length) {
      const [, tilleggCodes] = getPreSelectedTilleggCode(
        equipmentFilter,
        onloadDisableSku,
        TillaggSubCategoryEnum.Interiørpakke,
        newRulePayload,
        enrichedEquipment,
      );
      if(tilleggCodes?.length) {
        const addedEquipment = equipmentFilter?.filter((equip) => tilleggCodes?.includes(equip?.sku));
        updatedEquipmentData?.push(...addedEquipment);
      }
    }
  }
  return updatedEquipmentData;
};

export const reApplyPreSelectionOnSkus = (
  currentVariantData: IModelPackages,
  rulesPayload: string[],
  onloadDisableSku,
  { equipmentIds, rimIds, colorId, interiorId, listverkData, taklakkData }: IAllEquipment,
): ISelectionData => {
  const enrichedEquipment: IPartialEnrichedEquipment[] = rulesPayloadMapper(currentVariantData?.mappedEquipment);

  let tilleggs: IEquipmentTypes[] = ReApplyDefaultTillagg(
    equipmentIds,
    currentVariantData,
    rulesPayload,
    enrichedEquipment,
    onloadDisableSku,
  );

  const tilleggSkus = tilleggs?.map((tillegg) => tillegg?.sku);

  let colorSku: string =
    colorId ||
    ReApplyDefaultColor(
      currentVariantData,
      cloneDeep(uniq([...rulesPayload, ...tilleggSkus])),
      enrichedEquipment,
      onloadDisableSku,
    );

  let rim: IEquipmentTypes =
    rimIds ||
    ReApplyDefaultRim(
      currentVariantData,
      cloneDeep(uniq([...rulesPayload, ...tilleggSkus, colorSku])),
      enrichedEquipment,
      onloadDisableSku,
    );

  let interiorSku: string =
    interiorId ||
    ReApplyDefaultInterior(
      currentVariantData,
      cloneDeep(uniq([...rulesPayload, ...tilleggSkus, colorSku, rim?.sku])),
      enrichedEquipment,
      onloadDisableSku,
    );

  let listverk: IEquipmentTypes =
    listverkData ||
    ReApplyDefaultListverk(
      currentVariantData,
      cloneDeep(uniq([...rulesPayload, ...tilleggSkus, colorSku, rim?.sku, interiorSku])),
      enrichedEquipment,
      onloadDisableSku,
    );

  let taklak: IEquipmentTypes =
    taklakkData ||
    ReApplyDefaultTaklak(
      currentVariantData,
      cloneDeep(uniq([...rulesPayload, ...tilleggSkus, colorSku, rim?.sku, interiorSku, listverk?.sku])),
      enrichedEquipment,
      onloadDisableSku,
    );

  return {
    equipmentIds: tilleggs,
    rimIds: rim,
    colorId: colorSku,
    interiorId: interiorSku,
    listverkData: listverk,
    taklakkData: taklak,
  };
};

export const updateCartDataForDefaultSkus = (
  skus: string[],
  skusWithType: { [key: string]: string },
  currentVariantData: IModelPackages,
  existingCartData: ICartProps,
  priceUpdate: Map<any, any>,
): ICartProps => {
  let updatedSummaryCartData: ICartSummaryData[] = cloneDeep(existingCartData?.data);
  skus?.forEach((sku) => {
    const type = skusWithType[sku];
    let { equipementData, color, interior } = getEquipDataForSKU(currentVariantData, sku, type);
    updatedSummaryCartData.push(
      getCartSummaryDataByCategoryForDefaults(equipementData, color, interior, type, priceUpdate),
    );
  });
  const updatedCartData: ICartProps = {
    ...existingCartData,
    data: updatedSummaryCartData,
  };

  return updatedCartData;
};

export const getCartSummaryDataByCategoryForDefaults = (
  equipementData: IEquipmentTypes,
  color: IVarnish,
  interior: IInterior,
  type: string,
  priceUpdate: Map<any, any>,
): ICartSummaryData => {
  let summaryData: ICartSummaryData = null;

  switch (type) {
    case VehicleConfig.SELECTABLE_EQUIPMENT:
      summaryData = prepareVehicleConfig(
        `${VehicleConfig.SELECTABLE_EQUIPMENT}_${equipementData?.id || ''}`,
        equipementData?.sku,
        equipementData?.name,
        equipementData?.priceExcludingVat,
        equipementData?.vatCode,
      );
      break;
    case VehicleConfig.RIM:
      summaryData = prepareVehicleConfig(
        VehicleConfig.RIM,
        equipementData?.sku,
        equipementData?.name,
        getRimPrice(equipementData, priceUpdate),
        equipementData?.vatCode,
      );
      break;
    case VehicleConfig.COLOR:
      summaryData = prepareVehicleConfig(
        VehicleConfig.COLOR,
        color?.varnishCode,
        color?.name,
        getColorPrice(color, priceUpdate),
        color?.vatCode,
      );
      break;
    case VehicleConfig.INTERIOR:
      summaryData = prepareVehicleConfig(
        VehicleConfig.INTERIOR,
        interior?.interiorCode,
        interior?.name,
        getInteriorsPrice(interior, priceUpdate),
        interior?.vatCode,
      );
      break;
    case VehicleEquipmentCategoryEnum.Listverk:
      summaryData = prepareVehicleConfig(
        VehicleEquipmentCategoryEnum.Listverk,
        equipementData?.sku,
        equipementData?.name,
        getListverkPrice(equipementData, priceUpdate),
        equipementData?.vatCode,
      );
      break;
    case VehicleEquipmentCategoryEnum.Taklakk:
      summaryData = prepareVehicleConfig(
        VehicleEquipmentCategoryEnum.Taklakk,
        equipementData?.sku,
        equipementData?.name,
        getTaklakkPrice(equipementData, priceUpdate),
        equipementData?.vatCode,
      );
      break;
  }

  return summaryData;
};

export const prepareArticlesBasedOnTypeForDefaults = (
  sku: string,
  type: string,
  existingArticles: INextArticle[],
  priceUpdate: Map<any, any>,
  selectedSkuData: IEquipmentTypes,
  color: IVarnish,
  interior: IInterior,
) => {
  let newArticles = [...filterAccessoryFromArticles(existingArticles)];

  switch (type) {
    case VehicleConfig.SELECTABLE_EQUIPMENT:
      newArticles = [
        ...prepareArticles(
          `${VehicleConfig.SELECTABLE_EQUIPMENT}_${selectedSkuData?.id || ''}`,
          selectedSkuData?.priceExcludingVat,
          selectedSkuData?.vatCode,
          ArticleType.EQUIPMENT,
          selectedSkuData?.retailPrice,
          false,
          newArticles,
          false,
          sku,
        ),
      ];
      break;
    case VehicleConfig.RIM:
      newArticles = [
        ...prepareArticles(
          VehicleConfig.RIM,
          getRimPrice(selectedSkuData, priceUpdate),
          selectedSkuData?.vatCode,
          ArticleType.EQUIPMENT,
          calculateRetailPrice(getRimPrice(selectedSkuData, priceUpdate), selectedSkuData?.marginPercentage),
          false,
          newArticles,
          false,
          sku,
        ),
      ];
      break;
    case VehicleConfig.COLOR:
      newArticles = [
        ...prepareArticles(
          VehicleConfig.COLOR,
          getColorPrice(color, priceUpdate),
          color?.vatCode,
          ArticleType.EQUIPMENT,
          calculateRetailPrice(getColorPrice(color, priceUpdate), color?.marginPercentage),
          false,
          newArticles,
          false,
          sku,
        ),
      ];
      break;
    case VehicleConfig.INTERIOR:
      newArticles = [
        ...prepareArticles(
          VehicleConfig.INTERIOR,
          getInteriorsPrice(interior, priceUpdate),
          interior?.vatCode,
          ArticleType.EQUIPMENT,
          calculateRetailPrice(getInteriorsPrice(interior, priceUpdate), interior?.marginPercentage),
          false,
          newArticles,
          false,
          sku,
        ),
      ];
      break;
    case VehicleEquipmentCategoryEnum.Listverk:
      newArticles = [
        ...prepareArticles(
          VehicleEquipmentCategoryEnum.Listverk,
          getListverkPrice(selectedSkuData, priceUpdate),
          selectedSkuData?.vatCode,
          ArticleType.EQUIPMENT,
          calculateRetailPrice(getListverkPrice(selectedSkuData, priceUpdate), selectedSkuData?.marginPercentage),
          false,
          newArticles,
          false,
          sku,
        ),
      ];
      break;
    case VehicleEquipmentCategoryEnum.Taklakk:
      newArticles = [
        ...prepareArticles(
          VehicleEquipmentCategoryEnum.Taklakk,
          getTaklakkPrice(selectedSkuData, priceUpdate),
          selectedSkuData?.vatCode,
          ArticleType.EQUIPMENT,
          calculateRetailPrice(getTaklakkPrice(selectedSkuData, priceUpdate), selectedSkuData?.marginPercentage),
          false,
          newArticles,
          false,
          sku,
        ),
      ];
      break;
  }

  return newArticles;
};

export const getEquipDataForSKU = (currentVariantData: IModelPackages, sku: string, type: string) => {
  let equipementData: IEquipmentTypes = null;
  let color: IVarnish = null;
  let interior: IInterior = null;
  let isTillagg: boolean = false;

  switch (type) {
    case VehicleEquipmentCategoryEnum.Listverk:
      equipementData = getListverkFromEquipList(currentVariantData?.selectableEquipment)?.find(
        (equip) => equip?.sku === sku,
      );
      break;
    case VehicleEquipmentCategoryEnum.Taklakk:
      equipementData = getTaklakFromEquipList(currentVariantData?.selectableEquipment)?.find(
        (equip) => equip?.sku === sku,
      );
      break;
    case VehicleConfig.SELECTABLE_EQUIPMENT:
      isTillagg = true;
      equipementData = [
        ...getExterirTilleggFromEquipList(currentVariantData?.selectableEquipment),
        ...getInteriorTilleggFromEquipList(currentVariantData?.selectableEquipment),
      ]?.find((equip) => equip?.sku === sku);
      break;
    case VehicleConfig.COLOR:
      color = currentVariantData?.colors?.find((color) => color?.varnishCode === sku);
      break;
    case VehicleConfig.RIM:
      equipementData = currentVariantData?.rims?.find((rim) => rim?.sku === sku);
      break;
    case VehicleConfig.INTERIOR:
      interior = currentVariantData?.interiors?.find((interior) => interior?.interiorCode === sku);
      break;
  }

  return { equipementData, color, interior, isTillagg };
};

export const updateArticlesForDefaultSkus = (
  skus: string[],
  skusWithType: { [key: string]: string },
  existingArticles: INextArticle[],
  priceUpdate: Map<any, any>,
  currentVariantData: IModelPackages,
  vehicleConfiguration: IVehicleConfiguration,
): IArticlesResponse => {
  let isOneOffTaxError: boolean = false;

  let updatedArticles = cloneDeep(existingArticles);
  let tilleggList = [];
  skus?.forEach((sku) => {
    const type = skusWithType[sku];
    let { equipementData, color, interior, isTillagg } = getEquipDataForSKU(currentVariantData, sku, type);
    if (isTillagg) tilleggList?.push(sku);

    updatedArticles = prepareArticlesBasedOnTypeForDefaults(
      sku,
      type,
      updatedArticles,
      priceUpdate,
      equipementData,
      color,
      interior,
    );
  });

  //Update TAREWEIGHT Articles for KIA
  if (tilleggList?.length && invokeProvidedBrandSpecificCode(MAKE.Kia, { vehicleConfiguration })) {
    const selectedEquipments = vehicleConfiguration?.model?.selectableEquipment?.filter((equipment) =>
      tilleggList?.includes(equipment?.sku),
    );
    const kiaArticlesResponse = getOneOffTaxForKia(vehicleConfiguration, selectedEquipments, updatedArticles);
    updatedArticles = kiaArticlesResponse?.updatedArticles;
    isOneOffTaxError = kiaArticlesResponse?.isOneOffTaxError;
  }

  return {
    updatedArticles,
    isOneOffTaxError,
  };
};

export const getAndPrepareSelectedDataAfterDefaultCheck = (
  { selectedColorId, selectedInteriorId, selectedEquipmentIds, selectedListverk, selectedRims, selectedTaklakk }: ICurrentSelections,
  currentVariantData: IModelPackages[],
  rulesPayload: string[],
  onloadDisableSku
): ISelectionDataWithPayload => {
  const {
    equipmentIds,
    rimIds,
    colorId,
    interiorId,
    listverkData,
    taklakkData,
  } = reApplyPreSelectionOnSkus(currentVariantData?.[0], rulesPayload, onloadDisableSku, {
    equipmentIds: selectedEquipmentIds,
    rimIds: selectedRims,
    colorId: selectedColorId,
    interiorId: selectedInteriorId,
    listverkData: selectedListverk,
    taklakkData: selectedListverk
  })

  const [rulesPayloadWithPreSelection, rulesPayloadWithType]: [string[], { [key: string]: string; }] = prepareRulesPayloadFromSelectedData({
    equipmentIds,
    rimIds,
    colorId,
    interiorId,
    listverkData,
    taklakkData,
  });

  return { equipmentIds, rimIds, colorId, interiorId, listverkData, taklakkData, rulesPayload: rulesPayloadWithPreSelection, rulesPayloadWithType }
};