import { bosColor, typography } from '@next-components/common-styles';
import { FinancingType } from 'next-common';
import styled, { css } from 'styled-components';

export const OrderAccordionWrapper = styled.div<{ financingType: FinancingType }>`
  width: 100%;

  .newNext,
  .newNext[open],
  .newNext[open]:hover {
    button.btnAccordion:not(.search-button) {
      border: none;
    }
    .nextAccordion > div {
      margin-top: 1px;
    }
  }
  .newNext[disabled] {
    cursor: not-allowed;
    button.btnAccordion:not(.search-button) {
      cursor: inherit;
    }
  }
  &:has(.newNext.leasingTabs) {
    .content[open].nextAccordion {
      overflow: unset;
    }
  }
  .newNext.leasingTabs {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .custom-accessory-grid-block {
    .nextAccordion {
      &.content[open] {
        padding: 0;
      }
    }
  }
  .custom-accessory-add {
    .nextAccordion {
      padding: 2.5rem 1rem !important;
      border: 1px solid ${bosColor.neutralGrey};
    }
  }

  ${({ financingType }) =>
    financingType === FinancingType.CASH &&
    css`
      .nextAccordion.content[open] {
        overflow: unset;
        .calculation-type.cash {
          .tableContainer {
            overflow: unset;
          }
        }
      }
    `}
  ${({ contentOverflow }) =>
    contentOverflow &&
    css`
      .nextAccordion.content[open] {
        overflow: unset;
      }
    `}

  .packageCommentModal {
    .custom-modal {
      border-radius: 0.5rem;
    }
  }
  .modalContainer {
    .custom-modal {
      .close-modal-button {
        display: flex;
        width: 2rem;
        height: 2rem;
        padding: 0.375rem;
        justify-content: center;
        align-items: center;
        background-color: ${bosColor.lightGrey};
        border-radius: 62.4375rem;
        top: 1rem;
        right: 1.5rem;

        > svg {
          flex-shrink: 0;
          width: 1.25rem;
          height: 1.25rem;
          scale: 1.4;
        }
      }
      .inner-container {
        padding: 1.5rem;
        .content-container {
          & > div {
            padding-top: 0;

            .cover-image {
              img {
                object-fit: contain;
              }
            }
            .title + p > * {
              font-size: 1rem;
              font-style: normal;
              font-weight: ${typography.weight.semibold};
              line-height: 175%;
            }
          }
        }
      }
    }
  }
`;
