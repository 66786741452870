// @ts-expect-error
const crmBaseUrl = process.env.REACT_APP_CRM_BASE_URL || window._env_.REACT_APP_CRM_BASE_URL;
// @ts-expect-error
const crmAppId = process.env.REACT_APP_CRM_APP_ID || window._env_.REACT_APP_CRM_APP_ID;
export const crmQuotePath = (quoteId: string) =>
  !!crmBaseUrl && !!crmAppId
    ? `${crmBaseUrl}main.aspx?appid=${crmAppId}&pagetype=entityrecord&etn=quote&id=${quoteId}`
    : null;

// @ts-expect-error
export const appId = process.env.REACT_APP_ID || window._env_.REACT_APP_ID || '';
// @ts-expect-error
export const tenantId = process.env.REACT_APP_TENANT_ID || window._env_.REACT_APP_TENANT_ID || '';
// @ts-expect-error
export const redirectURL = process.env.REACT_APP_REDIRECT_URL || window._env_.REACT_APP_REDIRECT_URL || '';
export const loginURL = `https://login.microsoftonline.com/${tenantId}`;
export const msGraphApiRoot = 'https://graph.microsoft.com';

// @ts-expect-error
export const disableFactoryOrder = window._env_.REACT_APP_DISABLE_FACTORY_ORDER || process.env.REACT_APP_DISABLE_FACTORY_ORDER;

// @ts-expect-error
export const customerId = process.env.REACT_APP_DEFAULT_CUSTOMER_ID || window._env_.REACT_APP_DEFAULT_CUSTOMER_ID || '';

// @ts-expect-error
export const apimEndpoint = process.env.REACT_APP_APIM_URL || window._env_.REACT_APP_APIM_URL || null;

// @ts-expect-error
export const saleAllCarsMbImageToken = process.env.REACT_APP_SALE_ALL_CARS_MB_IMAGE_TOKEN || window._env_.REACT_APP_SALE_ALL_CARS_MB_IMAGE_TOKEN || null;
// @ts-expect-error
const serviceNowBaseUrl = process.env.REACT_APP_SERVICENOW_URL || window._env_.REACT_APP_SERVICENOW_URL || null
// @ts-expect-error
const serviceNowUrlPathSegment = process.env.REACT_APP_SERVICENOW_PATH_SEGMENTS || window._env_.REACT_APP_SERVICENOW_PATH_SEGMENTS || null
export const serviceNowSupportUrl = `${serviceNowBaseUrl}${serviceNowUrlPathSegment}` || ''
// @ts-expect-error
export const salesCommonCacheUrl = process.env.REACT_APP_SALES_COMMON_CACHE_END_POINT || window._env_.REACT_APP_SALES_COMMON_CACHE_END_POINT || null;

// @ts-expect-error
export const appEnv = process.env.REACT_APP_ENV || window._env_.REACT_APP_ENV || null;
// @ts-expect-error
export const manualSignFileUploadSizeInMb = process.env.REACT_APP_MANUAL_SIGN_FILE_UPLOAD_SIZE_IN_MB || window._env_.REACT_APP_MANUAL_SIGN_FILE_UPLOAD_SIZE_IN_MB || '{}';
// @ts-expect-error
export const salesCommonCacheStaticAuthToken = process.env.REACT_APP_SALES_COMMON_CACHE_STATIC_AUTH_TOKEN || window._env_.REACT_APP_SALES_COMMON_CACHE_STATIC_AUTH_TOKEN || null;
