import React, { useEffect } from 'react';
import { gql, useApolloClient, useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import {
  FinancingType,
  getVehicleOrderAttachmentsQuery,
  IBosApiAttachmentData,
  IBosApiSendSmsData,
  IBosOrderDraft,
  IBosVehicleOrderStatus,
  ICalculation,
  IConfiguration,
  INextArticle,
  IOrder,
  isEditable,
  isPcodeEnableForCurrentBrand,
  isStockSaleOrderToBeProcessed,
  IVehicleConfiguration,
  NextErrorCode,
  OrderStatus,
} from 'next-common';
import { Tooltip } from '@next-components/tooltip';
import { Button } from '@next-components/cta';
import { ICommonSalesOfferPdfProps, isOffer, isShowServiceContractLink, isShowSignedContractLink } from '../OrderPane';
import {
  cancelOrderMutationParsed,
  ERROR_POLICY,
  findRouteForOrder,
  isStockOrder,
  resumeOrderMutationParsed,
  Routes,
  selectedDealerId,
  sendSigningJobMutationParsed,
  updateOrderAndCalculationsWithChargesMutationParsed,
  updateOrderForEcomMutationParsed,
  useEmployee,
  useServiceContract,
} from '../../../../../common';
import { SalesOfferPdf } from '../../../sales-common/SalesOfferPdf';
import { ContractPDF } from '../../../sales-common/ContractPDF';
import { CancelOrderModal } from '../../../sales-common/CancelOrderModal';
import { ActionButtonContainer, FileButtonContainer, FilesWrapper, OrderButtonsStyled } from './OrderButtons.styled';
import { isOrder } from '..';
import {
  Events,
  eventEmitter,
  filterContracts,
  getChargesFromVehicleStatus,
  hasReservationBlockingCancellation,
  isEcomOrder,
  isOrderEditable,
  isOrderInvoiced,
  mapEmployeeToUser,
} from '../../../../../utils';
import { SendSmsModal } from './SendSmsModal';
import { ServiceContractPDF } from '../../../sales-common/ServiceContractPDF';
import { ICartProps } from '@next-components/cart';
import { useMsal } from '@azure/msal-react';
import { IEquipmentIdAndSKU } from 'sales-crystallize-common';
import { useNavigate } from 'react-router-dom';
import { IVehicleStockEcomItem } from 'sales-common';
import { NextErrorNotification } from '../../../../notifications';
import { MessagebarSeverityLevel } from '@next-components/messagebar';
import { cloneDeep, remove } from 'lodash';
import { IGetServiceContract } from 'sales-common';
import { getPCodeMasterListQueryParsed } from '../../../../../graphql';
import { PageSteps } from '../../../sales-common/NewHeader';

interface IOrderButtonsProps extends ICommonSalesOfferPdfProps {
  status: OrderStatus;
  order: IOrder;
  vehicleConfig: IVehicleConfiguration;
  vehicleOrderStatus: IBosVehicleOrderStatus;
  vehicleReserveStatus: boolean;
  isSigned: boolean;
  onStatusChange(status: OrderStatus): void;
  cartData?: ICartProps;
  mappedEquipment: IEquipmentIdAndSKU[];
  configuration?: IConfiguration;
  orderEditButtonDisabled?: boolean;
  pageLoading?: boolean;
  articles?: INextArticle[];
  carType?: string;
  dealerRole?: string;
  isTaxChange?: boolean;
  selectedVehicleData?: IVehicleStockEcomItem;
  setNotifications?(value: JSX.Element[]): void;
  orderDraft?:IBosOrderDraft
  setIsLoading(loading: boolean): void;
}

export const getVehicleOrderAttachmentsParsed = gql`
  ${getVehicleOrderAttachmentsQuery}
`;

export const checkExpireOrder = (status) => {
  return OrderStatus?.EXPIRED === OrderStatus[status as string];
};

export const OrderButtons = (props: IOrderButtonsProps) => {
  const client = useApolloClient();
  const [contracts, setContracts] = React.useState<IBosApiAttachmentData[]>([]);
  const [reopenLoading, setReopenLoading] = React.useState<boolean>(false);
  const [sendSmsLoading, setSmsLoading] = React.useState<boolean>(false);
  const [showCancelModal, setShowCancel] = React.useState<boolean>(false);
  const [showSendSmsModal, setShowSendSms] = React.useState<boolean>(false);
  const [sendSmsResult, setSendSmsResult] = React.useState<string>(null);
  const [editable, setEditable] = React.useState<boolean>(isOrderEditable(props.order, props.configuration));
  const signingJob = props?.order?.signingJob;

  const [cancelOrderMutation] = useMutation(cancelOrderMutationParsed);
  const [sendSigningSmsMutation] = useMutation(sendSigningJobMutationParsed);
  const [resumeOrderMutation] = useMutation(resumeOrderMutationParsed);

  const { accounts } = useMsal();
  const employee = useEmployee(accounts?.[0]?.username);
  const navigate = useNavigate();
  const [filteredContracts, setFilteredContracts] = React.useState<IBosApiAttachmentData[]>([]);

  const { data: serviceContract } = useServiceContract(
    client,
    {
      serialNo: props?.order?.vehicles?.[0]?.serialNo,
      sku: props?.order?.orderReference?.pimCode,
      activeStep: PageSteps.FINANACE,
      createdOrderType: props?.order?.lead?.orderType
    }
  );

  const serviceContractForLoan = (serviceContract: IGetServiceContract) => {
    const serviceContractCopy = cloneDeep(serviceContract)
    remove(serviceContractCopy?.paymentPeriodDataList, (element) => {
      return element?.month === -1 && element?.name === 'Full';
    });
    return serviceContractCopy;
  }

  const [getContracts] = useLazyQuery(getVehicleOrderAttachmentsParsed, {
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
    variables: {
      vehicleOrderId: props?.order?.id,
    },
    onCompleted: (data) => {
      setContracts(data?.getVehicleOrderAttachments);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      setContracts(null);
    },
  });

  const [updateOrderForEcom] = useMutation(updateOrderForEcomMutationParsed, {
    errorPolicy: ERROR_POLICY,
  });

  const [updateOrderAndCalculationsWithCharges] = useMutation(updateOrderAndCalculationsWithChargesMutationParsed, {
    errorPolicy: ERROR_POLICY,
  });

  const isInvoiced = isOrderInvoiced(props?.order);

  React.useEffect(() => {
    if (!sendSmsResult) return;
    setTimeout(() => {
      setSendSmsResult(null);
    }, 5000);
  }, [sendSmsResult]);

  React.useEffect(() => {
    if (editable || signingJob === null) {
      getContracts()
        .then((resposne) => resposne)
        .catch((error) => console.error(error));
    }
  }, [props?.order?.status]);

  useEffect(() => {
    if (contracts?.length > 0) {
      setFilteredContracts(filterContracts(contracts));
    }
  }, [contracts]);

  useEffect(() => {
    setEditable(isOrderEditable(props.order, props.configuration));
  }, [props?.status]);

  const selectedDealer = useReactiveVar(selectedDealerId);
  const getUpdateOrderVariables = (order: IOrder) => ({
    id: order?.id,
    input: {
      user: employee ? mapEmployeeToUser(employee, selectedDealer) : null,
    },
  });

  const updateOrder = async (order: IOrder) => {
    try {
      const variables = getUpdateOrderVariables(order);
      return await updateOrderForEcom({ variables });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const updateOrderAndCalculations = async () => {
    const { updatedWreckage, updatedOneOffTaxWeight, updatedCo2Tax, updatedNoxTax } = getChargesFromVehicleStatus(
      props?.selectedVehicleData,
    );
    const variables = {
      orderId: props?.order?.id,
      charges: {
        extraWreckage: updatedWreckage,
        oneOffTaxWeight: updatedOneOffTaxWeight,
        co2Tax: updatedCo2Tax,
        noxTax: updatedNoxTax,
      },
    };
    const { data } = await updateOrderAndCalculationsWithCharges({ variables });
    if (data?.updateOrderAndCalculationsWithCharges?.__typename === 'NextError') {
      props?.setNotifications([
        <NextErrorNotification
          key={'recalculationFail'}
          errorCode={NextErrorCode.RECALCULATION_ERROR}
          onClose={() => props?.setNotifications([])}
          severityLevel={MessagebarSeverityLevel.ERROR}
          hideLink={true}
        />,
      ]);
      setReopenLoading(false);
    } else {
      onContinue(props?.order).catch((error) => console.error(error));
    }
  };

  const [getPCodeList] = useLazyQuery(getPCodeMasterListQueryParsed, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: ERROR_POLICY,
    onCompleted: (data) => {
      props.setIsLoading(false);
      return;
    },
    onError: (error) => {
      props.setIsLoading(false);
      console.log(error);
    },
  });
  
  const onContinue = async (order: IOrder) => {
    const isStockSaleOrder = isStockSaleOrderToBeProcessed(props?.configuration, order?.lead?.source, null, '');
    const isVarebilEnabled = isPcodeEnableForCurrentBrand(props.configuration, { order });
    if (isStockOrder(order?.lead?.orderType)) {
      const route = findRouteForOrder(order);
      return navigate(route);
    }
    if (isVarebilEnabled) {
      let route = '';
      let isPCodesAvailable = Boolean(order?.pCodes?.length);
      if(!isPCodesAvailable) {
        const PCodeDataList = await getPCodeList({ variables: { articleCode: order?.pimSnapshot?.salesArticle } });
        isPCodesAvailable = Boolean(PCodeDataList?.data?.getPCodeMasterList?.length);
      }
      if(isPCodesAvailable) {
        route = Routes.getImporterOptionsPage(props?.order?.id);
      } else {
        route = Routes.getVehicleAccessoriesPage(props?.order?.id);
      }
      return navigate(route);
    }
    if (isEcomOrder(order?.source) || isStockSaleOrder) {
      if (!order?.user || order?.user?.employeeId !== employee?.employeeId) {
        await updateOrder(order);
      }
    }
    if ((isStockSaleOrder || order?.vehicles?.[0]?.serialNo) && !isStockOrder(order?.lead?.orderType)) {
      const route = Routes.getVehicleAccessoriesPage(props?.order?.id);
      return navigate(route);
    }
    const route = findRouteForOrder(order);
    return navigate(route);
  };

  const onCancel = () => {
    navigate(Routes.getHomePage());
  };

  const resumeOrder = async () => {
    try {
      const { data } = await resumeOrderMutation({
        variables: { orderId: props?.order?.id },
      });
      const order: IOrder = data?.resumeOrder || props?.order;
      props.onStatusChange(order?.status);
      return order;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const reopen = async () => {
    setReopenLoading(true);
    const status = OrderStatus[props?.status?.toString()];
    if (status === OrderStatus.PAUSED || status === OrderStatus.CANCELLED) {
      const order = await resumeOrder();
      if (!order) return;
      // eslint-disable-next-line no-console
      onContinue(order).catch((error) => console.error(error));
    } 
    if (props.isTaxChange) {
      await updateOrderAndCalculations();
    } else {
      // eslint-disable-next-line no-console
      onContinue(props?.order).catch((error) => console.error(error));
    }
  };

  const sendSigningSms = async () => {
    setSmsLoading(true);
    setSendSmsResult(null);
    try {
      const { data } = await sendSigningSmsMutation({
        variables: { id: props?.order?.id },
      });
      const result: IBosApiSendSmsData = data?.sendSigningJob;
      setSmsLoading(false);
      setSendSmsResult(result ? `Sendt${result?.statusCode ? result?.statusCode : ''}` : 'Klarte ikke sende');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setSmsLoading(false);
      setSendSmsResult('Sending feilet');
    }
  };

  const cancelOrder = async () => {
    try {
      const { data } = await cancelOrderMutation({
        variables: { orderId: props?.order?.id },
      });
      const order: IOrder = data?.cancelOrder || props?.order;
      props.onStatusChange(order?.status);
      return order;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const onInitCancel = () => {
    setShowCancel(!showCancelModal);
  };

  const onInitSendSms = () => {
    setShowSendSms(!showSendSmsModal);
  };

  const onCancelConfirm = async (confirmed: boolean) => {
    setShowCancel(false);
    if (!confirmed) return;
    await cancelOrder();
    onCancel();
  };

  const onSendSmsConfirm = async (confirmed: boolean) => {
    setShowSendSms(false);
    if (!confirmed) return;
    await sendSigningSms();
  };

  const disableCancelButton = hasReservationBlockingCancellation(props?.order?.reserveDetails);

  const getAdditionalText = (filteredContracts: IBosApiAttachmentData[], index: number): string => {
    if (filteredContracts?.length > 1) {
      return `(versjon ${filteredContracts?.length - index})`;
    }
    return '';
  };

  const getContractsToBeDisplayed = (): JSX.Element | JSX.Element[] => {
    return isEditable(props?.order?.status)
      ? filteredContracts &&
          filteredContracts?.length > 0 &&
          filteredContracts?.map((contract, index) => {
            return (
              <div className="file-icon" key={`file_${contract?.id}`}>
                <ContractPDF
                  order={props?.order}
                  contract={contract}
                  additionalText={getAdditionalText(filteredContracts, index)}
                />
              </div>
            );
          })
      : (isShowServiceContractLink(props?.status, props?.order, signingJob) ||
          isShowSignedContractLink(props?.status, props?.order, signingJob)) && (
          <div className="file-icon">
            <ServiceContractPDF order={props?.order} signingJob={signingJob} />
          </div>
        );
  };

  const getServiceContractToBeDesiplayed = (offers: ICalculation[]) => {
    if (!offers || offers.length === 0) {
      return null;
    }
    const isLoan = offers.some(offer => offer?.financingType === FinancingType.LOAN);

    return isLoan ? serviceContractForLoan(serviceContract) : serviceContract
  }
  return (
    <OrderButtonsStyled>
      <FileButtonContainer>
        {!editable &&
          (isShowServiceContractLink(props?.status, props?.order, signingJob) ||
            (contracts && contracts?.length > 0)) && (
            <FilesWrapper>
              {isShowServiceContractLink(props?.status, props?.order, signingJob) && (
                <div className="file-icon">
                  <ServiceContractPDF order={props?.order} signingJob={signingJob} />
                </div>
              )}
              {contracts && contracts?.length > 0 && (
                <div className="file-icon">
                  <ContractPDF order={props?.order} contract={contracts?.[0]} />
                </div>
              )}
              {isShowSignedContractLink(props?.status, props?.order, signingJob) && (
                <div className="file-icon">
                  <ServiceContractPDF order={props?.order} signingJob={signingJob} />
                </div>
              )}
            </FilesWrapper>
          )}
        {!props?.pageLoading &&
          props?.cartData &&
          ((!isStockOrder(props?.order?.lead?.orderType) && editable) || isOffer(props?.status, true, true)) && (
            <FilesWrapper>
              {editable && getContractsToBeDisplayed()}
              {isOffer(props?.status, true, true) && (
                <div className="file-icon">
                  <SalesOfferPdf
                    order={props?.order}
                    vehicleConfig={props?.vehicleConfig}
                    cartData={props?.cartData}
                    mappedEquipment={props?.mappedEquipment}
                    vatPrices={props.vatPrices}
                    configuration={props?.configuration}
                    className={'downloadOffers noWrapper'}
                    articles={props?.articles}
                    carType={props?.carType}
                    dealerRole={props?.dealerRole}
                    serviceContract={getServiceContractToBeDesiplayed(props?.order?.offers)}
                    orderDraft={props?.orderDraft}
                  />
                </div>
              )}
            </FilesWrapper>
          )}
      </FileButtonContainer>
      {props?.order && !checkExpireOrder(props?.status) && (
        <ActionButtonContainer className="flexWrapper">
          {!isStockOrder(props?.order?.lead?.orderType) && editable && !isOrderInvoiced(props.order) && (
            <div>
              <Button disabled={props.orderEditButtonDisabled || isInvoiced} isLoading={reopenLoading} onClick={reopen}>
                {reopenLoading ? '' : 'Åpne og endre ordre'}
              </Button>
            </div>
          )}
          {isOffer(props?.status, true) && !editable && (
            <Tooltip
              text={props?.order?.vehicleOrderStatus?.customerOrderStatus}
              enabledCondition={isInvoiced}>
              <Button
                className='btnGjenoppta'
                disabled={props?.vehicleReserveStatus || props?.isSigned || isInvoiced}
                isLoading={reopenLoading}
                onClick={reopen}>
                {reopenLoading ? '' : 'Gjenoppta'}
              </Button>
            </Tooltip>
          )}
          {isOffer(props?.status) && (
            <Tooltip
              text="For å slette tilbudet må du fjerne reservasjonen på bilen først."
              enabledCondition={disableCancelButton}>
              <Button
                className={'red'}
                variant={'secondary'}
                onClick={onInitCancel}
                disabled={disableCancelButton || props?.isSigned}>
                <span>Slett tilbud</span>
              </Button>
            </Tooltip>
          )}

          {isOffer(props?.status) && props?.order?.signingJobUrl && (
            <div>
              <Button
                variant={'secondary'}
                isLoading={sendSmsLoading}
                disabled={!!sendSmsResult}
                onClick={onInitSendSms}>
                {sendSmsLoading ? '' : 'Send signerings-SMS'}
              </Button>
              <div>{sendSmsResult}</div>
            </div>
          )}
        </ActionButtonContainer>
      )}
      <SendSmsModal show={showSendSmsModal} order={props?.order} onConfirm={onSendSmsConfirm} />
      <CancelOrderModal show={showCancelModal} isOrder={isOrder(props?.status)} onConfirm={onCancelConfirm} />
    </OrderButtonsStyled>
  );
};
