import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { CustomerType, FinancingType, getFinancingTypeTranslatedValue, ICalculation, MAKE } from 'next-common';
import { formatNumber } from '@next-components/common-types';
import {
  LeasingOptionPriceStyled,
  LegalStuffStyled,
  OptionDataStyled,
  OptionElementLeasingStyled,
  OptionElementsStyled,
  OptionElementStyled,
  OptionPriceStyled,
  SectionStyled,
} from './common-styles';
import { getTotalPrice, isBusinessCustomer, isIndependentDealer } from '../../../../../utils';
import { IOrderOffers } from '../SalesOfferPdf';
import { backgroundCard, bosBrand, kiaColor, lightGray, mercedesColor, peugeotColor, srtColor } from '@next-components/common-styles';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getRequestedNominalInterest, getTotalPriceIncVat, getNominalInterest, getMonthlyPrice, getOriginalDownPayment, getIncVatDownPayment } from '../../FinancingDialog';
import { FinanceMakeStyle } from './Finance.style';

interface ICalculationOptionsProps extends ICalculation {
  carPrice?: number;
  isElectric?: boolean;
}
type ICalculationOptionsPropsUpdated = ICalculationOptionsProps & { isOfferExpire: boolean, make: string, totalMVA?: number, pageBreak: boolean, salesTotalIncludingVatAndTax?: number, dealerRole?: string };

type ICalculationUpdated = ICalculation & { isOfferExpire: boolean, make: string, isElectric?: boolean, salesTotalIncludingVatAndTax?: number, dealerRole?: string };

const getBgColor = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    return backgroundCard;
  } else {
    switch (brand) {
      case MAKE.Smart:
        return srtColor.silver;
      default:
        return backgroundCard;
    }
  }
};

const getLegalBgColor = (dealer: string, brand: string, customerType?: CustomerType) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    return backgroundCard;
  } else {
    switch (brand) {
      case MAKE.Smart:
        return srtColor.silver;
      default:
        return isBusinessCustomer(customerType) ? backgroundCard : lightGray;
    }
  }
};

const getOptionPriceBgColor = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    switch (brand) {
      case MAKE.Smart:
        return srtColor.black;
      case MAKE.Mercedes:
        return mercedesColor.black;
      case MAKE.Peugeot:
        return peugeotColor.black;
      case MAKE.Kia:
        return kiaColor.black;
      default:
        return bosBrand;
    }
  } else {
    switch (brand) {
      case MAKE.Smart:
        return srtColor.black;
      default:
        return bosBrand;
    }
  }
};

const OptionElement = (props) => {
  const bgColor = getBgColor(props.dealerRole, props.make);
  return (
    <View style={{ ...OptionElementStyled, backgroundColor: bgColor}}>
      <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['optionElementLabel']}>{props?.label}</Text>
      <Text style={{...FinanceMakeStyle[props.dealerRole][props?.make]['optionElementValue'], flexWrap: 'nowrap',}}>{props?.value}</Text>
    </View>
  );
}

const OptionPrice = (props) => {
  const isBusinessCust = isBusinessCustomer(props.customerType);
  return (
    <View style={{ ...isBusinessCustomer(props.customerType) ? LeasingOptionPriceStyled : OptionPriceStyled, backgroundColor: getOptionPriceBgColor(props?.dealerRole, props?.make) }}>
      <View>
        <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['optionPriceLabel']}>{props?.label}</Text>
        <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['optionPriceValue']}>{props?.value}</Text>
      </View>
      {props?.isCash && props?.isServiceContractExist && (
        <View>
          <Text style={{ fontSize: 8, marginTop: 2 }}>
            {props?.isCheckBoxSelected === true
              ? `herav mva. ${formatNumber(props?.totalMVA)} kr`
              : 'Serviceavtale er ikke inkludert i total pris'}
          </Text>
        </View>
      )}
      {
        props.otherValue && (
          <View>
            <Text style={{ fontSize: 8 }}>{`${formatNumber(props.otherValue || 0, 2)}`}</Text>
          </View>
        )
      }
      {!props?.isCash && (
        <View style={{...FinanceMakeStyle[props.dealerRole][props?.make]['RenteLabel'][isBusinessCust ? 'BUSINESS' : 'PRIVATE']}}>
         <Text style={{ fontSize: 8 }}>Rente:</Text>
         <Text style={{ fontSize: 8, paddingLeft: `${isBusinessCustomer(props.customerType) ? '5px': ''}` }}>{`${formatNumber(props?.rent || 0, 2)} %`}</Text>
        </View>
      )}
    </View>
  )
}

const calcLegalYears = (months?: number) => {
  if (isNaN(months)) return 0;
  return Math.floor(months / 12);
};

const PrivateLegalLeasing = (props: ICalculationUpdated) => (
  <View style={{ ...LegalStuffStyled, backgroundColor: getLegalBgColor(props.dealerRole, props?.make) }}>
    <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['legalText']}>
      Priseksempel privatleasing fra Bertel O. Steen Finans, levert av DNB ASA: Startleie/ etabl.gebyr:
      kr {formatNumber(props?.financing?.request?.downPayment + props?.financing?.establishmentFeeInclVat + props?.financing?.documentFeeInclVat)}
      ,- månedsleie: kr {formatNumber(getMonthlyPrice(props?.financing))}
      ,- og totalpris: kr {formatNumber(getTotalPriceIncVat(props?.financing) || 0)}
      ,- Dette vil variere med rentenivået, som er flytende. {calcLegalYears(
        props?.financing?.request?.durationMonths,
      )}{' '}
      års bindingstid, kjørelengde: {`${formatNumber(props?.financing?.request?.mileage || 0)} km`}. Priseksempelet
      inkluderer etablering og termingebyr
    </Text>
    {props.isElectric && (
      <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['legalText']}>
        Leiebeløp mangler mva på etableringsgebyr og termingebyr som vil medføre økning tilsvarende mva på forholdsmessig del av bilens pris over kr 500 000.
      </Text>
    )}
  </View>
);

const BusinessLegalLeasing = (props: ICalculationUpdated) => {
  const fontStyleValue = props?.make == MAKE.Smart || isIndependentDealer(props.dealerRole) ? null : 'italic';
  return <View style={{ ...LegalStuffStyled, backgroundColor: getLegalBgColor(props.dealerRole, props?.make, props.customerType) }}>
    {/* Inline style in below statement will be replace in "FinanceMakeStyle" */}
    <Text style={{...FinanceMakeStyle[props.dealerRole][props?.make]['legalText'], fontSize: '6', fontWeight: 500, fontStyle: fontStyleValue }}>
      {/* "leasingDownPaymentExclVat" field needed here (check mapping pdf) */}
      Priseksempel leasing fra Bertel O. Steen Finans, levert av DNB ASA: Startleie/etableringsgebyr: {`${formatNumber((props.financing?.establishmentFeeExclVat + props.financing?.leasingDownPaymentExclVat) || 0)} kr`} (eks mva) kommer i tillegg.
      månedsleie: {`${formatNumber(props?.financing?.monthlyCostExclVatAndFee || 0)} kr`} og totalpris (eks mva): {`${formatNumber(props?.financing?.totalCostIncludingFeeExclVat || 0)} kr`}. Dette vil variere med rentenivået, som er flytende. {calcLegalYears(
        props?.financing?.request?.durationMonths,
      )}{' '} års bindingstid, kjørelengde: {`${formatNumber(props?.financing?.request?.mileage || 0)} km`}.
    </Text>
  </View>
};

const LegalLoan = (props: ICalculationUpdated) => {
  const InterestExpense = getTotalPriceIncVat(props.financing) - props.salesTotalIncludingVatAndTax;
  return <View style={{ ...LegalStuffStyled, backgroundColor: getLegalBgColor(props.dealerRole, props?.make) }}>
    <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['legalText']}>
      Billån fra Bertel O. Steen Finans i samarbeid med DNB ASA. Nominell flytende rente{' '}
      {formatNumber(getRequestedNominalInterest(props?.financing) || getNominalInterest(props?.financing) || 0, 2)} % / effektiv flytende rente{' '}
      {formatNumber(props?.financing?.effectiveInterest || 0, 2)} %. Egenkapital{' '}
      {formatNumber(props?.financing?.request?.downPayment || 0)} kr, lånebeløp:{' '}
      {formatNumber(props?.financing?.loanAmountInclVat || 0)} kr over{' '}
      {calcLegalYears(props?.financing?.request?.durationMonths || 0)} år. Rentekostnad kr{' '}
      {formatNumber(InterestExpense || 0)}, totalt kr{' '}
      {formatNumber(getTotalPriceIncVat(props?.financing) || 0)},-. Finansiering med
      forbehold om godkjenning av Finansieringsselskap. Eventuell kampanjerente er flytende og justeres etter 36 mnd til
      gjeldende markedsrente på tidspunktet.
    </Text>
  </View>
};

const loanValue = (
  props: ICalculationUpdated,
  serviceavtalePrice: number,
  postFix: string,
  offerExpireFlag: boolean,
) => {
  let value = '';
  if (props?.serviceContract?.isLoanPaymentIntervalSelected === true) {
    if (offerExpireFlag) {
      value = 'Inkludert i lån';
    } else {
      value = 'inkludert I terminbeløp';
    }
  } else {
    value = `${serviceavtalePrice || ''} ${postFix || ''}`;
  }
  return value;
};

const getCustomerTypeString = (customerType: CustomerType): string => {
  return customerType?.toString() === CustomerType[CustomerType.BUSINESS] ? 'kr / mnd inkl. mva' : 'kr / mnd';
};


const ServiceContracts = (props: ICalculationUpdated) => {
  const postFix = props?.serviceContract
    ? (props?.financingType === FinancingType.LOAN || props?.financingType === FinancingType.CASH) &&
      props?.serviceContract?.paymentPeriodMonth !== -1 &&
      props?.serviceContract?.paymentPeriodMonth !== 12
      ? props?.serviceContract?.paymentPeriodMonth === 1
        ? getCustomerTypeString(props?.customerType)
        : `kr / ${props?.serviceContract?.paymentPeriodMonth} mnd`
      : 'kr / år'
    : getCustomerTypeString(props?.customerType)
  const serviceavtalePrice =
    props?.serviceContract &&
      (props?.financingType === FinancingType.LOAN || props?.financingType === FinancingType.CASH) &&
      props?.serviceContract?.paymentPeriodMonth !== -1
      ? formatNumber(
        props?.serviceContract?.totalPrice /
        ((props?.serviceContract?.duration ? props?.serviceContract?.duration : 1) /
          (props?.serviceContract?.paymentPeriodMonth ? props?.serviceContract?.paymentPeriodMonth : 1)),
      )
      : 0;

  return (
    <View>
      <View style={{ ...OptionDataStyled, backgroundColor: getBgColor(props.dealerRole, props?.make) }}>
        <View style={{ flexDirection: 'column', width: '100%', height: '100%' }}>
          <View style={OptionElementsStyled}>
            {props?.financingType === FinancingType.LEASING && (
              <OptionElement
                value={`${props?.financing?.request?.durationMonths || '-'} Måneder`}
                label="Varighet serviceavtale"
                make={props?.make}
                dealerRole={props.dealerRole} 
              />
            )}
            {props?.financingType !== FinancingType.LEASING && (
              <OptionElement
                value={calcDurationYears(props?.serviceContract?.duration)}
                label="Varighet serviceavtale"
                make={props?.make}
                dealerRole={props.dealerRole} 
              />
            )}
            {props?.financingType !== FinancingType.LEASING && (
              <OptionElement
                value={
                  formatNumber(
                    (props?.serviceContract?.drivingDistance || 0) *
                    (!isNaN(props?.serviceContract?.duration)
                      ? Math.floor(props?.serviceContract?.duration / 12)
                      : 0),
                  ) + ' km'
                }
                label="Total kjørelengde"
                make={props?.make}
                dealerRole={props.dealerRole} 
              />
            )}
            {props?.financingType === FinancingType.LEASING && (
              <OptionElement
                value={
                  formatNumber(
                    (props?.financing?.request?.mileage || 0) *
                    (!isNaN(props?.financing?.request?.durationMonths)
                      ? Math.floor(props?.financing?.request?.durationMonths / 12)
                      : 0),
                  ) + ' km'
                }
                label="Total kjørelengde"
                make={props?.make}
                dealerRole={props.dealerRole} 
              />
            )}
            {props?.financingType === FinancingType.LOAN ? (
              <OptionElement
                value={loanValue(props, serviceavtalePrice, postFix, props?.isOfferExpire)}
                label="Serviceavtale"
                make={props?.make}
                dealerRole={props.dealerRole} 
              />
            ) : (
              <OptionElement
                value={
                  props?.financingType === FinancingType.CASH
                    ? props?.serviceContract?.paymentPeriodMonth !== -1
                      ? `${serviceavtalePrice} ${postFix}`
                      : `Inkludert i bilpris`
                    : `${formatNumber(props?.serviceContract?.monthlyPrice || 0)} ${getCustomerTypeString(props?.customerType)}`
                }
                label="Serviceavtale"
                make={props?.make}
                dealerRole={props.dealerRole} 
              />
            )}
            <View style={{ ...OptionElementStyled, backgroundColor: getBgColor(props.dealerRole, props.make) }} />
          </View>
        </View>
      </View>
    </View>
  );
};

const PriavteLeasing = (props: ICalculationUpdated) => {
  return (
    <View>
      <View style={{ ...OptionDataStyled, backgroundColor: getBgColor(props.dealerRole, props?.make) }}>
        <View style={{ flexDirection: 'column', width: '75%', height: '100%' }}>
          <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['financeLeasingHead']}>Leasing</Text>
          <View style={OptionElementsStyled}>
            <OptionElement 
              value={`${props?.financing?.request?.durationMonths || '-'} Måneder`} 
              label="Avtalelengde" 
              make={props?.make}
              dealerRole={props.dealerRole} 
            />
            <View style={{ ...OptionElementLeasingStyled, backgroundColor: getBgColor(props.dealerRole, props?.make) }}>
              <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['optionElementLabel']}>{'Startleie (inkl. mva)'}</Text>
              <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['optionElementValue']}>{`kr ${formatNumber(getOriginalDownPayment(false, props?.financing?.request) || 0)}`}</Text>  
              {/* <Text style={{ fontSize: 10, fontWeight: 'bold' }}>{`kr ${formatNumber(getIncVatDownPayment(props?.financing, props?.financing?.downPayment || getOriginalDownPayment(false, props?.financing?.request) || 0))} inkl mva`}</Text> */}
            </View>
            <OptionElement
              value={`${formatNumber(props?.financing?.request?.mileage || 0)} km`}
              label="Årlig kjørelengde"
              make={props?.make}
              dealerRole={props.dealerRole} 
            />
            <OptionElement
              value={`${formatNumber(getMonthlyPrice(props?.financing) || 0)} kr / mnd`}
              label="Leasingavtale"
              make={props?.make}
              dealerRole={props.dealerRole} 
            />
          </View>
          {!!props?.serviceContract?.id && (
            <>
              <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['serviceavtaleHead']}>Serviceavtale</Text>
              <View style={OptionElementsStyled}>
                <ServiceContracts {...props} />
              </View>
            </>
          )}

          {isIndependentDealer(props.dealerRole) ? <PrivateLegalLeasing {...props} /> : ''}
        </View>
        <OptionPrice
          value={`kr ${formatNumber(getTotalPrice(
            getMonthlyPrice(props?.financing) || 0,
            props?.serviceContract?.monthlyPrice || 0,
          ))}`}
          rent={getRequestedNominalInterest(props?.financing) || getNominalInterest(props?.financing) || 0}
          label="Totalpris / mnd"
          make={props?.make}
          dealerRole={props.dealerRole} 
        />
      </View>
      {props.dealerRole !== 'INDEPENDENT_DEALER' ? <PrivateLegalLeasing {...props} /> : '' }
    </View>
  );
};

//Move this fuction to separate file once whole offer document file created
const BusinessLeasing = (props: ICalculationUpdated) => {
  return (
    <View>
      <View style={{ ...OptionDataStyled, backgroundColor: getBgColor(props.dealerRole, props?.make) }}>
        <View style={{ flexDirection: 'column', width: '73%', height: '100%', paddingTop: '12' }}>
          <View style={OptionElementsStyled}>
            <OptionElement
              value={`${props?.financing?.request?.durationMonths || '-'} Måneder`} 
              label="Leieperiode"
              make={props?.make}
              dealerRole={props.dealerRole} 
            />
            <OptionElement
              value={`${formatNumber(props?.financing?.request?.mileage || 0)} km`}
              label="Årlig kjørelengde"
              make={props?.make}
              dealerRole={props.dealerRole} 
            />
            <View style={{ ...OptionElementLeasingStyled, backgroundColor: getBgColor(props.dealerRole, props?.make) }}>
              <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['optionElementLabel']}>{'Startleie eks. mva'}</Text>
              <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['optionElementValue']}>{`${formatNumber(props?.financing?.leasingDownPaymentExclVat || 0)} kr`}</Text>  
              <Text style={{ fontSize: 6 }}>{`${formatNumber(getOriginalDownPayment(false, props?.financing?.request) || 0)} kr inkl. mva`}</Text>
            </View>

            <View style={{ ...OptionElementLeasingStyled, backgroundColor: getBgColor(props.dealerRole, props?.make) }}>
              <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['optionElementLabel']}>{'Månedsleie eks. mva'}</Text>
              <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['optionElementValue']}>{`${formatNumber(props?.financing?.monthlyCostExclVatAndFee || 0)} kr`}</Text>  
              <Text style={{ fontSize: 6 }}>{`${formatNumber(props?.financing?.monthlyCostInclVatAndFee)} kr inkl. mva`}</Text>
            </View>
          </View>
          {!!props?.serviceContract?.id && (
            <>
              <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['serviceavtaleHead']}>Serviceavtale</Text>
              <View style={OptionElementsStyled}>
                <ServiceContracts {...props} />
              </View>
            </>
          )}
          <BusinessLegalLeasing {...props} />
        </View>
          <OptionPrice
            value={`${formatNumber(props?.financing?.monthlyCostExclVatAndFee || 0)} kr`}
            rent={getRequestedNominalInterest(props?.financing) || getNominalInterest(props?.financing) || 0}
            otherValue={`${formatNumber(props?.financing?.monthlyCostInclVatAndFee) || 0} kr inkl. mva`}
            label="Totalpris / mnd, eks. mva"
            make={props?.make}
            dealerRole={props.dealerRole} 
            customerType={props.customerType}
          />
      </View>
    </View>
  );
};

const calcDurationYears = (months?: number) => {
  if (isNaN(months)) return '-';
  const showMonths = months % 12 > 0;
  return `${Math.floor(months / 12)} år ${showMonths ? `${months % 12} mnd` : ''}`;
};

const Loan = (props: ICalculationOptionsPropsUpdated) => {
  /* calculate totalPrice based on checkbox selection */
  const investedAmount = Number(props?.financing?.request?.investedAmount) || 0;
  const downPayment = Number(props?.financing?.request?.downPayment) || 0;
  let totalPrice = investedAmount - downPayment;

  if (props?.serviceContract?.isLoanPaymentIntervalSelected === true) {
    totalPrice = props?.serviceContract?.totalPrice + totalPrice;
  }
  const FundedAmount = props?.financing?.loanAmountInclVat || 0 - downPayment;
  
  return (
    <View>
      <View style={{ ...OptionDataStyled, backgroundColor: getBgColor(props.dealerRole, props?.make) }}>
        <View style={{ flexDirection: 'column', width: '75%', height: '100%' }}>
          <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['financeLanHead']}>Lån</Text>
          <View style={OptionElementsStyled}>
            <OptionElement
              value={calcDurationYears(props?.financing?.request?.durationMonths)}
              label="Nedbetalingstid"
              make={props?.make}
              dealerRole={props.dealerRole}
            />
            <OptionElement
              value={`kr ${formatNumber(props?.financing?.request?.downPayment || 0)}`}
              label="Egenkapital"
              make={props?.make}
              dealerRole={props.dealerRole}
            />
            {props?.isOfferExpire ? (
              <OptionElement
                value={`${formatNumber(getMonthlyPrice(props?.financing) || 0)} kr / mnd`}
                label="Lånavtale"
                make={props?.make}
                dealerRole={props.dealerRole}
              />
            ) : (
                <OptionElement 
                  value={`kr ${formatNumber(FundedAmount || 0)}`} 
                  label="Finansiert beløp" 
                  make={props?.make} 
                  dealerRole={props.dealerRole} 
                />
            )}
            <View style={{ ...OptionElementStyled, backgroundColor: getBgColor(props.dealerRole, props.make) }} />
          </View>
          {!!props?.serviceContract?.id && (
            <>
              <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['serviceavtaleHead']}>Serviceavtale</Text>
              <View style={OptionElementsStyled}>
                <ServiceContracts {...props} />
              </View>
            </>
          )}

          {props.dealerRole === 'INDEPENDENT_DEALER' ? <LegalLoan {...props} /> : ''}
        </View>
        {props?.isOfferExpire ? (
          <OptionPrice
            value={`kr ${formatNumber(totalPrice)}`}
            rent={getRequestedNominalInterest(props?.financing) || getNominalInterest(props?.financing) || 0}
            label="Totalpris"
            make={props?.make}
            dealerRole={props.dealerRole} 
            customerType={props.customerType}
          />
        ) : (
          <OptionPrice
            //Lånavtale
            value={`${formatNumber(getTotalPrice(
              getMonthlyPrice(props?.financing) || 0,
              props?.serviceContract?.monthlyPrice || 0,
            ))} kr / mnd`}
            rent={getRequestedNominalInterest(props?.financing) || getNominalInterest(props?.financing) || 0}
            label="Terminbeløp"
            make={props?.make}
            dealerRole={props.dealerRole} 
            customerType={props.customerType}
          />
        )}
      </View>
      {props.dealerRole !== 'INDEPENDENT_DEALER' ? <LegalLoan {...props} /> : ''}
    </View>
  );
};

const Cash = (props: ICalculationOptionsPropsUpdated) => {
  return (
    <View
      style={{ ...OptionDataStyled, backgroundColor: getBgColor(props.dealerRole, props?.make) }}>
      <View style={{ flexDirection: 'column', width: '75%', height: '100%' }}>
        {props.serviceContract?.id ? (
          <>
            <View style={OptionElementsStyled}>
              <View
                style={{
                  ...OptionElementStyled,
                  backgroundColor: getBgColor(props.dealerRole, props?.make),
                }}
              />
              <View
                style={{
                  ...OptionElementStyled,
                  backgroundColor: getBgColor(props.dealerRole, props?.make),
                }}
              />
              <View
                style={{
                  ...OptionElementStyled,
                  backgroundColor: getBgColor(props.dealerRole, props?.make),
                }}
              />
              <View
                style={{
                  ...OptionElementStyled,
                  backgroundColor: getBgColor(props.dealerRole, props?.make),
                }}
              />
            </View>
            {props?.serviceContract?.isLoanPaymentIntervalSelected && (
              <View style={OptionElementsStyled}>
                <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['financeCashHead']}>Sum å betale</Text>
              </View>
            )}
            {!props?.serviceContract?.isLoanPaymentIntervalSelected && (
              <>
                <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['serviceavtaleHead']}>Serviceavtale</Text>
                <View style={OptionElementsStyled}>
                  <ServiceContracts {...props} />
                </View>
              </>
            )}
          </>
        ) : null}
      </View>
      <OptionPrice
        value={`${formatNumber(props?.carPrice)} kr`}
        label={props.serviceContract?.isLoanPaymentIntervalSelected ? '' : 'Pris'}
        isCash={true}
        isCheckBoxSelected={props?.serviceContract?.isLoanPaymentIntervalSelected}
        isServiceContractExist={!!props?.serviceContract?.id}
        make={props?.make}
        totalMVA={props?.totalMVA}
        dealerRole={props.dealerRole} 
        customerType={props.customerType}
      />
    </View>
  );
};

const OptionStyled: Style = {
  marginTop: 5,
  marginBottom: 20,
};

const getFinancingDisplayText = (props: ICalculationOptionsPropsUpdated): string =>
  getFinancingTypeTranslatedValue(props?.financingType);


const Option = (props: ICalculationOptionsPropsUpdated) => (
  <View style={OptionStyled} wrap={false} break={props?.pageBreak}>
    {props?.financing !== null ? (
      <Text style={FinanceMakeStyle[props.dealerRole][props?.make]['optionLabel']}>
        {getFinancingDisplayText(props)}
      </Text>
    ) : null}

    {props?.financingType === FinancingType.LEASING && props?.financing !== null ? isBusinessCustomer(props.customerType) ? <BusinessLeasing {...props} /> : <PriavteLeasing {...props} />  : null}
    {props?.financingType === FinancingType.LOAN && <Loan {...props} />}
    {props?.financingType === FinancingType.CASH && <Cash {...props} />}
  </View>
);

const OptionListStyled: Style = {
  padding: '0 5',
};

type IOrderOffersUpdated = IOrderOffers & {customerType: CustomerType, isOfferExpire: boolean, make: string, totalMVA: number, totalPrice: number, isElectric: boolean, pageBreak?: boolean, pageWrap: boolean, salesTotalIncludingVatAndTax: number, dealerRole?: string };

export const FinanceOptions = (props: IOrderOffersUpdated) => {
  const prices = props?.cash?.map((calculation) => calculation?.financing?.totalCost)?.[0];
  const price = prices ? prices : 0;

  const renderFinanceHtml = (financeData: ICalculation[], type: string, salesTotalIncludingVatAndTax: number = 0) => {
    let itemCount = 0;
    return financeData?.map((item: ICalculation) => {
      if (itemCount === 4) {
        itemCount = 0;
      } else {
        itemCount++;
      }
      if (type === 'loan') {
        return (
          <Option
            key={uuidv4()}
            {...item}
            carPrice={price}
            isOfferExpire={props?.isOfferExpire}
            make={props?.make}
            pageBreak={itemCount === 4 ? true : false}
            salesTotalIncludingVatAndTax={salesTotalIncludingVatAndTax}
            dealerRole={props.dealerRole}
            customerType={props?.customerType}
          />
        );
      }
      if (type === 'leasing') {
        return (
          <Option
            key={uuidv4()}
            {...item}
            isOfferExpire={props?.isOfferExpire}
            make={props?.make}
            isElectric={props.isElectric}
            pageBreak={itemCount === 4 ? true : false}
            dealerRole={props.dealerRole}
            customerType={props?.customerType}
          />
        );
      }
      if (type === 'cash') {
        return (
          <Option
            key={uuidv4()}
            {...item}
            carPrice={props?.totalPrice}
            isOfferExpire={props?.isOfferExpire}
            make={props?.make}
            totalMVA={props?.totalMVA}
            pageBreak={itemCount === 4 ? true : false}
            dealerRole={props.dealerRole}
            customerType={props?.customerType}
          />
        );
      }
    });
  };
  
  return (
    <View style={SectionStyled} break={props.pageBreak}>
      <Text style={FinanceMakeStyle[props.dealerRole][props.make]['heading']} >
        {isEmpty(props?.loan) && isEmpty(props?.leasing) && isEmpty(props?.cash)
          ? 'Tom liste'
          : "Forslag til finansiering"}
      </Text>
      <View style={OptionListStyled}>
        {props?.loan?.length > 0 && <View> {renderFinanceHtml(props?.loan, 'loan', props?.salesTotalIncludingVatAndTax)}</View>}
        {props?.leasing?.length > 0 && <View> {renderFinanceHtml(props?.leasing, 'leasing')}</View>}
        {props?.cash?.length > 0 && <View>{renderFinanceHtml(props?.cash, 'cash')}</View>}
      </View>
    </View>
  );
};
