import { bosColor, lightGray, typography, white } from '@next-components/common-styles';
import styled from 'styled-components';

export const AgreementsComparisonListStyled = styled.div`
  width: 100%;
  button {
    border-bottom: 1px solid #ccc;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    span.label {
      font-size: 1.125rem;
    }
  }
  button + div > div {
    padding: 0;
  }
  .tableContainer {
    border: 1px solid ${lightGray};
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .header {
    font-weight: ${typography.weight.semibold};
    align-items: center;
    justify-content: center;
    background: ${bosColor.neutralGrey};
    padding: 0.75rem 1rem;
    gap: 0.5rem 0;
    border-radius: 0.5rem 0.5rem 0 0;

    & > div {
      color: ${bosColor.black};
      font-size: 1rem;
      font-style: normal;
      font-weight: ${typography.weight.extrabold};
      line-height: 175%;
      width: 100%;

      &:not(&:first-child) {
        text-align: center;
      }
    }
  }
  .header,
  .item {
    grid-template-columns: 4fr 1fr 1fr;
  }
  &.alternateLeasingComparisonList {
    .tableContainer {
      .header,
      .item {
        grid-template-columns: 4fr 1.5fr 1.5fr !important;
      }
    }
  }
  .item {
    background-color: ${white};
    margin: 0;
    padding: 0;
    column-gap: 0;
    padding: 0.75rem 1rem;

    &:not(&:last-child) {
      border-bottom: 1px solid ${bosColor.neutralGrey};
    }
    &:hover {
      cursor: default;
      border: none;
    }
    &:last-child {
      border-radius: 0 0 0.5rem 0.5rem;
    }
    & > div {
      width: 100%;
      display: flex;
      align-items: center;
      align-self: stretch;
      gap: 0.5rem;

      &:not(div:first-child) {
        justify-content: center;
      }
      &:first-child {
        height: 100%;
        line-height: 2;
        color: ${bosColor.black};
        font-size: 1rem;
        font-style: normal;
        font-weight: ${typography.weight.semibold};
        line-height: 175%;
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .bg-grey {
    svg > path {
      fill: ${bosColor.neutralPlus};
    }
  }
  .bg-green {
    svg > path {
      fill: ${bosColor.komorebi};
    }
  }
  .br-100 {
    border-radius: 100%;
  }
  .footer {
    padding: 0.5rem 1rem;
    width: 100%;
    background-color: ${lightGray};
  }
`;
