import { ContentTransformer, NodeContent, NodeProps, Overrides } from '@crystallize/reactjs-components';
import { SingleDetails } from '@next-components/single-details';
import { IEquipmentTypes, IInterior, IVarnish } from 'next-common';
export interface IVehicleDescription {
  data: IInterior | IVarnish | IEquipmentTypes;
  active: boolean;
  disabled?: boolean;
  onClose?: () => void;
}

export const ContentTransformerWrapper = (included: string | any[]): JSX.Element => {
  try {
    const jsonData = typeof included === 'string' ? JSON.parse(included) : included;
    return <ContentTransformer json={jsonData} />;
  } catch (error) {
    console.warn(`VehicleSelectableDescription-ContentTransformerWrapper: ${error}`);
  }
};

export const VehicleSelectableDescription = (props: IVehicleDescription) => {
  const { data, active, onClose } = props;
  const description: JSX.Element = ContentTransformerWrapper(data?.description);
  return (
    <SingleDetails
      onCloseClick={() => onClose()}
      active={active}
      title={data?.name}
      details={description}
      img={data?.popupImage}
    />
  );
};
