import React, { useEffect, useState } from 'react';
import { CustomModal } from '@next-components/custom-modal';
import { FileDropContainer } from '@next-components/file-drop';
import { FinancingType, IOrder, OrderStatus, isEditable } from 'next-common';
import { ModalInner } from '../../../sales-common/modal.styled';
import { ContractPDF } from '../../../sales-common/ContractPDF';
import { findSsn, isStepDisabled } from './signing.utils';
import { AnalogSigningModalStyled, AnalogSigningStepStyled } from './AnalogSigningModal.styled';
import { CustomerInputs } from '../../../sales-common/CustomerInputs';
import { manualSignFileUploadSizeParse, validateSsnOnCustomerType } from '../../../../../utils';
import { Button } from '@next-components/cta';
import { CustomModalWrapper } from '../../../../layout/CustomModalWrapper.styled';
import get from 'lodash/get';
import { Icon, IconType } from '@next-components/bos-icon';
import { bosColor } from '@next-components/common-styles';
import { INotificationPageProps, manualSignFileUploadSizeInMb } from '../../../../../common';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar/lib/Messagebar';

export interface IAnalogSigningModalProp extends INotificationPageProps {
  order: IOrder;
  show: boolean;
  softLockForMinutes?: number;

  onConfirm(confirmed: boolean, files: File[]): void;
  createOrder(): Promise<any>;
}

interface IAnalogSigningStep {
  step: number;
  disabled?: boolean;
  info?: string;
  children: React.ReactNode;
}

const AnalogSigningStep = (props: IAnalogSigningStep) => {
  return (
    <AnalogSigningStepStyled className={`${props?.disabled ? 'disabled' : ''}`}>
      <h3 className="text-lg">{`Steg ${props?.step}`}</h3>
      {props?.info && <div className="info">{props?.info}</div>}
      {props?.children}
    </AnalogSigningStepStyled>
  );
};

export const AnalogSigningModal = (props: IAnalogSigningModalProp) => {
  const [files, setFiles] = React.useState<File[]>([]);
  const [hasValidSsn, setValidSsn] = useState(false);
  const [hasFiles, setHasFiles] = useState(false);
  const [createOrderErrorMessage, setCreateOrderErrorMessage] = useState(null);
  const [isVehicleOrderCreated, setIsVehicleOrderCreated] = useState(false);
  const [isCreateOrderProcessing, setIsCreateOrderProcessing] = useState(false);
  const [isUploadContractProcessing, setIsUploadContractProcessing] = useState(false);
  const [isOrderCreatable, setIsOrderCreatable] = useState<boolean>(true);
  const [isLargeFileSelected, setIslargeFileSelected] = useState<boolean>(false);
  const isServiceContract = !!props.order?.chosenOffer?.serviceContract;

  const isCash = props.order?.chosenOffer?.financingType === FinancingType.CASH;
  const { fileSize } = JSON.parse(manualSignFileUploadSizeParse()) || '{}';

  useEffect(() => {
    if (!props.order) return;
    setIsCreateOrderProcessing(true);
    const isCreated = [OrderStatus[OrderStatus.DRAFT_CREATED]].includes(
      // @ts-expect-error
      props.order?.status,
    );
    setIsVehicleOrderCreated(isCreated);
    setIsCreateOrderProcessing(false);
  }, [props.order?.status]);

  useEffect(() => {
    if (!props.order) return;
    const value = findSsn(props.order?.customer, isCash);
    if (isCash)
      value !== null && value !== ''
        ? setValidSsn(validateSsnOnCustomerType(value, props.order?.customer))
        : setValidSsn(true);
    else setValidSsn(validateSsnOnCustomerType(value, props.order?.customer));
  }, [props.order?.customer]);

  useEffect(() => {
    setIsOrderCreatable(isVehicleOrderCreated && !isEditable(props.order?.status));
  }, [isVehicleOrderCreated, props.order]);

  useEffect(() => {
    if (isLargeFileSelected) {
      raiseFileSelectionError();
    }
  }, [isLargeFileSelected])

  if (!props.show) return null;

  const createOrder = async () => {
    setIsCreateOrderProcessing(true);
    setCreateOrderErrorMessage(await props.createOrder());
    setIsCreateOrderProcessing(false);
  };

  const uploadContract = async () => {
    setIsUploadContractProcessing(true);
    await props.onConfirm(true, files);
    setIsUploadContractProcessing(false);
  };

  const handleFileDrop = (fileList) => {
    fileList?.map((file) => {
      if ((file.size / (1024 * 1024)) > fileSize) {
        setIslargeFileSelected(true);
        handleFileRemove(fileList);
      } else {
        setFiles(fileList);
        setHasFiles(fileList.length > 0);
        setIslargeFileSelected(false);
      }
    })
  };

  const handleFileAdd = (fileList) => {
    fileList?.map((file) => {
      if ((file.size / (1024 * 1024)) > fileSize) {
        setIslargeFileSelected(true);
        handleFileRemove(fileList);
      }else{
        setFiles(fileList);
        setHasFiles(fileList.length > 0);
        setIslargeFileSelected(false);
      }
    })
  };

  const raiseFileSelectionError = () => {
    props.raiseNotification(
      <Messagebar
        key={'fileSelectionError'}
        severityLevel={MessagebarSeverityLevel.ERROR}
        title={`Maksimal størrelse for filopplasting er ${fileSize} MB `}
        onClose={() => props?.clearNotifications('fileSelectionError')}
      />,
    );
  };

  const handleFileRemove = (fileList) => {
    setFiles(fileList);
    setHasFiles(fileList.length > 0);
  };

  const onSsnValidityChange = (value: boolean) => {
    setValidSsn(value);
  };

  const isFactoryOrder = Boolean(get(props, 'order.vehicles[0].orderFromFactoryDetails', null));
  
  return (
    <CustomModalWrapper className="signing-modal backdrop smModal width32" active={props.show}>
      <AnalogSigningModalStyled>
        <CustomModal
          active={props.show}
          onCloseClick={() => props.onConfirm(false, [])}
          isModelCloseAllowedOutside={false}>
          <header>
            <h2>Signering på papir</h2>
          </header>
          <ModalInner>
            <AnalogSigningStep step={1}>
              {!isVehicleOrderCreated && (
                <Button isLoading={isCreateOrderProcessing} onClick={createOrder} disabled={isOrderCreatable}>
                  Opprett ordre
                </Button>
              )}
              {isVehicleOrderCreated && (
                <div className="successMessageWrapper">
                  <Icon icon={IconType.NewNextCheckFilled} color={bosColor.komorebi} />
                  <h3 className="display-xs">Ordre opprettet</h3>
                </div>
              )}
              {props.softLockForMinutes && !isFactoryOrder ? (
                <span className="text-sm">Bilen vil bli holdt av i {props.softLockForMinutes} minutter</span>
              ) : null}
              {createOrderErrorMessage && <div className="errorMsg">{createOrderErrorMessage}</div>}
              {props.order?.vehicleOrderStatus?.status === 'FAILURE' && (
                <div className="errorMsg">{props.order?.vehicleOrderStatus.statusMessage}</div>
              )}
            </AnalogSigningStep>
            <AnalogSigningStep step={2} disabled={isStepDisabled(2, isVehicleOrderCreated)}>
              <div className="download" style={{ display: 'grid', gridTemplateColumns: '1fr', alignItems: 'center' }}>
                <ContractPDF
                  order={props.order}
                  generate
                  isDisabled={isStepDisabled(2, isVehicleOrderCreated)}
                  isServiceContract={isServiceContract}
                />
              </div>
            </AnalogSigningStep>
            <AnalogSigningStep step={3} disabled={isStepDisabled(2, isVehicleOrderCreated)}>
              <div className="upload">
                <FileDropContainer
                  onDrop={handleFileDrop}
                  onRemove={handleFileRemove}
                  disabled={isStepDisabled(2, isVehicleOrderCreated)}
                  onAdd={handleFileAdd}
                  title="Last opp ..."
                  label={'Dra og slipp filer eller klikk for å laste opp'}
                  displayIcon={IconType.NewNextPlus}
                  className="newNext"
                />
              </div>
            </AnalogSigningStep>
            <AnalogSigningStep step={4} disabled={isStepDisabled(4, isVehicleOrderCreated, hasFiles)}>
              <CustomerInputs
                customer={props.order?.customer}
                financingType={props.order?.chosenOffer?.financingType}
                onSsnValidityChange={onSsnValidityChange}
                disabled={isStepDisabled(4, isVehicleOrderCreated, hasFiles)}
              />
            </AnalogSigningStep>
            <div className="footerButton">
              <Button
                className="analogModalBtn"
                isLoading={isUploadContractProcessing}
                onClick={uploadContract}
                disabled={isStepDisabled(5, isVehicleOrderCreated, hasFiles, hasValidSsn) || isLargeFileSelected}>
                Send signert kontrakt
              </Button>
              <Button variant="secondary" onClick={() => props.onConfirm(false, [])}>
                Avbryt
              </Button>
            </div>
          </ModalInner>
        </CustomModal>
      </AnalogSigningModalStyled>
    </CustomModalWrapper>
  );
};
