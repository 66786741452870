import React, { ReactNode } from 'react';
import { Accordion } from '@next-components/accordion';
import { OrderAccordionWrapper } from './OrderAccordion.styled';
import { FinancingType } from 'next-common';

export interface IOrderAccordionProps {
  label: string;
  isOpen?: boolean;
  isDisabled?: boolean;
  children: ReactNode;
  className?: string;
  financingType?: FinancingType;
  contentOverflow?: boolean;
  setIsCustomAccessoryOpen?(data: boolean): void;
}

export const OrderAccordion = (props: IOrderAccordionProps) => (
  <OrderAccordionWrapper
    className="OrderAccordionWrapper"
    financingType={props.financingType}
    contentOverflow={props?.contentOverflow}>
    <Accordion
      primaryLabel={props.label}
      onClick={() => {
        if (props?.setIsCustomAccessoryOpen) {
          props.setIsCustomAccessoryOpen(!props?.isOpen);
        }
      }}
      isOpen={props.isOpen}
      isDisabled={props.isDisabled}
      nextAccordion
      className={`small newNext ${props.className ?? ''}`}
      greyMode>
      {props.children}
    </Accordion>
  </OrderAccordionWrapper>
);

OrderAccordion.defaultProps = {
  isDisabled: false,
  contentOverflow: false,
};
