import { Accordion } from '@next-components/accordion';
import { MarginAccordion } from './MarginCart.styled';
import {
  IAccessoryList,
  IMargin,
  IMarginInput,
  INextArticle,
  IOrder,
  IRammeavtale,
  IVehicleConfiguration,
  getLatestMargin,
  mapVatPricesForNext,
  prepareArticlesFromNextOrder,
} from 'next-common';
import { Button } from '@next-components/button';
import { ToggleSwitch } from '@next-components/toggle-switch';
import { cloneDeep, get } from 'lodash';
import { CalculateDiscount } from './CalculateDiscount';
import { CalculateImporterDiscount } from './CalculateImporterDiscount';
import { FrameworkAgreement } from './FrameworkAgreement';
import { MarginCartDetails } from './MarginCartDetails';
import { useEffect, useState } from 'react';
import { ICartPrices, ICartSummaryData } from '@next-components/cart';
import { useMutation } from '@apollo/client';
import { createAndAddMarginToOrderMutationParsed, useConfigurations } from '../../../common';
import { checkHasMarginChanged, checkHasRammeavtaleChanged, postAndSaveMargin } from './MarginCartUtils';
import { ArticleType } from 'sales-common';
import { calculateVatForElCars } from '../../../utils';
import { ICalculatePricesBasedOnArticleListResult } from 'sales-common-calculations';

interface IMarginCartProps {
  order: IOrder;
  vehicleConfiguration: IVehicleConfiguration;
  articles: INextArticle[];
  vatPrices: ICartPrices;
  carType: string;
  isUnavailablePimData?: boolean;
  saveAccessories?(): Promise<IOrder>;
  accessoryChanges?: ICartSummaryData[];
  onUpdateArticles?(value: IAccessoryList, customerAdvantage?: boolean): void;
  isPcodePage?: boolean;
  isPcodeChangeFound?: boolean;
  isPcodePriceChangeFound?: boolean;
  isSaveFromMargin?: boolean;
  isPcodeChangeCancelled?: boolean;
  setIsSaveFromMargin?(value: boolean): void;
  setIsPcodeChangeCancelled?(vale: boolean): void;
  rammeavtale?: IRammeavtale;
  setRammeavtale?(rammeavtale: IRammeavtale): void;
}

export const MarginCart = (props: IMarginCartProps) => {
  const [checkedIndex, setCheckedIndex] = useState(false);
  const [checkedAgreement, setCheckedAgreement] = useState(false);
  const [latestMargin, setLatestMargin] = useState<IMargin>(getLatestMargin(get(props, 'order.margins', null)));
  const [activeMargin, setActiveMargin] = useState<IMarginInput>(getLatestMargin(get(props, 'order.margins', null)));
  const [updatedMargin, setUpdatedMargin] = useState<IMarginInput>(null);
  const [hasMarginChanged, setHasMarginChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [vatPrices, setVatPrices] = useState<ICartPrices>(null);
  const [articles, setArticles] = useState<INextArticle[]>([]);
  const [isValidCampaignDiscount, setIsValidCampaignDiscount] = useState<boolean>(true);
  const [isValidImporterDiscount, setIsValidImporterDiscount] = useState<boolean>(true);
  const [isValidAccessoriesDiscount, setIsValidAccessoriesDiscount] = useState<boolean>(true);
  const [createAndSaveMarginMutation] = useMutation(createAndAddMarginToOrderMutationParsed);

  const configurations = useConfigurations();

  const isRammeavtaleEnabled = configurations?.features?.rammeavtale ?? false;

  useEffect(() => {
    setVatPrices(props.vatPrices);
    setArticles(props.articles);
  }, [props.vatPrices, props.articles]);

  useEffect(() => {
    setLatestMargin(getLatestMargin(get(props, 'order.margins', null)));
    setActiveMargin(getLatestMargin(get(props, 'order.margins', null)));
  }, [props.order?.margins]);

  useEffect(() => {
    setHasMarginChanged(
      checkHasMarginChanged(latestMargin, activeMargin) ||
      checkHasRammeavtaleChanged(props?.order?.rammeavtale, props?.rammeavtale),
    );
  }, [activeMargin]);

  useEffect(() => {
    setHasMarginChanged(checkHasRammeavtaleChanged(props?.order?.rammeavtale, props?.rammeavtale))
  }, [props?.order?.rammeavtale, props?.rammeavtale]);

  const handleSwitchToggle = (): void => {
    setCheckedIndex(!checkedIndex);
  };

  useEffect(() => {
    if (
      props.isSaveFromMargin &&
      !props.isPcodeChangeFound &&
      !props.isPcodeChangeCancelled &&
      !props.isPcodePriceChangeFound
    ) {
      async function saveMargin() {
        setLoading(true);
        await saveMarginData();
      }
      saveMargin();
    } else {
      setLoading(false);
    }
  }, [props.isSaveFromMargin, props.isPcodeChangeCancelled, props.isPcodeChangeFound]);

  const handleAgreementSwitchToggle = (): void => {
    setCheckedAgreement(!checkedAgreement);
  };

  const saveMargin = async (): Promise<void> => {
    if (hasMarginChanged) {
      setLoading(true);
      if (typeof props.saveAccessories === 'function') {
        await props?.saveAccessories();
      }
      if (props.isPcodePage) {
        props.setIsSaveFromMargin(true);
      } else {
        await saveMarginData();
      }
    }
  };

  const saveMarginData = async () => {
    //Articles here
    let articlesForDNBCalculations = props.isPcodePage
      ? prepareArticlesFromNextOrder(props.order)
      : cloneDeep(articles);

    const index = articlesForDNBCalculations?.findIndex((e) => e.articleType === ArticleType.CUSTOMERADVANTAGE);

    if (index > 0) {
      articlesForDNBCalculations[index].priceExclVat =
        updatedMargin?.campaignDiscount ?? activeMargin?.campaignDiscount;
    }

    const data = await postAndSaveMargin(
      get(props, 'order.id', ''),
      updatedMargin || activeMargin,
      createAndSaveMarginMutation,
      articlesForDNBCalculations,
      props?.rammeavtale ?? null,
    );

    if (data) {
      setHasMarginChanged(false);
    }

    setLoading(false);

    if (typeof props.setIsSaveFromMargin === 'function') {
      props.setIsSaveFromMargin(false);
    }
    if (typeof props.setIsPcodeChangeCancelled === 'function') {
      props.setIsPcodeChangeCancelled(null);
    }
  };

  const handleReset = (): void => {
    const updateArticleFields = (article: INextArticle, props: IMarginCartProps) => {
      switch (article?.articleType) {
        case ArticleType?.CUSTOMERADVANTAGE || ArticleType?.DISCOUNT_AMOUNT:
          return {
            ...article,
            priceExclVat: props?.order?.pimSnapshot?.customerAdvantageExcludingVat || 0,
          };
        case ArticleType?.IMPORTCONTRIBUTION:
          return {
            ...article,
            priceExclVat: props?.order?.pimSnapshot?.importerSupportExcludingVat || 0,
          };
        default:
          return article;
      }
    };
    const updatedArticles = articles?.map((article) => updateArticleFields(article, props));

    let vatCalculation: ICalculatePricesBasedOnArticleListResult = null;

    vatCalculation = calculateVatForElCars(updatedArticles, props.carType);

    setActiveMargin({
      campaignDiscount: Math.abs(vatCalculation?.calculatedDiscountOnVehicle || 0),
      campaignImporter: Math.abs(vatCalculation?.calculatedImportContribution || 0),
      discountValue: 0,
      importerDiscountValue: 0,

      marginValue: vatCalculation?.marginAmount,
      marginPercentage: vatCalculation?.marginPercentage,
      campaignDiscountPercentage: vatCalculation?.customerAdvantagePercentage,
      campaignImporterPercentage: 0,
    });
    setUpdatedMargin({
      campaignDiscount: Math.abs(vatCalculation?.calculatedDiscountOnVehicle || 0),
      campaignImporter: Math.abs(vatCalculation?.calculatedImportContribution || 0),
      discountValue: 0,
      importerDiscountValue: 0,

      marginValue: vatCalculation?.marginAmount,
      marginPercentage: vatCalculation?.marginPercentage,
      campaignDiscountPercentage: vatCalculation?.customerAdvantagePercentage,
      campaignImporterPercentage: 0,
    });

    setVatPrices(mapVatPricesForNext(vatCalculation));
    setIsValidCampaignDiscount(true);
    setIsValidImporterDiscount(true);
    setHasMarginChanged(false);
  };

  return (
    <MarginAccordion>
      <Accordion labelContent={<span className="h3">Rabatt</span>}>
        <CalculateDiscount
          activeMargin={activeMargin}
          latestMargin={latestMargin}
          pimSnapshot={get(props, 'order.pimSnapshot', null)}
          setActiveMargin={(value) => {
            setActiveMargin(value);
            setUpdatedMargin(value);
          }}
          vehicleConfiguration={props.vehicleConfiguration}
          articles={articles}
          vatPrices={vatPrices}
          setVatPrices={setVatPrices}
          isValid={isValidCampaignDiscount}
          setIsValid={setIsValidCampaignDiscount}
          carType={props?.carType}
          order={props.order}
          onUpdateArticles={props.onUpdateArticles}
        />
        {isRammeavtaleEnabled && (
          <>
            <div style={{ gap: checkedAgreement ? '1.5rem' : 0 }} className="marginContainer">
              <ToggleSwitch
                label={'Rammeavtale'}
                checked={checkedAgreement}
                toggleHandler={handleAgreementSwitchToggle}
              />
              <div
                style={{ height: checkedAgreement ? 'unset' : 0, visibility: checkedAgreement ? 'visible' : 'hidden' }}
                className="marginCartBottomContainer">
                <FrameworkAgreement
                  pimSnapshot={get(props, 'order.pimSnapshot', null)}
                  activeMargin={activeMargin}
                  latestMargin={latestMargin}
                  setActiveMargin={(value) => {
                    setActiveMargin(value);
                    setUpdatedMargin(value);
                  }}
                  vatPrices={vatPrices}
                  setVatPrices={setVatPrices}
                  isValid={isValidAccessoriesDiscount}
                  setIsValid={setIsValidAccessoriesDiscount}
                  order={props?.order}
                  accessoryChanges={props.accessoryChanges}
                  articles={props.articles}
                  onUpdateArticles={props.onUpdateArticles}
                  carType={props?.carType}
                  setRammeavtale={props?.setRammeavtale}
                  rammeavtale={props?.rammeavtale}
                  vehicleConfiguration={props.vehicleConfiguration}
                />
              </div>
            </div>
          </>
        )}
        {!get(props, 'order.isAgent', null) && (
          <div className="marginContainer">
            <ToggleSwitch label={'Marginbilde'} checked={checkedIndex} toggleHandler={handleSwitchToggle} />
            {checkedIndex && (
              <div className="marginCartBottomContainer">
                <CalculateImporterDiscount
                  activeMargin={activeMargin}
                  latestMargin={latestMargin}
                  pimSnapshot={get(props, 'order.pimSnapshot', null)}
                  articles={articles}
                  setActiveMargin={(value) => {
                    setActiveMargin(value);
                    setUpdatedMargin(value);
                  }}
                  vehicleConfiguration={props.vehicleConfiguration}
                  isValid={isValidImporterDiscount}
                  setIsValid={setIsValidImporterDiscount}
                  carType={props?.carType}
                  order={props.order}
                />
                <MarginCartDetails margin={latestMargin} />
              </div>
            )}
          </div>
        )}
        <div className="btnGroup">
          <Button
            isLoading={loading}
            colorVariant={'secondary'}
            onClick={saveMargin}
            disabled={
              !hasMarginChanged ||
              (!isValidCampaignDiscount && isValidCampaignDiscount !== null) ||
              (!isValidImporterDiscount && isValidImporterDiscount !== null) ||
              props.isUnavailablePimData
            }>
            <span>Oppdater rabatt</span>
          </Button>
          <Button
            variant={'secondary'}
            colorVariant={'secondary'}
            onClick={handleReset}
            disabled={props.isUnavailablePimData}>
            <span>Nullstill</span>
          </Button>
        </div>
      </Accordion>
    </MarginAccordion>
  );
};
