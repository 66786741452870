import { IDropdownOption, formatNumber } from '@next-components/common-types';
import { createAndAddMarginToOrder, prepareArticles } from '../../../utils';
import {
  FaunaMapper,
  IAccessoryList,
  IMargin,
  IMarginInput,
  INextArticle,
  IOrder,
  IPimSnapshot,
  IRammeavtale,
  IVehicleConfiguration,
  calcMargineValueWithoutVat,
  calculateTotalCampaignDiscount,
  getLatestMargin,
} from 'next-common';
import { cloneDeep, defaultTo, isEqual, remove } from 'lodash';
import { VatCode, ArticleType } from 'sales-common';

interface IValidationInterface {
  isValid: boolean;
  errorMessage: string;
}

export interface IDiscountFieldProps {
  activeMargin: IMarginInput;
  latestMargin: IMargin;
  pimSnapshot: IPimSnapshot;
  vehicleConfiguration: IVehicleConfiguration;
  setActiveMargin(margin: IMarginInput): void;
  isValid: boolean;
  setIsValid(isValid: boolean): void;
  articles: INextArticle[];
  carType: string;
  order: IOrder;
}

export enum DropDownValues {
  KR = 'kr',
  PERCENTAGE = '%',
}

export const dropDownOptions: IDropdownOption<any>[] = Object.entries(DropDownValues).map(([key, value], index) => {
  return { label: value, value: key, isSelected: index === 0 };
});

export const formatPrice = (value: number): string => {
  return `${formatNumber(value)} kr`;
};

export const postAndSaveMargin = async (
  orderId: string,
  activeMargin: IMarginInput,
  createAndSaveMarginMutation,
  articlesForDNBCalculations,
  rammeavtale?: IRammeavtale,
): Promise<IOrder> => {
  const marginInput: IMarginInput = FaunaMapper.deleteFields(activeMargin, ['id', 'timestamp']);
  return await createAndAddMarginToOrder(
    orderId,
    marginInput,
    createAndSaveMarginMutation,
    articlesForDNBCalculations,
    rammeavtale,
  );
};

export const checkHasMarginChanged = (latestMargin: IMargin, activeMargin: IMarginInput): boolean => {
  return (
    !isEqual(latestMargin?.campaignDiscount, activeMargin?.campaignDiscount) ||
    !isEqual(latestMargin?.campaignImporter, activeMargin?.campaignImporter) ||
    !isEqual(latestMargin?.marginPercentage, activeMargin?.marginPercentage)
  );
};

export const checkHasRammeavtaleChanged = (
  latestRammeavtale: IRammeavtale,
  activeRammeavtale: IRammeavtale,
): boolean => {
  const hasAccessoryChanged = activeRammeavtale?.accessories?.find((active) => {
    const accessory = latestRammeavtale?.accessories?.find((latest) => latest?.key === active?.key);
    if (accessory?.articleType === ArticleType.DELIVERY) {
      return (accessory?.discountAmountExclVat || 0) !== (active?.discountAmountExclVat || 0);
    } else {
      return (accessory?.discountPercentage || 0) !== (active?.discountPercentage || 0);
    }
  });

  let hasChanged = false;
  if (!latestRammeavtale) {
    hasChanged = !isEqual(latestRammeavtale?.agreementNumber || null, activeRammeavtale?.agreementNumber || null);
  } else if (latestRammeavtale && !activeRammeavtale) {
    hasChanged = true;
  } else {
    hasChanged = Boolean(hasAccessoryChanged);
  }
  return hasChanged;
};

export const calculateMarginValue = (order: IOrder, activeMargin: IMarginInput, latestMargin: IMargin): number => {
  return calculateTotalCampaignDiscount(
    calcMargineValueWithoutVat(order, activeMargin) - defaultTo(latestMargin?.discountValue, 0),
    latestMargin,
    activeMargin,
  );
};

export const validateDiscount = (
  inputValue: number,
  dropDownValue: DropDownValues = DropDownValues.KR,
  upperLimitPercentage: number = 100,
): boolean => {
  if (dropDownValue === DropDownValues.PERCENTAGE) {
    return inputValue >= 0 && inputValue <= upperLimitPercentage;
  }
  if (inputValue === 0) {
    return true;
  }
  return true;
};

export const checkIfMissingDataFromPim = (vehicleConfiguration: IVehicleConfiguration): boolean => {
  return !vehicleConfiguration?.pricing?.purchasePrice || !vehicleConfiguration?.pricing?.indicativePrice;
};

export const getValidity = (
  hasChanged: boolean,
  isValid: boolean,
  vehicleConfiguration: IVehicleConfiguration,
  isCampaignDiscount = false,
): IValidationInterface | {} => {
  if (hasChanged && isValid !== null) {
      return {
          isValid,
          errorMessage: `Gyldig intervall 0 til ${isCampaignDiscount ? '30' : '100'} %`,
      }
  } else if (checkIfMissingDataFromPim(vehicleConfiguration) && isValid !== null) {
      return {
          isValid,
          errorMessage: 'Mangler data for å utføre kalkulering',
      };
  }
  return {};
};

export const articlesForDiscount = (articles: INextArticle[], auxiliaryInput: any, order: IOrder) => {
  const articleType = [
    ArticleType.CUSTOMERADVANTAGE,
    ArticleType.DISCOUNT_PERCENTAGE,
    ArticleType.IMPORTCONTRIBUTION,
    ArticleType.IMPORTCONTRIBUTION_PERCENTAGE,
  ];

  // any type is given intentionally
  const articlesCopy: any[] = cloneDeep(articles);

  remove(articlesCopy, (article) => {
    return articleType.includes(article.articleType);
  });

  articlesCopy.forEach((article) => {
    delete article['id'];
  });

  const margins = getLatestMargin(order.margins);

  return [
    ...articlesCopy,
    {
      priceExclVat: defaultTo(auxiliaryInput?.discount, margins?.campaignDiscount || 0),
      vatCode: VatCode.NOT_APPLICABLE.toString(),
      articleType: ArticleType.CUSTOMERADVANTAGE,
      dealerPriceExclVat: 0,
      discountAmountExclVat: 0,
      discountPercentage: 0,
    },
    {
      priceExclVat: defaultTo(auxiliaryInput?.discountPercentage, 0),
      vatCode: VatCode.NOT_APPLICABLE.toString(),
      articleType: ArticleType.DISCOUNT_PERCENTAGE,
      dealerPriceExclVat: 0,
      discountAmountExclVat: 0,
      discountPercentage: 0,
    },
    {
      priceExclVat: defaultTo(
        auxiliaryInput?.importerDiscount,
        margins?.campaignImporter || order?.pimSnapshot?.importerSupportExcludingVat || 0,
      ),
      vatCode: VatCode.NOT_APPLICABLE.toString(),
      articleType: ArticleType.IMPORTCONTRIBUTION,
      dealerPriceExclVat: 0,
      discountAmountExclVat: 0,
      discountPercentage: 0,
    },
    {
      priceExclVat: defaultTo(auxiliaryInput?.importerPercentage, 0),
      vatCode: VatCode.NOT_APPLICABLE.toString(),
      articleType: ArticleType.IMPORTCONTRIBUTION_PERCENTAGE,
      dealerPriceExclVat: 0,
      discountAmountExclVat: 0,
      discountPercentage: 0,
    },
  ];
};

export const getArticlesWithDiscount = (
  accessory: IAccessoryList,
  customerAdvantage?: boolean,
  discountArticles?: INextArticle[],
  existingArticles?: INextArticle[],
) => {
  let articles: INextArticle[] = [];
  if (customerAdvantage) {
    articles = discountArticles;
  } else {
    if (accessory?.articleType === ArticleType.DELIVERY) {
      articles = [
        ...prepareArticles(
          accessory?.key,
          accessory?.price,
          accessory?.vatCode,
          accessory.articleType,
          accessory.discountedPrice,
          false,
          existingArticles,
          false,
          '',
          0,
          accessory.discountAmountExclVat,
        ),
      ];
    } else {
      articles = [
        ...prepareArticles(
          accessory?.key,
          accessory?.price,
          accessory?.vatCode,
          accessory.articleType,
          accessory.discountedPrice,
          false,
          existingArticles,
          false,
          '',
          accessory.discountPercentage,
        ),
      ];
    }
  }
  return articles;
};
