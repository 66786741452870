import { calculateRetailPrice, eventEmitter, Events, getInteriorChangeInput, prepareImageObject, PreSelectedKeyEnum, priceFormatter, VehicleConfig } from '../../../../../utils';
import React, { useEffect, useMemo, useState } from 'react';
import { IModelPackages } from '../../VehiclePickerPage';
import { ICartDataProps, ICommonRulesProps } from '../ResultGrid';
import { SwatchGrid } from '../ResultGrid.styled';
import { IInterior } from 'next-common';
import { useDisableSkus, useEnableSkus, usePreSelectionEquipment, useSkusPriceUpdate } from '../../../../../common';
import { VehicleSelectableDescription } from './VehicleSelectableDescription';
import { get } from 'lodash';
import { CardType } from '@next-components/common-types';
import { Card } from '@next-components/card';
import { MessagebarSeverityLevel } from '@next-components/messagebar';
export interface IVehicleInterior extends ICommonRulesProps {
  data: IModelPackages;
  selectedInteriorId: string;
  disabled: boolean;
  onInteriorChange: (data: ICartDataProps) => void;
  updatePrices: () => void;
}

export const VehicleInterior = (props: IVehicleInterior) => {
  const { data, selectedInteriorId, onInteriorChange, disabled } = props;
  const priceUpdate = useSkusPriceUpdate();
  const { isDisableSKUonChange, isDisableSKUonLoad, disableSku, onloadDisableSku } = useDisableSkus();
  const { isEnableSKUonChange, enableSku } = useEnableSkus();
  const [isPrepared, setIsPrepared] = useState<boolean>(false);
  const [active, setActive] = useState<string>('');
  const [activeDesc, setActiveDesc] = useState<IInterior>(null);
  const { isEnablePreSelection, isSKUPreselected, updateKeyStatus, isPreSelectionDone } = usePreSelectionEquipment();

  useEffect(() => {
    eventEmitter.subscribe(Events.SKU_RULES_PREPARED, setIsPrepared);
    return () => {
      eventEmitter.unsubscribe(Events.SKU_RULES_PREPARED);
    };
  }, []);

  useEffect(() => {
    //Update prices in cart & VAT Calculation
    if (selectedInteriorId) {
      props.updatePrices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInteriorId, priceUpdate]);

  return (
    <>
      {data?.interiors?.length <= 0 ? (
        <p> Ingen interiør tilgjengelig </p>
      ) : (
        <SwatchGrid className="vehicleInterior">
          {data?.interiors?.map((interior, index) => {
            return (
              <InteriorItem
                key={`${interior?.interiorCode}-${index}`}
                interior={interior}
                selectedInteriorId={selectedInteriorId}
                onInteriorChange={onInteriorChange}
                disabled={disabled}
                isPrepared={isPrepared}
                priceUpdate={priceUpdate}
                disableSku={disableSku}
                enableSku={enableSku}
                onloadDisableSku={onloadDisableSku}
                active={active}
                setActive={setActive}
                setActiveDesc={setActiveDesc}
                isEnablePreSelection={isEnablePreSelection}
                isSKUPreselected={isSKUPreselected}
                updateKeyStatus={updateKeyStatus}
                isPreSelectionDone={isPreSelectionDone}
                isDisableSKUonChange={isDisableSKUonChange}
                isDisableSKUonLoad={isDisableSKUonLoad}
                isEnableSKUonChange={isEnableSKUonChange}
                setCurrentlySelectedSku={props.setCurrentlySelectedSku}
                setCurrentSelectionDetails={props.setCurrentSelectionDetails}
                activeDesc={activeDesc}
              />
            );
          })}
        </SwatchGrid>
      )}
    </>
  );
};

interface IIteriorItem extends ICommonRulesProps {
  interior: IInterior;
  selectedInteriorId: string;
  onInteriorChange: (data: ICartDataProps) => void;
  disabled: boolean;
  isPrepared: boolean;
  priceUpdate: Map<string, number>;
  disableSku: Map<string, number>;
  enableSku: Map<string, number>;
  onloadDisableSku: Map<string, number>;
  active: string;
  isEnablePreSelection?: boolean;
  activeDesc?: IInterior;
  isSKUPreselected: (sku: string, key: string) => boolean; 
  updateKeyStatus: (key: string) => void;
  isPreSelectionDone: (key: string) => boolean;
  setActive: (data: string) => void;
  setActiveDesc: (data: IInterior) => void;
  isDisableSKUonChange: (sku: string) => boolean;
  isDisableSKUonLoad: (sku: string) => boolean;
  isEnableSKUonChange: (sku: string) => boolean;
}

const InteriorItem = ({
  interior,
  selectedInteriorId,
  disabled,
  onInteriorChange,
  isPrepared,
  priceUpdate,
  disableSku,
  enableSku,
  onloadDisableSku,
  active,
  activeDesc,
  setActive,
  setActiveDesc,
  isEnablePreSelection,
  isSKUPreselected,
  updateKeyStatus,
  isPreSelectionDone,
  isDisableSKUonChange,
  isEnableSKUonChange,
  isDisableSKUonLoad,
  setCurrentlySelectedSku,
  setCurrentSelectionDetails,
}: IIteriorItem) => {

  const isSelected = selectedInteriorId ? selectedInteriorId === interior?.interiorCode : false;
  const isDataAvailable = (interior: IInterior) => {
    return get(interior, 'description');
  };

  const finalPrice = useMemo(() => {
    return priceUpdate?.has(interior?.interiorCode)
      ? Number(priceUpdate?.get(interior?.interiorCode))
      : interior?.additionalPriceExcludingVat;
  }, [interior, priceUpdate]);

  const onChangeInterior = (interior: IInterior) => {
    if (typeof setCurrentlySelectedSku === "function" && typeof setCurrentSelectionDetails === "function") {
      if (selectedInteriorId !== interior?.interiorCode) {
        setCurrentlySelectedSku(interior?.interiorCode);
      } else {
        setCurrentSelectionDetails({
          deSelectedSku: interior?.interiorCode,
        });
      }
    }
    return onInteriorChange(
      getInteriorChangeInput(interior, priceUpdate, selectedInteriorId)
    )
  };

  const isDisable = useMemo(() => {
    const isDisable = isDisableSKUonChange(interior?.interiorCode)
      ? true
      : isEnableSKUonChange(interior?.interiorCode)
        ? false
        : isDisableSKUonLoad(interior?.interiorCode);

    return isDisable;
  }, [disableSku, enableSku, onloadDisableSku, interior, isPrepared]);

  const updatePreselection = (interior: IInterior) => {
    if (isSKUPreselected(interior?.interiorCode, PreSelectedKeyEnum.INTERIOR) && isEnablePreSelection && !isPreSelectionDone(PreSelectedKeyEnum.INTERIOR)) {
      onChangeInterior(interior)
      updateKeyStatus(PreSelectedKeyEnum.INTERIOR)
    }
    return false;
  }

  // incase of re-selection based on previous disabled interior
  useEffect(() => {
    if (!isDisable && isPrepared && selectedInteriorId === interior?.interiorCode) {
      onInteriorChange({
        code: selectedInteriorId,
        name: interior?.name,
        price: finalPrice,
        vatCode: interior?.vatCode,
        isRecommended: interior?.isRecommended,
        isOptimisticUpdate: true,
        configType: VehicleConfig.INTERIOR,
        dealerPriceExclVat: calculateRetailPrice(finalPrice, interior?.marginPercentage),
      });
    }
  }, [isDisable, selectedInteriorId, finalPrice]);

  let subTitleText = 'Inkludert';

  if (!interior?.isRecommended) {
    subTitleText = `${finalPrice >= 0 ? '+' : ''} ${priceFormatter(finalPrice)}`;
  }

  const setCurrentData = (interior: IInterior) => {
    setActiveDesc(interior);
    setActive(interior?.id);
  };

  return (
    <Card
      key={interior?.id}
      id={interior?.id}
      title={interior?.type}
      bosSwatch={prepareImageObject(interior?.images?.[0])}
      customPriceTag={subTitleText}
      type={CardType.BosCard}
      selected={isSelected}
      isDisabled={disabled || updatePreselection(interior)}
      alertMessage={{
        typeOfAlert: MessagebarSeverityLevel.WARNING,
        showIcon: false,
      }}
      displayTag
      disableTag={!isDataAvailable(interior)}
      tagValue={interior?.interiorCode}
      updateSelected={() => onChangeInterior(interior)}
      onTagClick={() => setCurrentData(interior)}
      tagModal={{
        isOpen: active === interior?.id,
        modalChildComponent: (
          <VehicleSelectableDescription
            data={activeDesc}
            active={active === interior?.id}
            onClose={() => setActive('')}
          />
        ),
      }}
    />
  );
};
