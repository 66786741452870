import { ICartSummaryData } from '@next-components/cart';
import { compact, defaultTo, get, isEqual, sortBy } from 'lodash';
import { AccessoryType, campaignDiscount, getLatestMargin, getSeperatedIdFromAccessoryCustomId, IAccessory, IOrder, IOrderInput, IVehicleConfiguration, IVehicleModel, MAKE, NextErrorCode, UsageCodes } from 'next-common';
import { PropertiesTableValue, VatCode } from 'sales-common';
import { getUpdatedConjunctionPrices } from 'sales-crystallize-common';
import { getOptionInputsForPickerPage, VehicleConfig } from '.';
import { IModelPackages, IOverriddenTaxAndUsageCode, IPageProps } from '../../components/sales/sales-vehicle-picker-page';
import { isDemoOrder, isStockOrder } from '../../common';
import { OperationVariables } from '@apollo/client';
import { IStepMenuData, VarebilWizardSteps } from '../../components/sales/sales-wizard/sales-wizard-menu';

export interface IPriceChangedFields {
  name: string;
  currentPrice: number;
  newPrice: number;
  isChanged?: boolean;
}

export const isOfferChange = (existingValue: IPageProps, selectedValues) => {
  const {
    selectedModelId,
    selectedVariantId,
    selectedColorId,
    selectedInteriorId,
    selectedRims,
    selectedEquipmentIds,
    selectedListverk,
    selectedTaklakk,
  } = selectedValues;

  let isOffer = true;

  const existingEquSKUs = existingValue?.selectableEquipments?.map((ele) => ele?.sku);
  const selectedEquSKUs = selectedEquipmentIds?.map((ele) => ele?.sku);
  const isEqupChange = !isEqual(sortBy(existingEquSKUs), sortBy(selectedEquSKUs));
  if (
    existingValue?.vehicleModelId === selectedModelId &&
    existingValue?.vehicleVariantId === selectedVariantId &&
    existingValue?.selectedColorId === selectedColorId &&
    existingValue?.selectedInteriorId === selectedInteriorId &&
    existingValue?.rimsEquipments?.sku === selectedRims?.sku &&
    existingValue?.listverkEquipments?.sku === selectedListverk?.sku &&
    existingValue?.taklakkEquipments?.sku === selectedTaklakk?.sku &&
    !isEqupChange
  ) {
    isOffer = false;
  }
  return isOffer;
};

export const isChangeVehicleModelOnly = (selectedValues) => {
  const { selectedColorId, selectedInteriorId, selectedRims, selectedListverk, selectedTaklakk, selectedEquipmentIds } = selectedValues;

  if (
    !selectedColorId &&
    !selectedInteriorId &&
    !selectedRims &&
    !selectedListverk &&
    !selectedTaklakk &&
    selectedEquipmentIds?.length <= 0
  )
    return true;
  return false;
};

export const isOfferValidationPassed = (selectedData) => {
  const { selectedColorId, selectedInteriorId, selectedRims, selectedListverk, selectedTaklakk, selectedEquipmentIds } = selectedData;

  if (
    (selectedColorId || selectedInteriorId || selectedRims || selectedListverk || selectedTaklakk || selectedEquipmentIds?.length) &&
    (!selectedColorId || !selectedInteriorId)
  )
    return false;

  return true;
};

export const prepareOptions = (
  order: IOrder,
  vehicleConfig: IVehicleConfiguration,
  colorCode: string,
  interiorCode: string,
) => {
  const colorName = vehicleConfig?.varnishes?.find((varnish) => varnish?.varnishCode === colorCode)?.name ?? null;

  const metaData = {
    colorId: colorCode,
    colorDesc: colorName,
    interiorCode,
  };
  const options = compact(getOptionInputsForPickerPage(order, metaData, vehicleConfig, { interiorCode }));

  return options;
};

export const updateDataOnChangedVehicle = async (
  variables,
  updateOrderWithVehicle,
  updateOrderWithVehicleEquipment,
  equipments = [],
  chosenOffer,
  pageProps,
) => {
  const isOrderStockType = isStockOrder(pageProps?.order?.lead?.orderType);
  const isOrderDemoType = isDemoOrder(pageProps?.order?.lead?.orderType);

  if (equipments?.length) {
    const { data } = await updateOrderWithVehicleEquipment({
      variables: {
        ...variables,
        equipments,
      },
    });
    const calculationId = data?.createExistingOrderWithVehicleAndVehicleEquipment?.offers?.[0]?.id;
    if (calculationId && (isOrderStockType || isOrderDemoType)) {
      await chosenOffer(calculationId);
    }
    if(data?.createExistingOrderWithVehicleAndVehicleEquipment?.errorCode === NextErrorCode.MB_CONFIG_API_FAILED) return {mbApiStatus : false, isSuccess: false,  httpCode: data?.createExistingOrderWithVehicleAndVehicleEquipment?.httpCode}
    if (data?.createExistingOrderWithVehicleAndVehicleEquipment?.id) {
      return {isSuccess: true, mbApiStatus : true, data: data?.createExistingOrderWithVehicleAndVehicleEquipment };
    }
    return {isSuccess: false, mbApiStatus : true, httpCode:data?.createExistingOrderWithVehicleAndVehicleEquipment?.httpCode, data: data?.createExistingOrderWithVehicleAndVehicleEquipment };
  } else {
    const { data } = await updateOrderWithVehicle({ variables });
    const calculationId = data?.createExistingOrderWithVehicle?.offers?.[0]?.id;
    if (calculationId && (isOrderStockType || isOrderDemoType)) {
      await chosenOffer(calculationId);
    }
    if(data?.createExistingOrderWithVehicle?.errorCode === NextErrorCode.MB_CONFIG_API_FAILED) return {mbApiStatus : false, isSuccess: false,  httpCode:data?.createExistingOrderWithVehicle?.httpCode}
    if (data?.createExistingOrderWithVehicle?.id) {
      return {isSuccess: true, mbApiStatus : true, data: data?.createExistingOrderWithVehicle };
    }
    return {isSuccess: false, mbApiStatus : true, httpCode:data?.createExistingOrderWithVehicle?.httpCode, data: data?.createExistingOrderWithVehicle };
  }
};

export const updateDataForExistingVehicle = async (
  variables,
  equipments = [],
  addVehiclesToOrder,
  addVehiclesToOrderrWithEquipment,
) => {
  if (equipments?.length) {
    const { data } = await addVehiclesToOrderrWithEquipment({
      variables: {
        ...variables,
        equipments,
      },
    });
    if (data?.addVehiclesAndVehicleEquipmentToOrder?.id) return true;
    return false;
  } else {
    const { data } = await addVehiclesToOrder({ variables });
    if (data?.addVehiclesToOrder?.id) return true;
    return false;
  }
};

export const updateVehicleModel = async (variables, updateOrderMutation) => {
  const { data } = await updateOrderMutation({ variables });
  return data;
};

const getUpdatedPrice = (order: IOrder, selectedVariant: IModelPackages) => {
  const selectableSkuPayload: string[] = [];
  if (order?.options?.length) {
    selectableSkuPayload.push(order?.options?.[0]?.colorId, order?.options?.[0]?.interiorId)
  }
  if (order?.equipments?.length) {
    selectableSkuPayload.push(...order.equipments.map(equipment => equipment.sku));
  }
  const rulesPayload: string = `${selectableSkuPayload?.join(';') || ''};`;
  return getUpdatedConjunctionPrices(rulesPayload, selectedVariant?.mappedEquipment);
}

const getPrice = (item: ICartSummaryData, skuPrice: ((PropertiesTableValue & { sku: string }) | any)[], newPrice: number) => {
  const selectablePrice = skuPrice?.find((element) => element?.sku === item?.id);
  return selectablePrice ? Number(selectablePrice?.value || 0) : newPrice;
}

export const getPriceChangedFields = (order: IOrder, accessories: IAccessory[], item: ICartSummaryData, priceChangedFields: IPriceChangedFields[], model: IVehicleModel, skuPrice) => {
  if (item?.key?.startsWith(`${VehicleConfig.SELECTABLE_EQUIPMENT}`)) {
    const equipment = model?.selectableEquipment?.find((equipment) => item?.id === equipment?.sku);
    const newPrice = getPrice(item, skuPrice, defaultTo(equipment?.priceExcludingVat, 0));
    priceChangedFields.push({
      name: get(item, 'name', ''),
      currentPrice: get(item, 'price', 0),
      newPrice,
      isChanged: item?.price !== newPrice,
    });
  }
  if (item?.key?.startsWith(`${VehicleConfig.ACCESSORY}`)) {
    const orderAccessory = order?.accessories?.find(
      (accessory) =>
        accessory?.customId === item?.id &&
        accessory?.accessoryType.toString() === AccessoryType[AccessoryType.ACCESSORY],
    );
    if (orderAccessory) {
      const pimAccessoryId = getSeperatedIdFromAccessoryCustomId(item?.id)?.[1];
      const accessory = accessories?.find((accessory) => accessory?.pimSku === pimAccessoryId);
      priceChangedFields.push({
        name: get(item, 'name', ''),
        currentPrice: get(item, 'price', 0),
        newPrice: get(accessory, 'priceExcludingVat', 0),
        isChanged: item?.price !== accessory?.priceExcludingVat,
      });
    }
  }
  return priceChangedFields;
}

export const isPimPriceChanged = (cartData: ICartSummaryData[], order: IOrder, vehicle: IVehicleConfiguration, selectedVariant: IModelPackages) => {
  let priceChangedFields: IPriceChangedFields[] = [];
  if (!cartData?.length || !order || !vehicle || selectedVariant?.sku !== vehicle?.sku) return priceChangedFields;
  
  const { tires, accessories, varnishes, interiors, pricing, model } = vehicle;
  const { pimSnapshot } = order;
  const skuPrice = getUpdatedPrice(order, selectedVariant);

  cartData?.forEach((item) => {
    switch (item?.key) {
      case VehicleConfig.MODEL:
        priceChangedFields.push({
          name: get(item, 'name', ''),
          currentPrice: get(item, 'price', 0),
          newPrice: get(pricing, 'carPriceExclVatAndFees', 0),
          isChanged: item?.price !== pricing?.carPriceExclVatAndFees,
        });
        break;

      case VehicleConfig.VARIANT:
        priceChangedFields.push({
          name: get(item, 'name', ''),
          currentPrice: get(item, 'price', 0),
          newPrice: get(pricing, 'packagePriceExclVat', 0),
          isChanged: item?.price !== pricing?.packagePriceExclVat,
        });
        break;

      case VehicleConfig.DELIVERY_CHARGE:
        priceChangedFields.push({
          name: get(item, 'name', ''),
          currentPrice: get(item, 'price', 0),
          newPrice: get(pricing, 'extraDeliveryCharge', 0),
          isChanged: item?.price !== pricing?.extraDeliveryCharge,
        });
        break;

      case VehicleConfig.COLOR:
        const varnish = varnishes?.find((varnish) => item?.id === varnish?.varnishCode);
        const newVarnishPrice = getPrice(item, skuPrice, defaultTo(varnish?.additionalPriceExcludingVat, 0));
        priceChangedFields.push({
          name: get(item, 'name', ''),
          currentPrice: get(item, 'price', 0),
          newPrice: newVarnishPrice,
          isChanged: item?.price !== newVarnishPrice,
        });
        break;

      case VehicleConfig.INTERIOR:
        const interior = interiors?.find((interior) => item?.id === interior?.interiorCode);
        const newInteriorPrice = getPrice(item, skuPrice, interior?.additionalPriceExcludingVat);
        priceChangedFields.push({
          name: get(item, 'name', ''),
          currentPrice: get(item, 'price', 0),
          newPrice: newInteriorPrice,
          isChanged: item?.price !== newInteriorPrice,
        });
        break;

      case VehicleConfig.TYRE:
        const tyrePimSku = getSeperatedIdFromAccessoryCustomId(item?.id)?.[1];
        const tyre = tires?.find((tire) => tyrePimSku === tire?.pimSku);
        priceChangedFields.push({
          name: get(item, 'name', ''),
          currentPrice: get(item, 'price', 0),
          newPrice: get(tyre, 'additionalPriceExcludingVat', 0),
          isChanged: item?.price !== tyre?.additionalPriceExcludingVat,
        });
        break;

      case VehicleConfig.DISCOUNT:
        const isCampaignDiscountAdded = order?.margins?.find(discount => discount?.discountValue !== 0);
        const isImporterDiscountAdded = order?.margins?.find(discount => discount?.importerDiscountValue !== 0);
        const margin = getLatestMargin(order?.margins);
        const discount = campaignDiscount(margin);
        if (isCampaignDiscountAdded) {
          priceChangedFields.push({
            name: 'Margin/Rabatt',
            currentPrice: defaultTo(Math.abs(item?.price), 0),
            newPrice: discount,
            isChanged: Math.abs(item?.price) !== discount,
          });
        }
        if (isImporterDiscountAdded) {
          priceChangedFields.push({
            name: 'Margin/Rabatt',
            currentPrice: get(pimSnapshot, 'purchasePrice', 0),
            newPrice: get(pricing, 'purchasePrice', 0),
            isChanged: pimSnapshot?.purchasePrice !== pricing?.purchasePrice,
          });
          priceChangedFields.push({
            name: 'Margin/Rabatt',
            currentPrice: get(pimSnapshot, 'importerSupportExcludingVat', 0),
            newPrice: get(pricing, 'importerSupportExcludingVat', 0),
            isChanged: pimSnapshot?.importerSupportExcludingVat !== pricing?.importerSupportExcludingVat,
          });
        }
        break;

      default:
        break;
    }
    priceChangedFields = getPriceChangedFields(order, accessories, item, priceChangedFields, model, skuPrice);
  });

  return priceChangedFields.filter((price) => price?.isChanged);
};

export const isValidFactoryCodes = (make: MAKE, order: IOrder): boolean => {
  if (make === MAKE.Mercedes && order?.vehicles?.[0]?.orderFromFactoryDetails) {
    if (
      !order?.pimSnapshot?.modelFactoryCodeId ||
      !order?.options?.[0]?.colorFactoryCodeId ||
      !order?.options?.[0]?.interiorFactoryCodeId
    ) {
      return false;
    }

    let isValidEquip = true;
    order?.equipments?.forEach((equipment) => {
      if (!equipment?.factoryCodeId) {
        isValidEquip = false;
      }
    });
    return isValidEquip;
  }
  return true;
};

 /**
   * This Mutation is used to update fields related to brukskode and overriddenVehicleRoadTax and Its Corresponding flag.
   * Based on brukskode vehicle vatCode is also updated in Articles.
   * @param variables Mutation Input Variables
   */
 export const updateOrderVariablesForUsageCodeAndTaxes = (
   variables: OperationVariables,
   overriddenTaxAndUsageCodeDetails: IOverriddenTaxAndUsageCode,
 ): OperationVariables => {
   try {
     if (!variables?.input) {
       variables = { ...variables, input: {} as IOrderInput };
     }
     if (overriddenTaxAndUsageCodeDetails?.usageCode) {
       variables.input.pimSnapshot = {
         vehicleUsageCode: overriddenTaxAndUsageCodeDetails?.usageCode,
       };
       if (variables?.nextArticles?.articles?.length > 0) {
         const scrapDepositArticle = variables?.nextArticles?.articles?.find(
           (ar) => ar?.key === VehicleConfig.SCRAPDEPOSIT,
         );
         const finalArticles = variables?.nextArticles?.articles?.map((article) => {
           if (article?.key === VehicleConfig.MODEL) {
             return {
               ...article,
               vatCode:
                 overriddenTaxAndUsageCodeDetails?.usageCode === UsageCodes.EL
                   ? VatCode.DEDUCTABLE
                   : VatCode.NON_DEDUCTABLE,
             };
           } else if (article?.key === VehicleConfig.TAREWEIGHT) {
             if (
               overriddenTaxAndUsageCodeDetails?.overriddenVehicleRoadTax &&
               overriddenTaxAndUsageCodeDetails?.overriddenVehicleRoadTax >= 0
             ) {
               return {
                 ...article,
                 priceExclVat:
                   overriddenTaxAndUsageCodeDetails?.overriddenVehicleRoadTax - scrapDepositArticle?.priceExclVat,
               };
             } else {
               return article;
             }
           } else {
             return article;
           }
         });
         variables.nextArticles.articles = finalArticles;
       }
     }

     if (!overriddenTaxAndUsageCodeDetails?.doneReset && overriddenTaxAndUsageCodeDetails?.chargesAreOverridden) {
       variables.input.chargesAreOverridden = overriddenTaxAndUsageCodeDetails?.chargesAreOverridden;
       variables.input.vatCalculation = {
         overriddenVehicleRoadTax: overriddenTaxAndUsageCodeDetails?.overriddenVehicleRoadTax,
       };
       variables.nextArticles.manufactureComponentId = null;
     } else if (overriddenTaxAndUsageCodeDetails?.doneReset) {
       variables.input.chargesAreOverridden = false;
       variables.input.vatCalculation = { overriddenVehicleRoadTax: null };
     }
     if(variables?.nextArticles?.articles?.length > 0) {
      variables.nextArticles.articles = variables?.nextArticles?.articles?.filter(Boolean);
     }
     return variables;
   } catch (err) {
     // tslint:disable-next-line:no-console
     console.log('error in updateOrderVariablesForUsageCodeAndTaxes------->', err);
     return variables;
   }
 };


export const isValidOverriddenCharges = (overriddenTaxAndUsageCodeDetails: IOverriddenTaxAndUsageCode) => {
  return overriddenTaxAndUsageCodeDetails?.chargesAreOverridden && overriddenTaxAndUsageCodeDetails?.overriddenVehicleRoadTax &&
  overriddenTaxAndUsageCodeDetails?.overriddenVehicleRoadTax >= 0;
}
export const isSigningPage = (allStepData: IStepMenuData[]): boolean => {
  const activeRoute = allStepData?.find((route) => route?.routeIsActive)?.routeName;
  return activeRoute === VarebilWizardSteps[VarebilWizardSteps.Signing]
}
