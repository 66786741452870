import * as React from 'react';
import { CardType, formatNumber } from '@next-components/common-types';
import { CardContent } from './PimTires.styled';
import { StyledCard } from '../../sales/sales-vehicle-accessories-page/VehicleAccessoriesPage.styled';
import { Card, ITagModal } from '@next-components/card';

export interface IPimAccessoryProps {
  key?: string;
  id?: string;
  priceIncludingVat?: number;
  priceExcludingVat?: number;
  title?: string;
  images?: object;
  isRecommended?: boolean;
  isSelected?: boolean;
  packageId?: string;
  disableTag?: boolean;
  tagModal?: ITagModal;
  handleTireSet?(id: string): void;
  onTagClick?: () => void;
}

const formatPrice = (value: number): string => {
  return `${formatNumber(value)} kr`;
};

export const PimTires = (props: IPimAccessoryProps) => {
  const formattedPriceIncludingVat = formatPrice(props.priceIncludingVat ?? 0);
  const formattedPriceExcludingVat = formatPrice(props.priceExcludingVat ?? 0);
  const tireDescriptionData = {
    description: [
      { title: 'Pris inkl. mva', value: formattedPriceIncludingVat },
      { title: 'Pris eks. mva', value: formattedPriceExcludingVat },
    ],
  };

  return (
    <StyledCard>
      <CardContent>
        <Card
          key={props?.key}
          id={props?.id}
          title={props?.title}
          customPriceTag={props?.isRecommended && 'Inkludert'}
          type={CardType.BosCard}
          updateSelected={() => props.handleTireSet(props.id)}
          selected={props?.isSelected}
          bosSwatch={{ ...(props.images ?? {}) }}
          bosDescription={tireDescriptionData?.description}
          displayTag
          tagValue={props?.packageId}
          onTagClick={() => props?.onTagClick()}
          disableTag={props?.disableTag || false}
          tagModal={props?.tagModal}
        />
      </CardContent>
    </StyledCard>
  );
};
