import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { IConfiguration, IPimVehicleModel, MAKE, OrderSource, isStockSaleOrderToBeProcessed } from 'next-common';
import { useEffect } from 'react';
import { queryVehiclesModels } from '../../graphql';
import { validateModelSeriesName } from '../../utils';
import { ERROR_POLICY } from '../constants';
import { isEmpty } from 'lodash';

export interface IVehicleModelsProps {
  make: MAKE;
  modelSeries: string;
  source: OrderSource;
  serialNo: string;
  configuration: IConfiguration;
  setIsLoading: (loadingState: boolean) => void;
}

export interface IVehicleModels {
  results: IPimVehicleModel[];
}
export interface IVehicleModelsQuery {
  vehicleModels: IVehicleModels;
  isVehicleListDataLoaded: boolean;
}

const isValidCall = (props: IVehicleModelsProps): boolean => {
  if (
    props.make &&
    props.modelSeries &&
    props.source &&
    !isStockSaleOrderToBeProcessed(props.configuration || null, props?.source, props?.serialNo, props?.make)
  ) {
    return true;
  }
  return false;
};

export const useVehicleModelsQuery = (props: IVehicleModelsProps) => {
  useEffect(() => {
    if (isValidCall(props)) {
      props.setIsLoading(true);
      vehicleModels()
        .then((response) => {
          props?.setIsLoading(false);
          return response;
        })
        .catch((error) => {
          props?.setIsLoading(false);
          console.error(error);
        });
    } else {
      props?.setIsLoading(false);
    }
  }, [props.make, props.modelSeries, props.source, props.configuration, props.serialNo]);

  const input = {
    make: props.make,
    modelSeries:
      props.make === MAKE.Smart
        ? validateModelSeriesName(props.modelSeries?.toLowerCase())
        : props.modelSeries?.toLowerCase(),
  };

  const [vehicleModels, { loading, data, error }] = useLazyQuery(queryVehiclesModels, {
    variables: {
      input,
    },
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'no-cache',
  } as LazyQueryHookOptions);
  return { data: data?.getVehicleModels, loading, error };
};

export const useVehicleModels = (props: IVehicleModelsProps): IVehicleModelsQuery => {
  const { data } = useVehicleModelsQuery(props);
  return { vehicleModels: data, isVehicleListDataLoaded: isValidCall(props) ? isEmpty(data) : false };
};
