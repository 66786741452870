import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { OrderAccordion } from '../OrderAccordion/OrderAccordion';
import { DynamicList, IDynamicListProps, IDynamicListConfig } from '@next-components/dynamic-list';
import {
  FinancingType,
  getCalculationByType,
  ICalculation,
  INextArticle,
  SantanderProductType,
} from 'next-common';
import { FinancingDialog, IFinancingDialogProps } from '../FinancingDialog';
import { TabId, Tabs } from '@next-components/tabs';
import { CustomAlertPopup } from '../../../common-components/custom-DeletePopup';
import { useLeasingCompanies } from '../../../../common/hooks';
import { defaultTo } from 'lodash';
import { loanDurationFormatter, rangeFormatter } from '../../sales-finance-page/FinanceEdit/FinanceEdit.formatters';
import { monthlyPriceFormatter } from '../../../../utils';

interface IFinancingItem {
  disabled: boolean;
  downPayment: number;
  durationMonths: number;
  id: string;
  picker: ReactElement;
  range: number;
  serviceContractDrivingDistance: number;
  serviceContractDuration: number;
  serviceContractMonthlyPrice: number;
  title: string;
  totalCost: number;
  totalMonthlyCost: number;
  totalPrice: number;
  monthlyCostInclVatAndFee: number;
}
interface FinanceAccordionProps extends IDynamicListProps {
  data: IFinancingItem[];
  sort?: any;
  selectedRows: any;
  label: string;
  config: IDynamicListConfig;
  stopSantandarService: string[];
  santanderProductType?: string;
  financingButton?: ReactElement;
  isNoTires?: boolean;
  className: string;
  financeDialogProps?: IFinancingDialogProps;
  addNew: boolean;
  isUnavailablePimData?: boolean;
  articles?: INextArticle[];
  isStockSaleOrder?: boolean;
  financingType?: FinancingType;
  removeAlternativeLeasing?(id: string): void;
  setAddNew?(addNew?: boolean): void;
  handleAddCalculation?(financingType: FinancingType, input?): void;
  handleSetSelectedLeasingTab?(selectedLeasingTab: FinancingType): void;
}
enum LeasingTypeEnum {
  BOS_LEASING = 'Bertel O. Steen Finans',
  ALTERNATIVE_LEASING = 'Alternativt leasingselskap',
}

export const FinanceAccordion = (props: FinanceAccordionProps) => {
  const [leasingInput, setLeasingInput] = useState(null);
  const [isError, setIsError] = useState(false);
  const [userClosedAccordion, setUserClosedAccordion] = useState<boolean>(false);
  const [currentActiveTab, setCurrentActiveTab] = useState<TabId>(
    props?.financeDialogProps?.order?.alternativeLeasing?.organizationNo
      ? LeasingTypeEnum.ALTERNATIVE_LEASING
      : LeasingTypeEnum.BOS_LEASING,
  );
  const [deleteLeasingAlertModal, setDeleteLeasingAlertModal] = useState<boolean>(false);
  const { leasingCompanies, loading, error } = useLeasingCompanies();
  const children = props.financingButton;
  const order = defaultTo(props?.financeDialogProps?.order, null);
  const alternativeLeasing = defaultTo(order?.alternativeLeasing, null);
  const selectedFinancingType = props?.financeDialogProps?.financingType;
  
  const getCalculation = (id: string): ICalculation =>
    props.financeDialogProps?.order?.offers.find((offer) => offer?.id === id);

  useEffect(() => {
    setIsError(!!error);
  }, [error]);

  useEffect(() => {
    if((selectedFinancingType === FinancingType.LOAN || selectedFinancingType === FinancingType.CASH) && !alternativeLeasing) {
      setCurrentActiveTab(LeasingTypeEnum.BOS_LEASING);
    }
  },[selectedFinancingType, alternativeLeasing])

  useEffect(() => {
    if (currentActiveTab === LeasingTypeEnum.ALTERNATIVE_LEASING && props?.handleSetSelectedLeasingTab) {
      props.handleSetSelectedLeasingTab(FinancingType.ALTERNATIVELEASING);
    } else if(currentActiveTab === LeasingTypeEnum.BOS_LEASING && props?.handleSetSelectedLeasingTab) {
      props.handleSetSelectedLeasingTab(FinancingType.LEASING);
    }
  }, [currentActiveTab, props]);

  const getFinanceDialog = (rowId?: string) => {
    const currentCalculation = rowId && getCalculation(rowId);

    return (
      <div className={children?.props?.type === FinancingType.CASH ? 'newServiceContractForm' : ''}>
        <FinancingDialog
          articles={props.articles}
          isStockSaleOrder={props.isStockSaleOrder}
          loading={props.loading}
          offerId={rowId}
          calculation={currentCalculation}
          serviceContractDb={currentCalculation?.serviceContract || null}
          serviceContract={props.financeDialogProps?.serviceContract}
          customerType={props.financeDialogProps?.customerType}
          isModal={false}
          orderId={props.financeDialogProps?.order?.id}
          vehicleConfig={props.financeDialogProps?.vehicleConfig}
          onUpdateCalculation={props.financeDialogProps?.onUpdateCalculation}
          onCalculationUpdated={props.financeDialogProps?.onCalculationUpdated}
          onServiceContractUpdated={props.financeDialogProps?.onServiceContractUpdated}
          financingType={children?.props?.type ?? FinancingType.CASH}
          success={props.financeDialogProps?.success}
          order={props.financeDialogProps?.order}
          addNew={props.financeDialogProps?.addNew}
          setUpdatedOrder={props.financeDialogProps?.setUpdatedOrder}
          setAddNew={props.financeDialogProps?.setAddNew}
          onAddCalculation={props.financeDialogProps?.onAddCalculation}
          isSigningStep={props.financeDialogProps?.isSigningStep}
          isDeleted={props.financeDialogProps?.isDeleted}
          configuration={props.financeDialogProps?.configuration}
          onDeleteCalculation={props.financeDialogProps?.onDeleteCalculation}
          isUnavailablePimData={props.isUnavailablePimData}
          onAlertModalToggle={handleToggleAlertModal}
          setLeasingInput={setLeasingInput}
          leasingCompanies={leasingCompanies}
          removeAlternativeLeasing={removeAlternativeLeasing}
        />
      </div>
    );
  };

  const getLatestOffer = () => {
    const filteredOffers = order?.offers?.filter(offer => offer?.financingType === selectedFinancingType);
    
    if (filteredOffers && filteredOffers.length > 0) {
        const latestOffer = filteredOffers.at(-1);
        return latestOffer.id;
    }
    return null;
  };

  const renderDynamicListSection = () => (
    <div className={`calculation-type ${props.className} ${props?.className === 'cash' ? 'singleRounded' : ''}`}>
      <DynamicList
        data={props.data?.map((element) => ({
          ...element,
          accordionChild: getFinanceDialog(element?.id),
          isAccordionOpen: !userClosedAccordion && (
            (props.addNew === false && element?.id === getLatestOffer()) ||
            props.selectedRows[0]?.id === element?.id
          ),
          userClosedAccordion: (param) => {
            props.selectedRows[0]?.id === param.id && setUserClosedAccordion(true);
          },
        }))}
        sort={props.sort}
        isNewDynamicList={true}
        config={props.config}
        selectedRows={props.selectedRows}
        selectedRowsKey="id"
        isFinancePage={true}
        onElementClick={props.onElementClick}
      />
      {props.addNew ? getFinanceDialog() : null}
      {!props.stopSantandarService?.includes(props.santanderProductType) && !props.addNew && !props.loading && children}
      {props.stopSantandarService?.includes(props.santanderProductType) && (
        <div className="error-santandar">{'(Foreløpig ikke tilgjengelig)'}</div>
      )}
      {props.santanderProductType === SantanderProductType.LEASING &&
        !props.stopSantandarService?.includes(props.santanderProductType) &&
        props.isNoTires && (
          <div className="error-santandar">
            {'Leasingavtale kan ikke kalkuleres dersom det ikke er lagt til vinterhjul i tilbudet.'}
          </div>
        )}
    </div>
  );

  const renderAlternativeLeasingDynamicList = () => {
    const calculation = getCalculationByType(order, FinancingType.ALTERNATIVELEASING);
    const config = {
      maxSelected: 1,
      selectable: true,
      withCheckbox: true,
      withAccordion: true,
      cols: [
        { header: 'Valgt leasingselskap', key: 'organizationName', noSort: true },
        { header: 'Organisasjonsnummer', key: 'organizationNo', noSort: true },
        { 
          header: 'Årlig kjørelengde', 
          key: 'serviceContractDrivingDistance', 
          formatter: rangeFormatter,
          noSort: true 
        },
        { 
          header: 'Serviceavtale', 
          key: 'serviceContractDuration', 
          formatter: loanDurationFormatter, 
          noSort: true 
        },
        {
          header: 'Serviceavtale  /mnd',
          key: 'serviceContractMonthlyPrice',
          formatter: monthlyPriceFormatter,
          noSort: true,
        },
      ],
    };

    const data = alternativeLeasing ? [
      {
        organizationName: alternativeLeasing?.organizationName,
        organizationNo: alternativeLeasing?.organizationNo,
        id: calculation?.id,
        serviceContractDrivingDistance: calculation?.serviceContract?.drivingDistance,
        serviceContractDuration: calculation?.serviceContract?.duration,
        serviceContractMonthlyPrice: calculation?.serviceContract?.monthlyPrice
      },
    ] : [];
    
    return (
      <div
        className={`calculation-type singleRounded alternativeLeasing ${props.className} ${
          alternativeLeasing ? 'leasingAdded' : ''
        }`}>
        <DynamicList
          data={data?.map((element) => ({
            ...element,
            accordionChild: getFinanceDialog(element?.id),
            isAccordionOpen: !userClosedAccordion && (
              (props.addNew === false && element?.id === getLatestOffer()) ||
              props.selectedRows[0]?.id === element?.id
            ),
            userClosedAccordion: (param) => {
              props.selectedRows[0]?.id === param.id && setUserClosedAccordion(true);
            },
          }))}
          sort={props.sort}
          isNewDynamicList={true}
          config={config}
          selectedRows={props.selectedRows}
          selectedRowsKey="id"
          isFinancePage={true}
          onElementClick={props.onElementClick}
        />
        {props.addNew ? getFinanceDialog() : null}
        {props.stopSantandarService?.includes(props.santanderProductType) && (
          <div className="error-santandar">{'(Foreløpig ikke tilgjengelig)'}</div>
        )}
      </div>
    );
  };

  const organizationName = defaultTo(alternativeLeasing?.organizationName, null);

  const modalData = {
    title:
      currentActiveTab === LeasingTypeEnum.ALTERNATIVE_LEASING
        ? 'Slette leasing fra Bertel O. Steen Finans?'
        : `Slette leasing fra ${organizationName}?`,
    description:
      currentActiveTab === LeasingTypeEnum.ALTERNATIVE_LEASING
        ? 'Dersom du legger til leasing fra et alternativt leasingselskap slettes leasing fra Bertel O. Steen Finans fra tilbudet.'
        : `Dersom du legger til leasing fra Bertel O. Steen Finans slettes leasing fra ${organizationName} fra tilbudet.`,
    actionsList: [
      {
        label:
          currentActiveTab === LeasingTypeEnum.ALTERNATIVE_LEASING
            ? 'Ja, slett leasing fra Bertel O. Steen Finans'
            : `Ja, slett leasing fra ${organizationName}`,
        actionMethod: () => {
          handleToggleAlertModal();
          handleAddLeasing();
        },
        actionVariant: 'secondary',
      },
      {
        label: 'Avbryt',
        actionMethod: () => {
          handleToggleAlertModal();
        },
        actionVariant: 'secondary',
      },
    ],
  };

  const warningModalData = {
    title: 'Leasingselskaper feil',
    description: 'Vi kunne ikke hente oversikt over leasingselskaper, vennligst prøv igjen senere',
    actionsList: [
      {
        label: 'OK',
        actionMethod: () => {
          setIsError(false);
        },
        actionVariant: 'secondary',
      },
    ],
  };

  const handleToggleAlertModal = () => {
    setDeleteLeasingAlertModal(!deleteLeasingAlertModal);
  };

  const handleAddLeasing = () => {
    props?.financeDialogProps?.onAddCalculation(leasingInput?.financingType, leasingInput?.input);
  };

  const removeAlternativeLeasing = async (orderId: string) => {
    const order = await props?.removeAlternativeLeasing(orderId);
    setCurrentActiveTab(LeasingTypeEnum.BOS_LEASING);
    return order;
  };

  const handleLeasingTabSection = async (finance: FinancingType) => {
    props?.setAddNew(false);
    props?.handleSetSelectedLeasingTab(finance)
    if (finance === FinancingType.ALTERNATIVELEASING && !alternativeLeasing) {
      props?.handleAddCalculation(finance)
    }
  }

  return (
    <>
      <OrderAccordion isOpen={true} label={props.label} financingType={props?.financingType}>
        {props?.financingType === FinancingType.LEASING ? (
          <Tabs
            selectedTab={currentActiveTab}
            defaultTabId={LeasingTypeEnum.BOS_LEASING}
            underline
            className="newNext leasingTabs"
            GetActiveTabId={(param: TabId) => {
              setCurrentActiveTab(param);
            }}>
            <Tabs.TabList>
              <Tabs.Tab onTabClick={() => {handleLeasingTabSection(FinancingType.LEASING)}} tabId={LeasingTypeEnum.BOS_LEASING}>
                Bertel O. Steen Finans
              </Tabs.Tab>
              <Tabs.Tab onTabClick={() => {handleLeasingTabSection(FinancingType.ALTERNATIVELEASING)}} tabId={LeasingTypeEnum.ALTERNATIVE_LEASING} >
                Alternativt leasingselskap
              </Tabs.Tab>
            </Tabs.TabList>
            <Tabs.TabPanel tabId={LeasingTypeEnum.BOS_LEASING}>{renderDynamicListSection()}</Tabs.TabPanel>
            <Tabs.TabPanel tabId={LeasingTypeEnum.ALTERNATIVE_LEASING}>
              {renderAlternativeLeasingDynamicList()}
            </Tabs.TabPanel>
          </Tabs>
        ) : (
          renderDynamicListSection()
        )}
      </OrderAccordion>

      {deleteLeasingAlertModal && (
        <CustomAlertPopup
          isOpen={deleteLeasingAlertModal}
          onCloseClick={() => setDeleteLeasingAlertModal(!deleteLeasingAlertModal)}
          className="deleteAlert deleteLeasingOffer"
          deleteOrderData={modalData}
        />
      )}

      {isError && (
        <CustomAlertPopup
          isOpen={isError}
          onCloseClick={() => setIsError(false)}
          className="deleteAlert deleteLeasingOffer"
          deleteOrderData={warningModalData}
        />
      )}
    </>
  );
};

FinanceAccordion.defaultProps = {
  label: '',
  stopSantandarService: [],
  santanderProductType: '',
  className: '',
};
