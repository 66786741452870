import React from 'react';
import { priceFormatter } from '../../../../../utils';
import { IVehicleModel } from '../../VehiclePickerPage';
import { ICartDataProps } from '../ResultGrid';
import { CarConfigureGroupStyled } from '../ResultGrid.styled';
import { OrderAccordion } from '../../../sales-common/OrderAccordion';
import { CardType } from '@next-components/common-types';
import { Card } from '@next-components/card';
import { MAKE } from 'next-common';

export interface IVehicleModelProps {
  data: IVehicleModel[];
  selectedModelId: string;
  selectedModelCode: string;
  vehicleBrand?: MAKE;
  onModelChange: (data: ICartDataProps) => void;
  disabled: boolean;
  selectedModelName: string;
}

export const VehicleModel = (props: IVehicleModelProps) => {
  const { data, selectedModelId, onModelChange, disabled, selectedModelCode, selectedModelName } = props;

  const sortedData = data?.sort((a, b) => a.basePrice - b.basePrice);

  return (
    <OrderAccordion label="Motorvariant" isOpen={true}>
      {sortedData?.length <= 0 ? (
        <p> Ingen motorvariant tilgjengelig</p>
      ) : (
        <CarConfigureGroupStyled className="vehicleModel">
          {sortedData?.map((model) => {
            const title = props.vehicleBrand === MAKE.Kia ? model?.name : `${model?.name} (${model?.stockCount})`;
            return (
              <Card
                key={model?.id}
                id={model?.id}
                title={title}
                customPriceTag={priceFormatter(model?.basePrice)}
                type={CardType.BosCard}
                updateSelected={() =>
                  onModelChange({
                    id: model?.id === selectedModelId ? null : model?.id,
                    name: model?.name === selectedModelName ? null : model?.name,
                    price: model?.id === selectedModelId ? null : model?.basePrice,
                    vatCode: model?.id === selectedModelId ? null : model?.vatCode,
                    dealerPriceExclVat: model?.baseCostPriceExclVat,
                    code: model?.code === selectedModelCode ? null : model?.code,
                  })
                }
                selected={selectedModelId === model?.id}
                isDisabled={disabled}
              />
            );
          })}
        </CarConfigureGroupStyled>
      )}
    </OrderAccordion>
  );
};
