import React, { useEffect, useState } from 'react';
import { AccessoryType, Image } from 'next-common';
import { TireSetsStyled } from '../VehicleAccessoriesPage.styled';
import { AccessoryChangeAction, IAccessoryChange, ICustomAccessoryProps } from '../AccessoryTool';
import { PimTires } from '../../../common-components/PimTires';
import { OrderAccordion } from '../../sales-common/OrderAccordion';
import { SingleDetails } from '@next-components/single-details';
import { ContentTransformerWrapper } from '../../sales-vehicle-picker-page/ResultGrid/OrderAccordian/VehicleSelectableDescription';
import placeholderImage from '../../../../assets/images/placeholder.png';

export interface ITireSetsProps {
  loading: boolean;
  tireSetData: Partial<ICustomAccessoryProps>[];
  changes: IAccessoryChange[];
  isLeasingOffer?: boolean;
  handleSwitchTire(fromId: string, toId: string): void;
  handleRemoveTire(id: string, accessory: ICustomAccessoryProps, accessoryType: AccessoryType): void;
  raiseWarningForUnselectTire?(): void;
}

const placeholder: Image = {
  url: placeholderImage,
  altText: '',
  key: '',
  variants: [],
};

export const TireSets = (props: ITireSetsProps) => {
  const [selectedWinterTire, setSelectedWinterTire] = useState<string>(null);
  const [winterTireList, setWinterTireList]: [ICustomAccessoryProps[], any] = useState<ICustomAccessoryProps[]>(null);
  const [showPackageComment, setShowPackageComment] = React.useState<string>('');
  const [toggledTag, setToggledTag] = React.useState<ICustomAccessoryProps>(null);

  useEffect(() => {
    if (!props.tireSetData) {
      return;
    }
    setWinterTireList(props.tireSetData);
    setSelectedWinterTire(props.tireSetData?.find((tireSet) => tireSet?.isSelected)?.id);
  }, [props.tireSetData]);

  useEffect(() => {
    if (!props.changes?.length) {
      setSelectedWinterTire(props.tireSetData?.find((tireSet) => tireSet?.isSelected)?.id);
    }
  }, [props.changes]);

  const handleTireSetSave = async (id: string, previousTireId: string) => {
    setSelectedWinterTire(id);
    props.handleSwitchTire(previousTireId, id);
  };

  const handleTireSetRemove = async (id: string) => {
    if (props?.isLeasingOffer) {
      return props?.raiseWarningForUnselectTire();
    }

    setSelectedWinterTire(null);
    const removedTire = winterTireList?.find((tire) => tire.id === id);
    props.handleRemoveTire(id, removedTire, AccessoryType.TYRE);
  };

  const handleTireSet = (id: string) => {
    const previousTireId = selectedWinterTire?.valueOf() ?? null;
    if (previousTireId !== id) {
      handleTireSetSave(id, previousTireId);
    } else {
      handleTireSetRemove(id);
    }
  };

  return (
    <OrderAccordion label="Vinterhjul" isOpen contentOverflow>
      {winterTireList?.length ? (
        <TireSetsStyled>
          {winterTireList?.map((tireSet) => {
            const change = props.changes?.find((change) => change?.accessoryId === tireSet?.id);
            return (
              <PimTires
                key={tireSet?.id}
                id={tireSet?.id}
                title={tireSet?.name}
                priceIncludingVat={tireSet?.price}
                priceExcludingVat={tireSet?.priceExcludingVat}
                images={tireSet?.images}
                isSelected={(!change && tireSet?.isSelected) || change?.action === AccessoryChangeAction.ADD}
                handleTireSet={handleTireSet}
                packageId={tireSet?.packageId}
                onTagClick={() => {
                  setShowPackageComment(tireSet?.id);
                  setToggledTag(tireSet);
                }}
                disableTag={
                  (!tireSet?.packageComment?.plainText || tireSet?.packageComment?.plainText === `[""]`) &&
                  !tireSet?.description
                }
                tagModal={{
                  isOpen: showPackageComment === tireSet?.id,
                  modalChildComponent: (
                    <SingleDetails
                      onCloseClick={() => setShowPackageComment('')}
                      active={showPackageComment === tireSet?.id}
                      title={toggledTag?.name || ''}
                      details={
                        <>
                          {ContentTransformerWrapper(toggledTag?.packageComment?.json) ?? null}
                          <br />
                          {ContentTransformerWrapper(toggledTag?.description) ?? null}
                        </>
                      }
                      img={(toggledTag?.images as Image) || placeholder}
                    />
                  ),
                }}
              />
            );
          })}
        </TireSetsStyled>
      ) : (
        <p>Ingen vinterhjul funnet</p>
      )}
    </OrderAccordion>
  );
};
