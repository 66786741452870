import React, { useEffect, useState } from 'react';
import { CardWrapper, ImporterOptionsPageStyled } from './ImporterOptionsPage.styled';
import { IconType } from '@next-components/bos-icon';
import { Cart, ICartPrices, ICartProps, IOneOffTaxAndWreckage } from '@next-components/cart';
import { InvalidTTCodePopup, InvalidDatePopup } from '../../common-components/PCodePopup';
import {
  MBApiCalculationFlag,
  INextArticle,
  IOrder,
  IVehicleConfiguration,
  isStockSaleOrderToBeProcessed,
  UsageCodes,
  IPcodes,
  OptionSource,
  IAccessoryList,
  IRammeavtale,
} from 'next-common';
import {
  checkAndUpdateCart,
  filterSelectedEquipmentInCart,
  getSelectedEquipmentInCart,
  getStockSaleParams,
  prepareArticles,
  prepareVehicleConfig,
  VehicleConfig,
  isDateValidateFailed,
  vehicleStatusToSavedDataValidation,
  masterAPIToSavedDataValidation,
  mapSelectedOptions,
  setOptionChanges,
  getSelectedPcodes,
  removeDuplicateObjectFromArray,
  ICurrentChanges,
  applyTTCodeValidation,
  getPriceValidation,
  isValidOverriddenCharges,
  updateVehicleVatCodeAndDiscount,
  getOtherFeeTotal,
  checkIfBrukskodeEnabled,
  priceFormatter,
  isOfferCase,
} from '../../../utils';
import { getArticlesWithDiscount, MarginCart } from '../../common-components/MarginCart';
import {
  INotificationPageProps,
  IPCodeWarnFlags,
  IVehiclePageProps,
  IVehicleStock,
  Routes,
  useConfigurations,
  useVatStateCalculationHandler,
  useVehicleData,
} from '../../../common';
import { IAlertPopup } from '../sales-wizard';
import { getStepData, IStepMenuData } from '../sales-wizard/sales-wizard-menu';
import { OrderAccordion } from '../sales-common/OrderAccordion';
import { Card } from '@next-components/card';
import { CardType } from '@next-components/common-types';
import { MessagebarSeverityLevel } from '@next-components/messagebar';
import { cloneDeep, defaultTo, differenceBy, get, isEqual, sumBy } from 'lodash';
import { SalesWizardButtonMenu } from '../sales-wizard/sales-wizard-menu/SalesWizardButtonMenu';
import { useNavigate } from 'react-router';
import { usePCodes } from '../../../common/hooks/usePCodes';
import { ArticleType, IPcodeMaster, IVehicleStockEcomItem, VatCode } from 'sales-common';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { CustomAlertPopup } from '../../common-components/custom-DeletePopup';
import { useMutation } from '@apollo/client';
import { togglePcodesOnOrderMutationParsed } from '../../../graphql';
import { NextErrorNotification, OneOffTaxChangeWarning, PCodeValidationWarning, PriceMismatchWarning } from '../../notifications';
import { RulesPopup } from '../../common-components/RulesPopup/RulesPopup';
import { IRuleValues } from '../../common-components/RulesPopup/RulesDetail';
import { IOverriddenTaxAndUsageCode } from '../sales-vehicle-picker-page';
import { updateOrderMutationWithOrderInputMutationParsed } from '../../../graphql/backend/mutations/order';

export interface IImporterOptionsPageProps extends IVehiclePageProps, INotificationPageProps {
  nonExactMatchCount?: number;
  exactMatchCount?: number;
  order?: IOrder;
  vehicle?: IVehicleConfiguration;
  isCalled?: boolean;
  cartData?: ICartProps;
  articles?: INextArticle[];
  availableVehicleList?: IVehicleStock[];
  selectedSerialNo?: number;
  vatPrices?: ICartPrices;
  isElectricCar?: boolean;
  isShowDeleteModel?: boolean;
  isShowDeleteButton?: boolean;
  isLoading?: boolean;
  rootUrl?: string;
  stepMenuData?: IStepMenuData;
  deleteOrderData?: IAlertPopup;
  trackTraceCode?: number;
  setIsShowDeleteModel?: (isShowDeleteModel: boolean) => void;
  setIsCalled?: (value: boolean) => void;
  setIsLoading?(value: boolean): void;
}

export const ImporterOptionsPage = (props: IImporterOptionsPageProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isChange, setIsChange] = useState(false);
  const [importersLocalOption, setImportersLocalOption] = useState<IPcodeMaster[]>([]);
  const [selectedVehicleData, setSelectedVehicleData] = useState<IVehicleStockEcomItem>(null);
  const [cart, setCart] = useState<ICartProps>(props.cartData);
  const [articles, setArticles] = useState<INextArticle[]>(props?.articles);
  const [warning, setWarning] = useState<IPCodeWarnFlags>({
    showDateWarn: false,
    showVehicleStatusMismatchWarn: false,
    showMasterAPIMismatchWarn: false,
    isPriceMismatch: false,
    showTTCodeWarn: false
  });
  const [currentChanges, setCurrentChanges] = useState<ICurrentChanges>({ addedOptions: [], removedOptions: [] });
  const [isMismatch, setIsMismatch] = useState(false);
  const [savedOptions, setSavedOptions] = useState<IPcodes[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<IPcodes[]>([]);
  const [preSelectedOptions, setPreSelectedOptions] = useState<IPcodes[]>([]);
  const [priceMismatchOptions, setPriceMismatchOptions] = useState([]);
  const [isDateValidationPopup, setIsDateValidationPopup] = useState<string>(null);
  const [optionTTCode, setOptionTTCode] = useState<string>(null);
  const order: IOrder = props?.order;
  const vehicle: IVehicleConfiguration = props?.vehicle;
  const carType = defaultTo(order?.pimSnapshot?.vehicleType, vehicle?.model?.vehicleType);
  const popupPrice = sumBy(currentChanges?.addedOptions, 'price') - sumBy(currentChanges?.removedOptions, 'price');
  const navigate = useNavigate();
  const [togglePcodesOnOrder] = useMutation(togglePcodesOnOrderMutationParsed);
  const stepData = getStepData(props?.activeStep, props?.orderId, props?.rootUrl, props?.isVarebilEnabled);
  const params = getStockSaleParams(order);
  const [isSaveFromMargin, setIsSaveFromMargin] = useState<boolean>(false);
  const [isPcodeChangeFound, setIsPcodeChangeFound] = useState(false);
  const [isPcodePriceChangeFound, setIsPcodePriceChangeFound] = useState(false);
  const [isPcodeChangeCancelled, setIsPcodeChangeCancelled] = useState<boolean>(null);
  const [updateOrderMutationWithOrderInput] = useMutation(updateOrderMutationWithOrderInputMutationParsed);
  const [isSaveFromOverriddenTax, setIsSaveFromOverriddenTax] = useState<boolean>(false);
  const [currentOverriddenTax, setCurrentOverriddenTax] = useState<number>(null);
  const [articlesUpdated, setArticlesUpdated] = useState<boolean>(false);
  const [overriddenTaxAndUsageCodeDetails, setOverriddenTaxAndUsageCodeDetails] = useState<IOverriddenTaxAndUsageCode>({ chargesAreOverridden: false, doneReset: false, overriddenVehicleRoadTax: null, usageCode: null });
  const [isBrukskodeEnabled, setIsBrukskodeEnabled] = useState<boolean>(checkIfBrukskodeEnabled(props?.configuration));
  const [rammeavtale, setRammeavtale] = useState<IRammeavtale>(props?.order?.rammeavtale);
  const isStockSaleOrder = isStockSaleOrderToBeProcessed(
    props?.configuration,
    params?.source,
    params?.serialNo,
    params?.make,
  );

  const { finalPrice } = useVatStateCalculationHandler({
    articles,
    carType,
  });

  const { data: vehicleData, loading: vehicleDataLoading } = useVehicleData({
    serialNo: order?.vehicles?.[0]?.serialNo,
    pCodesUpdatedAt: order?.pCodesUpdatedAt,
    configuration: props?.configuration,
    fetchPolicy: 'cache-and-network',
  });

  const configurations = useConfigurations();
    
  const isRammeavtaleEnabled = configurations?.features?.rammeavtale ?? false;

  useEffect(() => {
    setIsBrukskodeEnabled(checkIfBrukskodeEnabled(props?.configuration));
  }, [props?.configuration]);

  useEffect(() => {
    setSelectedVehicleData(vehicleData);
  }, [vehicleData]);

  useEffect(() => {
    setOverriddenTaxAndUsageCodeDetails({
      chargesAreOverridden:
        props?.order?.chargesAreOverridden ?? overriddenTaxAndUsageCodeDetails?.chargesAreOverridden,
      doneReset: overriddenTaxAndUsageCodeDetails?.doneReset,
      overriddenVehicleRoadTax: props?.order?.vatCalculation?.overriddenVehicleRoadTax,
      usageCode: props?.order?.pimSnapshot?.vehicleUsageCode,
    });
  }, [props?.order]);

  useEffect(() => {
    const isArticleUpdated = {
      modelArticleUpdated: false,
      oneOffTaxArticleUpdated: false
    };
    if (articles?.length > 0 && (props?.order?.chargesAreOverridden || props?.order?.pimSnapshot?.vehicleUsageCode) && !articlesUpdated) {
      const finalArticles = articles?.map((article) => {
        if (article?.key === VehicleConfig.MODEL) {
          if(article?.vatCode === (overriddenTaxAndUsageCodeDetails?.usageCode === UsageCodes.EL ? VatCode.DEDUCTABLE : VatCode.NON_DEDUCTABLE)){
            isArticleUpdated.modelArticleUpdated = true;
            return article;
          }
          return {
            ...article,
            vatCode: overriddenTaxAndUsageCodeDetails?.usageCode === UsageCodes.EL ? VatCode.DEDUCTABLE : VatCode.NON_DEDUCTABLE,
          };
        } else if (article?.key === VehicleConfig.TAREWEIGHT) {
          const overriddenOneOffTax = (props?.order?.vatCalculation?.overriddenVehicleRoadTax && props?.order?.vatCalculation?.overriddenVehicleRoadTax >= 0) ? (props?.order?.vatCalculation?.overriddenVehicleRoadTax - getOtherFeeTotal(props?.order?.pimSnapshot)) : null;
          if(article?.priceExclVat === (overriddenOneOffTax ?? props?.order?.pimSnapshot?.oneOffTaxWeight ?? finalPrice?.oneOffTax)){
            isArticleUpdated.oneOffTaxArticleUpdated = true;
            return article
          }
          return {
            ...article,
            priceExclVat: overriddenOneOffTax ?? props?.order?.pimSnapshot?.oneOffTaxWeight ?? finalPrice?.oneOffTax
          };
        } else {
          return article;
        }
      });
      if(isArticleUpdated.modelArticleUpdated && isArticleUpdated.oneOffTaxArticleUpdated){
        setArticlesUpdated(true);
      }
      setArticles(finalArticles);
    }
  }, [props?.order, articles]);

  useEffect(() => {
    // tslint:disable-next-line:no-console
    if (
      (isValidOverriddenCharges(overriddenTaxAndUsageCodeDetails)) ||
      overriddenTaxAndUsageCodeDetails?.doneReset
    ) {
      if (overriddenTaxAndUsageCodeDetails?.doneReset) {
        if (articles?.length > 0) {
          const finalArticles = articles?.map((article) => {
            if (article?.key === VehicleConfig.TAREWEIGHT) {
              return {
                ...article, priceExclVat: props?.order?.pimSnapshot?.oneOffTaxWeight ?? finalPrice?.oneOffTax
              }
            } else {
              return article;
            }
          });
          setArticles(finalArticles);
        }
      } else if (isValidOverriddenCharges(overriddenTaxAndUsageCodeDetails)) {
        if (articles?.length > 0) {
          const finalArticles = articles?.map((article) => {
            if (article?.key === VehicleConfig.MODEL) {
              return { ...article, vatCode: overriddenTaxAndUsageCodeDetails?.usageCode === UsageCodes.EL ? VatCode.DEDUCTABLE : VatCode.NON_DEDUCTABLE }
            } else if (article?.key === VehicleConfig.TAREWEIGHT) {
              return { ...article, priceExclVat: overriddenTaxAndUsageCodeDetails?.overriddenVehicleRoadTax - getOtherFeeTotal(props?.order?.pimSnapshot) }
            } else {
              return article;
            }
          });
          setArticles(finalArticles);
        }
      }
    }
  }, [overriddenTaxAndUsageCodeDetails]);

  //Fetch options from MASTER api
  const { pCodes: vehiclePCodes, loading: pCodesLoading } = usePCodes({
    articleCode: order?.pimSnapshot?.salesArticle,
    pCodeMasterData: props.pCodeMasterData
  });

  useEffect(() => {
    if (order && vehicleData) {
      const mismatchedData = vehicleStatusToSavedDataValidation(order, vehicleData);
      setWarning({
        ...warning,
        showVehicleStatusMismatchWarn: Boolean(mismatchedData?.length),
      });
    }
  }, [order, vehicleData]);

  const isOptionDisableByTTCode = (option: IPcodeMaster, isSelected: IPcodeMaster) => {
    let isDisabled = false;
    const isPreSelected = preSelectedOptions?.find((saved) => saved?.localOptionCode === option?.localOptionCode);
    const { isSelectionAllowed, isDeSelectionAllowed, showWarning } = applyTTCodeValidation(option, props.trackTraceCode, order, preSelectedOptions);
    if (!Boolean(isPreSelected) && !isSelectionAllowed) isDisabled = true;
    if (Boolean(isPreSelected) && !isDeSelectionAllowed) isDisabled = true;
    if(showWarning && !warning.showTTCodeWarn && Boolean(isSelected)) {
      setWarning({
        ...warning,
        showTTCodeWarn: true,
      });
    }
    return isDisabled;
  }

  const isOptionDisableByDate = (option: IPcodeMaster, isSelected: IPcodeMaster, isTTCodeDisabled: boolean) => {
    let isDisabled = false;
    let showWarningIcon = false;
    if(Boolean(isSelected) && isTTCodeDisabled) {
      //D2-AK2-23: If option is disabled by TTCode & validToDate is also passed then warning icon should not display.
      return { isDisabled, showWarningIcon };
    } else {
      const { isValidToDate } = isDateValidateFailed(option);
      if (!isValidToDate) {
        showWarningIcon = true;
        if(Boolean(isSelected) && !warning?.showDateWarn) {
          setWarning({
            ...warning,
            showDateWarn: true,
          });
        } 
        if(!Boolean(isSelected)) {
          isDisabled = true;
        }
      }
      return { isDisabled, showWarningIcon};
    }
  }

  const removeInvalidOption = (PCodeMasterList: IPcodeMaster[], selectedPcodes: IPcodes[] ) => {
    //D2-AK2-21:Hide option if not selected & valid To date is also passed
    const updatedPCodeList = [];
      PCodeMasterList?.forEach(option => {
        const isSelected = selectedPcodes?.find(
          (selected) => selected?.localOptionCode === option?.localOptionCode,
        );
        if(isSelected) {
          updatedPCodeList.push(option)
        } else {
          //TTCode validation
          let isTTCodeDisabled = isOptionDisableByTTCode(option, isSelected);
    
          // date validation
          let { isDisabled: isDateDisabled} = isOptionDisableByDate(option, isSelected, isTTCodeDisabled);
          if(!isDateDisabled) {
            updatedPCodeList.push(option)
          }
        }
      })
      return updatedPCodeList;
  }

  useEffect(() => {
    if (order && vehiclePCodes) {
      const isOffer = isOfferCase(order);
      const mismatchedData = masterAPIToSavedDataValidation(order, vehiclePCodes);
      const priceMismatchedData = isOffer ? getPriceValidation(order, mismatchedData, vehiclePCodes) : [];
      setWarning({
        ...warning,
        showMasterAPIMismatchWarn: Boolean(mismatchedData?.length),
        isPriceMismatch: Boolean(priceMismatchedData?.length),
      });
      setPriceMismatchOptions(priceMismatchedData);
      if (priceMismatchedData?.length > 0) {
        props.raiseNotification(
          <PriceMismatchWarning
            priceChangedFields={priceMismatchedData}
            key="PriceChange"
            onClose={() => props?.clearNotifications('PriceChange')}
          />,
        );
      }
      const { pCodes, mappedArticles, cartData, preSelectedPcodes } = getSelectedPcodes(order?.pCodes, selectedVehicleData, vehiclePCodes, isOffer);
      setPreSelectedOptions([...savedOptions, ...preSelectedPcodes]);
      setSelectedOptions(pCodes ?? []);
      if(vehiclePCodes?.length) {
        setImportersLocalOption(removeInvalidOption(vehiclePCodes, pCodes));
      }
      setArticles(removeDuplicateObjectFromArray([...articles, ...mappedArticles], 'key'));
      setCart((prev) => {
        const finalData = removeDuplicateObjectFromArray([...(prev?.data || []), ...cartData], 'key');
        return { ...prev, data: finalData };
      });
      setCurrentChanges((prev) => {
        const addedOptions = mapSelectedOptions(pCodes);
        const removedOptions = mapSelectedOptions(mismatchedData);
        return setOptionChanges(prev, addedOptions, removedOptions);
      });
    }
  }, [order, vehiclePCodes, selectedVehicleData]);

  useEffect(() => {
    if(isSaveFromMargin && priceMismatchOptions?.length) {
      setIsPcodePriceChangeFound(true);
      const { isMismatchFound  } = checkForPCodeMismatch(selectedOptions, savedOptions);
      if(!isMismatchFound) {
        savePcodes();
      }
    } 
  }, [isSaveFromMargin, priceMismatchOptions])

  useEffect(() => {
    setSavedOptions(order?.pCodes || []);
  }, [order]);

  useEffect(() => {
    if (!isEqual(cart, props?.cartData)) {
      setCart(props.cartData);
    }
    if (!isEqual(articles, props?.articles)) {
      setArticles(props.articles);
    }
  }, [props?.cartData, props?.articles]);

  useEffect(() => {
    props.setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setIsChange(Boolean(currentChanges?.addedOptions?.length || currentChanges?.removedOptions?.length) || Boolean(priceMismatchOptions?.length));
  }, [currentChanges, priceMismatchOptions]);

  useEffect(() => {
    if (props?.isCalled && !isChange) {
      if (!savedOptions?.length && !selectedOptions?.length && preSelectedOptions?.length > 0) {
        savePcodes();
      } else {
        nextStep();
      }
    }
    if (props?.isCalled && isChange) {
      const { addedOptions, removedOptions, isMismatchFound } = checkForPCodeMismatch(selectedOptions, savedOptions)
      setCurrentChanges({ addedOptions, removedOptions });
      if (isMismatchFound) {
        setIsMismatch(isMismatchFound);
      } else if (Boolean(priceMismatchOptions?.length)) {
        savePcodes();
      } else {
        nextStep();
      }
    }
  }, [props?.isCalled]);

  useEffect(() => {
    const { isMismatchFound  } = checkForPCodeMismatch(selectedOptions, savedOptions);
    setIsPcodeChangeFound(isMismatchFound)
  }, [selectedOptions, savedOptions]);

  const checkForPCodeMismatch = (selectedOptions: IPcodes[], savedOptions: IPcodes[]) => {
    const addedOptions = mapSelectedOptions(differenceBy(selectedOptions, savedOptions, 'localOptionCode'));
    const removedOptions = mapSelectedOptions(differenceBy(savedOptions, selectedOptions, 'localOptionCode'));
    const isMismatchFound = Boolean(addedOptions?.length || removedOptions?.length)
    return { addedOptions, removedOptions, isMismatchFound };
  }

  const nextStep = () => {
    props.setIsLoading(false);
    setLoading(false);
    props.setIsCalled(false);
    const route = Routes.getVehicleAccessoriesPage(order?.id);
    const redirectUrl = get(props?.stepMenuData, 'route') ? props?.stepMenuData?.route : route;
    navigate(redirectUrl);
  };

  const savePcodesOnOrder = async () => {
    setLoading(true);
    const variables = {
      orderId: order?.id,
      input: selectedOptions,
    };
    const { data } = await togglePcodesOnOrder({ variables });
    if (data?.togglePcodesOnOrder?.__typename === 'NextError') {
      props.raiseNotification(
        <NextErrorNotification
          key={'togglePcodesFail'}
          orderId={order?.id}
          errorCode={data?.togglePcodesOnOrder?.errorCode}
          onClose={() => props?.clearNotifications('togglePcodesFail')}
        />,
      );
    }
    props.clearNotifications('PriceChange');
    if(!isSaveFromMargin && !isSaveFromOverriddenTax) nextStep();
    return data?.createPcodes || order;
  };

  useEffect(() => {
    if(isSaveFromMargin && isChange) {
      const { addedOptions, removedOptions, isMismatchFound  } = checkForPCodeMismatch(selectedOptions, savedOptions);
      setCurrentChanges({ addedOptions, removedOptions });
      setIsMismatch(isMismatchFound);
    }
  }, [isSaveFromMargin])

  const savePcodes = () => {
    savePcodesOnOrder()
      .catch((error) => {
        console.error('An error occurred while updating Pcodes', error);
      })
      .finally(() => {
        setLoading(false);
        setIsPcodeChangeCancelled(false);
        setIsPcodeChangeFound(false);
        setIsPcodePriceChangeFound(false);
      });
  };

  const updateCartAndArticles = (id: string, option: IPcodes) => {
    const optionKey = `${VehicleConfig.PCODE}_${option?.localOptionCode || ''}`;
    const cartCopy: ICartProps = cloneDeep(cart);
    if (!id) {
      checkAndUpdateCart(optionKey, cartCopy, setCart);
      setArticles([...prepareArticles(optionKey, 0, '', ArticleType.EQUIPMENT, 0, false, articles, true)]);
    } else {
      const selectedOption = prepareVehicleConfig(
        optionKey,
        option?.localOptionCode,
        option?.localOptionDescription,
        option?.customerPriceExclVat,
        option?.vatCode,
      );
      checkAndUpdateCart(optionKey, cartCopy, setCart, selectedOption);
      setArticles([
        ...prepareArticles(
          optionKey,
          option?.customerPriceExclVat,
          option?.vatCode,
          ArticleType.EQUIPMENT,
          option?.costPriceExclVat,
          false,
          articles,
        ),
      ]);
    }
  };

  const handleOptions = (id: string, option: IPcodeMaster) => {
    let selectedOptionsCopy = [...selectedOptions];
    if (!id) {
      selectedOptionsCopy = selectedOptionsCopy?.filter(
        (selected) => selected?.localOptionCode !== option?.localOptionCode,
      );
    } else {
      selectedOptionsCopy.push({...option, isDefaultPcode: false, optionSource: OptionSource.MASTER });
    }
    const addedOption = mapSelectedOptions(differenceBy(selectedOptionsCopy, savedOptions, 'localOptionCode'));
    const removedOption = mapSelectedOptions(differenceBy(savedOptions, selectedOptionsCopy, 'localOptionCode'));
    setIsChange(addedOption?.length > 0 || removedOption?.length > 0);
    setSelectedOptions(selectedOptionsCopy);

    const isOptionWithInvalidDateIsSelected = selectedOptionsCopy?.find(option => {
      const { isValidToDate } = isDateValidateFailed(option);
      return !isValidToDate;
    })
    
    //D2-AK2-22: Remove date warning from page top
    if(!isOptionWithInvalidDateIsSelected && warning.showDateWarn) {
      setWarning({
        ...warning,
        showDateWarn: false,
      });
    }

    if(id) {
      const { showWarning } = applyTTCodeValidation(option, props.trackTraceCode, order, [option])
      //D2-AK2-29: Add warning on top if selected option is incorrect
      if(showWarning && !warning.showTTCodeWarn) {
        setWarning({
          ...warning,
          showTTCodeWarn: true,
        });
      }
    } else {
      const isOptionWithInvalidTTCodeIsSelected = selectedOptionsCopy?.find(option => {
        const { showWarning } = applyTTCodeValidation(option, props.trackTraceCode, order, preSelectedOptions);
        return showWarning;
      })
      //D2-AK2-29: Remove TTCode warning from page top
      if(!isOptionWithInvalidTTCodeIsSelected && warning.showTTCodeWarn) {
        setWarning({
          ...warning,
          showTTCodeWarn: false,
        });
      }
    }
    updateCartAndArticles(id, option);
  };

  const handleClose = () => {
    props.setIsShowDeleteModel(false);
    setIsPcodeChangeCancelled(true);
  };

  const handleRulesPopupclose = () => {
    setIsMismatch(false);
    props.setIsCalled(false);
    setIsPcodeChangeCancelled(true);
    setIsSaveFromMargin(false);
    setIsSaveFromOverriddenTax(false);
  };

  const handleRulesPopupConfirm = () => {
    savePcodes();
    setIsMismatch(false);
  };

  useEffect(() => {
    if (isSaveFromOverriddenTax && isPcodeChangeCancelled === false) {
      saveOverriddenTaxInOrder(currentOverriddenTax);
    }
  }, [isPcodeChangeCancelled, isSaveFromOverriddenTax]);


  const setOverriddenTax = async (overriddenTax) => {
    if (props?.order?.vatCalculation?.overriddenVehicleRoadTax !== overriddenTax) {
      const { addedOptions, removedOptions, isMismatchFound } = checkForPCodeMismatch(selectedOptions, savedOptions);
      if (!isMismatchFound && addedOptions?.length === 0 && removedOptions?.length === 0) {
        saveOverriddenTaxInOrder(overriddenTax)
      } else {
        setCurrentChanges({ addedOptions, removedOptions });
        setIsMismatch(isMismatchFound);
        setIsSaveFromOverriddenTax(true);
        setCurrentOverriddenTax(overriddenTax);
      }
    }
  }

  const saveOverriddenTaxInOrder = async (overriddenTax) => {
    setLoading(true);
    try {
      let updatedArticles = cloneDeep(articles);
      const finalArticles = updatedArticles?.map((article) => {
        if (article?.key === VehicleConfig.TAREWEIGHT) {
          const overriddenOneOffTax = (overriddenTax && overriddenTax >= 0) ? (overriddenTax - getOtherFeeTotal(order?.pimSnapshot)) : props?.order?.vatCalculation?.overriddenVehicleRoadTax >= 0 ? (props?.order?.vatCalculation?.overriddenVehicleRoadTax - getOtherFeeTotal(props?.order?.pimSnapshot)) : null;
          return {
            ...article,
            priceExclVat: overriddenOneOffTax ?? props?.order?.pimSnapshot?.vehicleRoadTax
          }
        } else {
          return article;
        }
      });
      const nextArticles = {
        articles: finalArticles,
        manufactureComponentId: props?.order?.pimSnapshot?.modelFactoryCodeId,
      };
      const variables = {
        orderInput: {
          chargesAreOverridden: overriddenTax ? true : false,
          vatCalculation: {
            overriddenVehicleRoadTax: overriddenTax,
          },
        },
        updateOrderId: props?.order?.id,
        nextArticles: nextArticles
      };
      const { data } = await updateOrderMutationWithOrderInput({
        variables
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }


  const isChargesEditable = () => {
    if(props?.order?.pimSnapshot?.vehicleUsageCode && isBrukskodeEnabled) {
      return !["EL", "PB"].includes(props?.order?.pimSnapshot?.vehicleUsageCode);
    }
    return false;
  }

  const updateArticlesWithDiscount = (
    accessory: IAccessoryList,
    customerAdvantage?: boolean,
    discountArticles?: INextArticle[],
  ) => {
    setArticles(getArticlesWithDiscount(accessory, customerAdvantage, discountArticles, articles));
  };

  if (pCodesLoading || !order || vehicleDataLoading) {
    return <LoadingSpinner isModal={true} />;
  }

  return (
    <ImporterOptionsPageStyled className="mainContainer">
      <div className="leftContainer">
        {(props?.vehicleChargeStatus?.isTaxChange || Object.values(warning).find((i) => i === true)) &&
          <div className="msgContainer">
            {
              props.vehicleChargeStatus?.isTaxChange ?
                <div>
                  <OneOffTaxChangeWarning vehicleChargeStatus={props.vehicleChargeStatus} order={props.order} />
                </div> : ''
            }
            <PCodeValidationWarning order={props.order} warning={warning}></PCodeValidationWarning>
          </div>}
        <OrderAccordion label="Opsjoner fra importør" isOpen>
          <CardWrapper>
            {!importersLocalOption?.length
              ? 'Finner ingen lokale alternativer'
              : importersLocalOption?.map((option) => {
                  const isSelected = selectedOptions?.find(
                    (selected) => selected?.localOptionCode === option?.localOptionCode,
                  );

                  //TTCode validation
                  let isTTCodeDisabled = isOptionDisableByTTCode(option, isSelected);

                  //date validation
                  let { isDisabled: isDateDisabled, showWarningIcon} = isOptionDisableByDate(option, isSelected, isTTCodeDisabled);
                  return (
                    <Card
                      key={option?.characteristicId}
                      id={option?.localOptionCode}
                      title={option?.localOptionDescription}
                      customPriceTag={`+ ${priceFormatter(option?.customerPriceExclVat || 0)}`}
                      type={CardType.BosCard}
                      selected={Boolean(isSelected)}
                      isDisabled={isDateDisabled || isTTCodeDisabled}
                      onDisableClick={() => {
                        if(isTTCodeDisabled) {
                          setOptionTTCode(
                            JSON.stringify({
                              trackTraceAdd: option?.trackTraceAdd || null,
                              trackTraceRemove: option?.trackTraceRemove || null,
                              vehicleTTCode: vehicleData?.vehicleStatus?.trackTraceCode || null,
                              selected: Boolean(isSelected)
                            }),
                          );
                        }
                      }}
                      isCardLocked={false}
                      alertMessage={{
                        typeOfAlert: MessagebarSeverityLevel.WARNING,
                        showIcon: showWarningIcon,
                        onIconClick: () => {
                          setIsDateValidationPopup(JSON.stringify({ selected: Boolean(isSelected) }))
                        },
                      }}
                      displayTag
                      tagValue={option?.localOptionCode}
                      updateSelected={(id) => {
                        handleOptions(id, option);
                      }}
                      disableTag
                    />
                  );
                })}
          </CardWrapper>
        </OrderAccordion>
        <div className="footerMenu">
          <SalesWizardButtonMenu
            orderId={props.orderId}
            order={order}
            calculationId={props?.calculationId}
            activeStep={props?.activeStep}
            isUnavailablePimData={props?.isUnavailablePimData}
            menuLoading={props?.menuLoading}
            onClickSigning={props?.onClickSigning}
            onClick={props?.onClick}
            isStockSaleOrder={isStockSaleOrder}
            stepData={stepData?.[stepData?.length - 1]}
            allStepData={stepData}
            isVehicleValidForReserveOrSign={props.isVehicleValidForReserveOrSign}
            bottomButton={true}
            vehicleAvailabilityCode={props.vehicleAvailabilityCode}
            isVarebilEnabled={props.isVarebilEnabled}
            invalidPcodesValidation={props?.invalidPcodesValidation}
          />
        </div>
      </div>
      <InvalidTTCodePopup
        isOpen={Boolean(optionTTCode)}
        optionTTCode={optionTTCode}
        handleClose={() => {
          setOptionTTCode(null);
        }}
      />
      <InvalidDatePopup
        isOpen={Boolean(isDateValidationPopup)}
        isDateValidationPopup={isDateValidationPopup}
        handleClose={() => {
          setIsDateValidationPopup(null);
        }}
      />
      <RulesPopup
        isOpen={isMismatch}
        selectableName={''}
        ruleAddVaules={currentChanges?.addedOptions as IRuleValues[]}
        ruleRemoveVaules={currentChanges?.removedOptions as IRuleValues[]}
        ruleDefaultValues={[]}
        popupPrice={popupPrice}
        handleClose={handleRulesPopupclose}
        handleConfirm={handleRulesPopupConfirm}
      />
      <Cart
        rammeavtale={isRammeavtaleEnabled}
        exactMatchCount={props.exactMatchCount}
        nonExactMatchCount={props.nonExactMatchCount}
        make={cart?.make}
        modelSeries={cart?.modelSeries}
        prices={finalPrice}
        image={cart?.image}
        data={updateVehicleVatCodeAndDiscount(
          filterSelectedEquipmentInCart(cart?.data, getSelectedEquipmentInCart(cart?.data)), 
          overriddenTaxAndUsageCodeDetails?.usageCode, 
          order, 
          rammeavtale
        )}
        isVehicleSelected={props?.isVehicleSelected}
        serialNo={props?.selectedSerialNo}
        selectedLocation={props?.vehicleLocation}
        selectedStatus={props?.vehicleStatus}
        isFactoryOrder={props?.isFactoryOrder}
        expectedProductionDate={props?.expectedProductionDate}
        vehicleCount={props?.availableVehicleList?.length}
        isElectricCar={props?.isElectricCar}
        showMBApiWarning={order?.externalTaxCalculation === MBApiCalculationFlag.FETCH_FAILED}
        equipmentDetails={getSelectedEquipmentInCart(cart?.data)}
        footerChildren={
          <MarginCart
            order={order}
            vehicleConfiguration={vehicle}
            articles={articles}
            vatPrices={props?.vatPrices}
            carType={carType}
            isUnavailablePimData={props?.isUnavailablePimData}
            isPcodePage = {true}
            isPcodeChangeFound={isPcodeChangeFound}
            isSaveFromMargin={isSaveFromMargin}
            isPcodeChangeCancelled={isPcodeChangeCancelled}
            setIsSaveFromMargin={(value) => {
              setIsSaveFromMargin(value);
              if(priceMismatchOptions?.length) {
                setIsPcodePriceChangeFound(true);
              }
            }}
            isPcodePriceChangeFound={isPcodePriceChangeFound}
            setIsPcodeChangeCancelled={setIsPcodeChangeCancelled}
            onUpdateArticles={updateArticlesWithDiscount}
            accessoryChanges={cart?.data}
            rammeavtale={rammeavtale}
            setRammeavtale={setRammeavtale}
          />
        }
        showAbortFactoryOrderAction={props.isShowDeleteButton}
        abortFactoryBorder={{
          buttonIcon: IconType?.NewNextTrash,
          buttonClass: 'red',
          buttonLabel: props.order?.vehicles?.[0]?.serialNo ? 'Fjern bil' : 'Avbryt fabrikkbestilling',
          buttonAction: () => {
            props.setIsShowDeleteModel(!props.isShowDeleteModel);
          },
        }}
        oneOffTaxAndWreckageDetails=  {(finalPrice?.oneOffTaxAndWreckage >= 0 ? {
          isEditable: isChargesEditable(),
          taxAndWreckageValue: props?.order?.vatCalculation?.overriddenVehicleRoadTax ??
          finalPrice?.oneOffTaxAndWreckage,
          resetTaxAndWreckageValue: props?.order?.vatCalculation?.oneOffTaxAndWreckage,
          inputValue: (param, doneReset) => {
            setOverriddenTaxAndUsageCodeDetails({
              ...overriddenTaxAndUsageCodeDetails,
              chargesAreOverridden: !doneReset,
              doneReset: doneReset,
              overriddenVehicleRoadTax: !doneReset ? param : null,
            });
            setOverriddenTax(!doneReset ? param : null);
          },
        }:{} as IOneOffTaxAndWreckage)}
      />
      {(props?.isLoading || loading) && <LoadingSpinner isModal={true} />}
      {props.isShowDeleteModel && (
        <CustomAlertPopup
          isOpen={props.isShowDeleteModel}
          onCloseClick={handleClose}
          deleteOrderData={props.deleteOrderData}
          className="deleteAlert"></CustomAlertPopup>
      )}
    </ImporterOptionsPageStyled>
  );
};
