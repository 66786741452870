import { manualSignFileUploadSizeInMb } from "../../common"

export const manualSignFileUploadSizeParse = () => {
    try {
        const cleanData = JSON.stringify(manualSignFileUploadSizeInMb, (key, value) => {
            if (value === undefined) {
                return null;  // Or return a default value
            }
            return value;
        });
        
        return JSON.parse(cleanData); // want to return object
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error('Error in manualSignFileUploadSizeInMb parse:', error?.message);
        return null; // Optionally return null or some default object
    }
}